import { Calendar } from "primereact/calendar";
import { useEffect, useRef, useState } from "react";
import Filter from "../../../../assets/images-svg/Filter";
import { axiosService } from "../../../../services/axios.service";
import { HOLIDAYS_URL } from "../../../holidays/constants/holidays-urls.js";
import "./HolidaysList.css";

function HolidaysList() {
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [holidayData, setHolidayData] = useState([]);
  const calendarRef = useRef(null);

  const isPastDate = (dateString) => {
    const holidayDate = new Date(dateString);
    const currentDate = new Date();
    return holidayDate < currentDate;
  };

  const fetchData = async () => {
    try {
      const response = await axiosService.get(
        `${
          HOLIDAYS_URL.GET_HOLIDAYS_BY_YEAR
        }?year=${selectedYear.getFullYear()}`
      );

      const data = response?.data;
      if (data) {
        const sortedData = data.sort(
          (a, b) => new Date(a.holidayDate) - new Date(b.holidayDate)
        );
        setHolidayData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching holiday data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear]);

  return (
    <div className="holiday-list-container-main">
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col w-100 ">
            <div className="holiday-list-text-holder d-flex flex-wrap align-items-center">
              <h1>Holiday Schedule for Year</h1>
              <div className="holiday-list-filters-input-container">
                <div className="holiday-year-filter">
                  <Calendar
                    dateFormat="yy"
                    view="year"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.value)}
                    className="calendar-input"
                    ref={calendarRef}
                  />
                   <div className="holiday-filter-icon">
                  <Filter />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="holiday-table-container">
          <table className="holidays-list-table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Day</th>
                <th scope="col">Holiday Name</th>
              </tr>
            </thead>
            <tbody>
              {holidayData.map((holiday, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <span>
                        <hr
                          className={`holidays-hr-line ${
                            isPastDate(holiday.holidayDate)
                              ? "past-holiday"
                              : "upcoming-holiday"
                          }`}
                        />
                      </span>
                      {holiday.holidayDate}
                    </div>
                  </td>
                  <td>{holiday.day}</td>
                  <td>{holiday.holidayName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex gap-4 mt-3" style={{boxShadow:"0px -2px  #F3EEEA"}}>
          <div className="d-flex gap-2 holidays-indicator">
            <div className="circle-green mt-2"></div>
            <p>Upcoming</p>
          </div>
          <div className="d-flex gap-2 holidays-indicator">
            <div className="circle-black mt-2"></div>
            <p>Past Holidays</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HolidaysList;


