function ProfileIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="10.0001"
        cy="14.5833"
        rx="5.83333"
        ry="2.91667"
        stroke="black"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <ellipse
        cx="10.0001"
        cy="5.83333"
        rx="3.33333"
        ry="3.33333"
        stroke="black"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
    </svg>
  );
}
export default ProfileIcon;
