import React from "react";

function BackArrow() {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5303 3.54963C15.8232 3.84252 15.8232 4.31739 15.5303 4.61029L9.0103 11.1303C8.53319 11.6074 8.53319 12.3925 9.0103 12.8696L15.5303 19.3896C15.8232 19.6825 15.8232 20.1574 15.5303 20.4503C15.2374 20.7432 14.7625 20.7432 14.4696 20.4503L7.94964 13.9303C6.88674 12.8674 6.88674 11.1325 7.94964 10.0696L14.4696 3.54963C14.7625 3.25673 15.2374 3.25673 15.5303 3.54963Z"
        fill="black"
      />
    </svg>
  );
}

export default BackArrow;
