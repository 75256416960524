import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Trash from "../../../../assets/images-svg/Trash";
import { axiosService } from "../../../../services/axios.service";
import Deletemodal from "../../../holidays/components/holidays-list/Deletemodal";
import { LOOKUP_URL } from "../../constants/all-look-up-urls";
function LookUpTaskTab() {
  const [taskValue, setTaskValue] = useState("");
  const [taskName, setTaskName] = useState([]);
  const [taskData, setTaskData] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const openDeleteModal = (workTypeId) => {
    setSelectedTaskId(workTypeId);
    setDeleteModalOpen(true);
  };
  useEffect(() => {
  }, []);
  const handleCloseModal = () => {
    setDeleteModalOpen(false);
  };
  const onSubmit = async () => {
    try {
      const requestData = {
        ...(taskValue && { task: taskValue }),
      };
      const url = LOOKUP_URL.ADD_PROJECT;
      const response = await axiosService.post(url, requestData);
      const { data } = response?.data;
      setTaskData(data);
      setTaskValue("");
      toast.success("Task successfully added");
    } catch (error) {
      if (error.response && error.response.status === 500) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage.split(":")[1]);
      } else {
        console.error("Error:", error);
        toast.error("An error occurred while processing your request.");
      }
    }
  };
  const fetchData = async () => {
    try {
      const response = await axiosService.get(LOOKUP_URL.GET_PROJECT);
      const responseData = response?.data?.data;
      const tasks = responseData.filter(
        (task) => task.task && task.task.trim() !== ""
      );
      const taskNames = tasks.map((task) => ({
        workTypeId: task.workTypeId,
        task: task.task,
        
      }));

      setTaskName(taskNames.reverse());
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  useEffect(() => {  

    fetchData();
  }, [taskData]);
  return (
    <div>
      <div className="look-up-project-tab d-flex flex-wrap">
        <span className="p-float-label">
          <InputText
            id="task"
            value={taskValue}
            onChange={(e) => setTaskValue(e.target.value)}
          />
          <label htmlFor="task">Enter Task Name</label>
        </span>
        <div className="ms-auto">
          <button className="add-button" style={{opacity: taskValue === "" ? "0.5" : "1"}} onClick={onSubmit} disabled={taskValue === ""}>
            Add
          </button>
        </div>
      </div>
      <div className="lookup-list-table-container">
        <table className="lookup-list-table">
          <thead>
            <tr>
              <th scope="col" style={{width:"20%"}}>S.No</th>
              <th scope="col" style={{width:"60%"}}>Task Name</th>
              <th scope="col" style={{width:"20%"}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {taskName.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.task}</td>
                <td>
                  <div className="action d-inline-flex gap-1">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteModal(item?.workTypeId);
                      }}
                    >
                      <Trash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Deletemodal
        modalOpen={deleteModalOpen}
        closeModal={handleCloseModal}
        cancelButton={true}
        submitButton={true}
        onCloseTitle="Close"
        taskData={selectedTaskId}
        fetchData={fetchData}
        title="Are you sure?"
        data="Are you sure you want to delete?"
      />
    </div>
  );
}

export default LookUpTaskTab;
