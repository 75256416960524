function RightArrow() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
        fill="black"
      />
    </svg>
  );
}
export default RightArrow;
