import React from 'react'
import ReporteesNavbar from '../../reportees-navbar/ReporteesNavbar'

function AllReporteesDetails() {
  return (
    <div>
        <ReporteesNavbar />
    </div>
  )
}

export default AllReporteesDetails