import React from 'react'

const TimesheetLogo = ({color}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16V13M10 13V10M10 13H7M10 13H13M19 6L17 4M8 2H12M10 21C5.58172 21 2 17.4183 2 13C2 8.58172 5.58172 5 10 5C14.4183 5 18 8.58172 18 13C18 17.4183 14.4183 21 10 21Z"
        stroke={color}
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default TimesheetLogo