import { Pagination } from "flowbite-react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import Action from "../../../../assets/images-svg/Action";
import ClockIn from "../../../../assets/images-svg/ClockIn";
import ClockOut from "../../../../assets/images-svg/ClockOut";
import Dropdowns from "../../../../assets/images-svg/Drop_down";
import Filter from "../../../../assets/images-svg/Filter";
import LeftArrow from "../../../../assets/images-svg/LeftArrow";
import RightArrow from "../../../../assets/images-svg/RightArrow";
import { axiosService } from "../../../../services/axios.service";
import sessionService from "../../../../services/sessionstorage.service";
import { ATTENDANCE_URL } from "../../constants/attendance-urls";
import "./AttendanceList.css";
import { IoCloseCircle } from "react-icons/io5";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    width: "550px",
    height: "470px",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    padding: "0",
  },
};

const AttendanceList = () => {
  const webcamRef = useRef(null);
  const [imgSrcIn, setImgSrcIn] = useState(null);
  const [imgSrcOut, setImgSrcOut] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [clockOutModalIsOpen, setClockOutModalIsOpen] = useState(false);
  const [attendanceId, setAttendanceId] = useState(null);
  const User = sessionService.getUserData("userData");
  const empId = User?.employee?.empId;
  const [dates, setDates] = useState(() => calculateDefaultDates());
  const [attendanceData, setAttendanceData] = useState([]);
  const cardsPerPageOptions = [10, 20, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(cardsPerPageOptions[0]);
  const [totalElements, setTotalElements] = useState(null);

  const [selectStatus, setSelectStatus] = useState(null);

  function calculateDefaultDates() {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - 1);
    return [startDate, currentDate];
  }
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (dates && dates.length === 2) {
          const startDate = formatDate(dates[0]);
          const endDate = formatDate(dates[1]);
          const url = `${ATTENDANCE_URL.GET_ATTENDANCE_BY_EMPID}${empId}`;
          const params = {
            startDate,
            endDate,
            pageNumber: currentPage,
            itemsPerPage: recordsPerPage,
            status: selectStatus,
          };

          const response = await axiosService.get(url, params);
          const data = response?.data?.data?.data;
          setTotalElements(parseInt(response.data.data.totalElements));
          if (data) {
            setAttendanceData(data);
          }
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    fetchData();
  }, [dates, imgSrcOut, imgSrcIn, currentPage, recordsPerPage, selectStatus]);

  const handleDateChange = (e) => {
    const selectedDates = e.value;
    if (selectedDates.length === 1) {
      const endDate = new Date(selectedDates[0]);
      endDate.setDate(endDate.getDate() + 1);

      setDates([selectedDates[0], endDate]);
    } else {
      setDates(selectedDates);
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const openClockOutModal = () => {
    setClockOutModalIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const closeClockOutModal = () => {
    setClockOutModalIsOpen(false);
    setAttendanceId(null);
  };

  const SaveMarkIn = async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();

      if (!imageSrc) {
        console.error("Failed to capture image");
        return;
      }
      setImgSrcIn(imageSrc);
      closeModal();
      const blobImage = await fetch(imageSrc).then((res) => res.blob());
      const imageFile = new File([blobImage], "markInImage.jpeg", {
        type: "image/jpeg",
      });
      const response = await axiosService.postImageFile(
        `${ATTENDANCE_URL.ADD_ATTENDANCE_IN}${empId}`,
        imageFile,
        "markInImage"
      );
      if (response?.status === 200) {
        setImgSrcIn(null);
        // sessionService?.setAttendanceId(response?.data?.data?.attendanceId);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setImgSrcIn(null);
    }
  };

  const SaveMarkOut = async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();

      if (!imageSrc) {
        console.error("Failed to capture image");
        return;
      }
      setImgSrcOut(imageSrc);
      const blobImage = await fetch(imageSrc).then((res) => res.blob());
      const imageFile = new File([blobImage], "markOutImage.jpeg", {
        type: "image/jpeg",
      });
      const response = await axiosService.postImageFile(
        `${ATTENDANCE_URL.ADD_ATTENDANCE_OUT}${empId}?attendanceId=${attendanceId}`,
        imageFile,
        "markOutImage"
      );
      if (response?.status === 200) {
        setImgSrcIn(null);
        // sessionService?. removeAttendanceId();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setImgSrcOut(null);
      closeClockOutModal();
    }
  };
  const displayedCards = attendanceData;
  displayedCards.sort((a, b) => {
    const dateA = new Date(a.checkInTime);
    const dateB = new Date(b.checkInTime);
    return dateB - dateA;
  });
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const hanleStatusChange = (selectedOption) => {
    setSelectStatus(selectedOption);
  };

  const options = [
    { value: "ONTIME", label: "On Time" },
    { value: "LATE", label: "Late" },
  ];

  const [imagePreview, setImagePreview] = useState(null);
  return (
    <>
      <div className="w-100 attendance-list">
        <div className="attendance-list-container">
          <div className="attendance-list-banner">
            <div className="container-fluid  ">
              {/* Top Filter section */}
              <div className="row ">
                <div className=" col w-100 Top-column ">
                  <div className="attendance-list-date-range">
                    <Calendar
                      id="calendarInput"
                      value={dates}
                      placeholder="Date Range"
                      onChange={handleDateChange}
                      selectionMode="range"
                      readOnlyInput
                      hideOnRangeSelection
                      className="attendance-list-status-input"
                    ></Calendar>
                    <div className="attendance-list-date-icon">
                      <Filter />
                    </div>
                  </div>

                  <div className="attendance-list-status">
                    <Dropdown
                      value={selectStatus}
                      onChange={(e) => hanleStatusChange(e.target.value)}
                      options={options}
                      optionLabel="label"
                      showClear
                      placeholder="Status"
                      className="w-full md:w-14rem attendance-status"
                    />
                  </div>
                  <div className="ms-auto">
                    <button
                      onClick={openModal}
                      className="attendence-list-clock-btutton"
                    >
                      Clock In
                      <span className="mx-1">
                        <ClockIn />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              {/* Table Section  */}
              <div className="row">
                <div class="attendance-list-table-container">
                  <table className="attendance-list-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Check In</th>
                        <th>Check Out</th>
                        <th>Duration</th>
                        <th>Image In</th>
                        <th>Image Out</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedCards.map((e, index) => (
                        <tr key={index}>
                          <td>{e.date}</td>
                          <td>
                            {new Date(e.checkInTime).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </td>
                          <td>
                            {e.checkOutTime
                              ? new Date(e.checkOutTime).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                )
                              : "N/A"}
                          </td>
                          <td>{e.duration}</td>
                          <td>
                            {e?.markInImage && (
                              <img
                                onClick={() => setImagePreview(e?.markInImage)}
                                src={`${e?.markInImage}`}
                                alt="Mark IN Img"
                                className="rounded mx-1"
                                style={{
                                  width: "40px",
                                  height: "35px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </td>
                          <td>
                            {e.markOutImage && (
                              <img
                                onClick={() => setImagePreview(e?.markOutImage)}
                                src={`${e.markOutImage}`}
                                alt="Mark Out Img"
                                className="rounded mx-1"
                                style={{
                                  width: "40px",
                                  height: "35px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </td>
                          <td>
                            <div
                              className="attendance-list-table-status"
                              style={{
                                background:
                                  e.attendanceStatus === "LATE"
                                    ? "#E2531933"
                                    : "#3FC28A33",
                                color:
                                  e.attendanceStatus === "LATE"
                                    ? "#E25319"
                                    : "#3FC28A",
                              }}
                            >
                              {e.attendanceStatus}
                            </div>
                          </td>
                          <td style={{ padding: "0px 20px" }}>
                            {!e.markOutImage ? (
                              <span
                                onClick={() => {
                                  openClockOutModal();
                                  setAttendanceId(e.attendanceId);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <Action />
                              </span>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  className="row mt-3"
                  style={{ boxShadow: "0px -2px  #F3EEEA" }}
                >
                  <div className="col w-50 d-flex align-items-center attendance-record-list">
                    <span>Showing</span>
                    <div style={{ width: "fit-content", position: "relative" }}>
                      <select
                        value={recordsPerPage}
                        onChange={handleRecordsPerPageChange}
                      >
                        {cardsPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="attendance-record-list-dropdown-icon">
                        <Dropdowns />
                      </div>
                    </div>
                  </div>
                  <div className="col w-50 attendance-pagination-container">
                    {Math.ceil(totalElements / recordsPerPage) > 1 ? (
                      <>
                        {totalElements && (
                          <Pagination
                            className="attendance-pagination d-inline-flex"
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                              totalElements / recordsPerPage
                            )}
                            onPageChange={onPageChange}
                            previousLabel={<LeftArrow />}
                            nextLabel={<RightArrow />}
                            activePageLinkStyle={{
                              color: "#ffffff !important",
                            }}
                            icon={<span>{currentPage}</span>}
                          />
                        )}
                      </>
                    ) : (
                      <nav
                        class="pagination d-inline-flex"
                        activepagelinkstyle="[object Object]"
                        icon="[object Object]"
                      >
                        <ul class="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.4685 17.5856C14.7919 17.3269 14.8444 16.8549 14.5856 16.5315L10.9604 12L14.5856 7.46849C14.8444 7.14505 14.7919 6.67308 14.4685 6.41432C14.145 6.15556 13.673 6.208 13.4143 6.53145L9.41429 11.5315C9.19516 11.8054 9.19516 12.1946 9.41429 12.4685L13.4143 17.4685C13.673 17.7919 14.145 17.8444 14.4685 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                          <li aria-current="page">
                            <button
                              type="button"
                              class="w-12 border border-gray-300 py-2 leading-tight enabled:hover:bg-gray-100 enabled:hover:text-gray-700 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                            >
                              1
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>

                {/* Modal Start */}
                <div>
                  <Modal
                    isOpen={modalIsOpen || clockOutModalIsOpen}
                    style={customStyles}
                    contentLabel="Webcam Modal"
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                      }}
                    >
                      {imgSrcIn || imgSrcOut ? (
                        <img
                          src={imgSrcIn || imgSrcOut}
                          alt="webcam"
                          style={{
                            width: "auto",
                            height: "100%",
                            borderRadius: "20px",
                          }}
                        />
                      ) : (
                        <Webcam
                          style={{
                            width: "auto",
                            height: "100%",
                            position: "relative",
                            borderRadius: "20px",
                          }}
                          ref={webcamRef}
                          screenshotFormat="image/png"
                        />
                      )}

                      <button
                        className={`attendence-list-clock-${
                          modalIsOpen ? "in" : "out"
                        }-button`}
                        onClick={modalIsOpen ? SaveMarkIn : () => SaveMarkOut()}
                        style={{
                          position: "absolute",
                          bottom: "0",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {modalIsOpen ? "Clock In" : "Clock Out"}
                        <span className="mx-1">
                          {modalIsOpen ? <ClockIn /> : <ClockOut />}
                        </span>
                      </button>
                      {modalIsOpen && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={closeModal}
                        >
                          X
                        </button>
                      )}
                      {clockOutModalIsOpen && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={closeClockOutModal}
                        >
                          X
                        </button>
                      )}
                    </div>
                  </Modal>
                </div>

                <Modal
                  isOpen={imagePreview}
                  style={customStyles}
                  onRequestClose={() => setImagePreview(null)}
                  shouldCloseOnEsc={true}
                >
                  <IoCloseCircle
                    onClick={() => setImagePreview(null)}
                    color="#000"
                    size={"30px"}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      cursor: "pointer",
                    }}
                  />

                  <img
                    src={imagePreview}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceList;
