import { Pagination } from "flowbite-react";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cancel from "../../../../assets/images-svg/Cancel";
import Dropdowns from "../../../../assets/images-svg/Drop_down";
import Edit from "../../../../assets/images-svg/Edit";
import LeftArrow from "../../../../assets/images-svg/LeftArrow";
import RightArrow from "../../../../assets/images-svg/RightArrow";
import Update from "../../../../assets/images-svg/Update";
import { axiosService } from "../../../../services/axios.service";
import sessionService from "../../../../services/sessionstorage.service";
import { REPORTEES_URL } from "../../../reportees/constants/reportees-urls";
import "./ReporteesLeaveBalance.css";

const ReporteesLeaveBalance = () => {
  const User = sessionService.getUserData("userData");
  const empId = User?.employee?.empId;
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const role = userData?.role;
  const [data, setData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [singleSelections, setSingleSelections] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({});
  const cardsPerPageOptions = [10, 15, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(cardsPerPageOptions[0]);
  const [totalElements, setTotalElements] = useState(0);

  const displayedCards = data;
  console.log(displayedCards, "oooooooooooooooooooooooooooooooo")
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${REPORTEES_URL.GET_REPORTEES_NAMES}${empId}`;
        const response = await axiosService.get(url);
        const data = response?.data?.data;

        if (data) {
          const employee = data.map((item) => {
            return { name: item.employeeFirstName + ' ' + item.employeeLastName, empId: item?.employeeId };
          });
          setAttendanceData(employee);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "USER") {
      fetchData();
    }
  }, [empId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = REPORTEES_URL.ADMIN_REPORTEES_NAMES;
        const response = await axiosService.get(url);
        const data = response?.data?.data;

        if (data) {
          const employee = data.map((item) => {
            return { name: item.employeeFirstName + ' ' + item.employeeLastName, empId: item?.employeeId };
          });
          setAttendanceData(employee);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "ADMIN") {
      fetchData();
    }
  }, [recordsPerPage, currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${REPORTEES_URL.GET_REPORTEES_LEAVE_BALANCE}${empId}`;
        const params = {
          empId,
          pageNumber: currentPage,
          itemsPerPage: recordsPerPage,
        };

        if (singleSelections) {
          params.employeeName = singleSelections;
        }

        const response = await axiosService.get(url, params);
        const data = response?.data?.data?.data;
        
        const totalElements = response?.data?.data?.totalElements;
        setData(data);
        setTotalElements(totalElements);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "USER") {
      fetchData();
    }
  }, [empId, currentPage, recordsPerPage, singleSelections]);

  useEffect(() => {
    if (role === "ADMIN") {
      fetchLeaveBalanceData();
    }
  }, [currentPage, recordsPerPage, singleSelections]);

  const fetchLeaveBalanceData = async () => {
    try {
      const url = REPORTEES_URL.ADMIN_REPORTEES_LEAVE_BALANCE;
      const params = {
        pageNumber: currentPage,
        itemsPerPage: recordsPerPage,
      };

      if (singleSelections) {
        params.employeeName = singleSelections;
      }

      const response = await axiosService.get(url, params);
      const data = response?.data?.data?.data;
      const totalElements = response?.data?.data?.totalElements;
      setData(data);
      setTotalElements(totalElements);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditData(displayedCards[index]);
  };

  const handleCancelClick = () => {
    setEditIndex(null);
    setEditData({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdateClick();
  }, []);


  const handleUpdateClick = async () => {
    try {
      const url = `${REPORTEES_URL.ADMIN_EDIT_LEAVE_MASTER}`;
      const requestData = {
        ...editData,
        employee: { empId: editData?.employee?.empId }
      };
      await axiosService.put(url, requestData);
      const updatedData = [...data];
      updatedData[editIndex] = editData;
      setEditIndex(null);
      setEditData({});

      toast.success("Leave Balance Updated Successfully!", {
        autoClose: 3000,
        closeButton: false,
        style: { backgroundColor: "green", color: "white" },
      });
      fetchLeaveBalanceData();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleEmployeeSelect = (e) => {
    setSingleSelections(e.value);
  };
  const employeeNameoptions = attendanceData.map((a) => ({
    label: a?.name,
    value: a?.empId,
  }));

  const onSubmit = async () => {
    try {
      const response = await axiosService.post(`${REPORTEES_URL.GET_MONTHLY_LEAVE}`,);
      console.log("Leave balances updated successfully:", response.data);

      if (response && response.status === 200) {
        toast.success(response.data.data);
        console.log(response.data, "success success")
      } else {
        toast.error(response.data.message);
        console.log(response.data, "error error")
      }
    } catch (error) {
      console.log("Error updating leave balances:", error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }

  };

  return (
    <>
      <div className="w-100 reportees-timesheet-list">
        <div className="reportees-timesheet-list-container">
          <div className="reportees-timesheet-list-banner">
            <div className="reportees-container-fluid">
              {/* Top section */}
              <div className="reportees-row">
                <div className="col w-100 reportees-timesheet-top-column" style={{justifyContent:"space-between"}}>
                  <div className="reportees-attendance-list-filter-input-container">
                    <Dropdown
                      id="employee-search"
                      labelKey="employeeFirstName"
                      value={singleSelections}
                      onChange={handleEmployeeSelect}
                      options={employeeNameoptions}
                      placeholder="Employee"
                      filter={true}
                      showClear
                      className="reportees-custom-dropdown w-full md:w-14rem"
                      style={{ zIndex: "0" }}
                    />
                  </div>
                  <button className="monthly-leave-update" onClick={onSubmit} style={{marginRight:"10px"}}>Update Monthly Leave</button>
                </div>
                <div className="reportees-TimesheetScrollContainer">
                  {/* Table Section */}
                  <div className="row reportees-timesheetTableScroll">
                    <div className="reportees-timesheet-list-table-container">
                      <table className="reportees-timesheet-list-table">
                        <thead>
                          <tr>
                            <th style={{ width: "180px" }}>Employee Id</th>
                            <th style={{ width: "220px" }}>Employee Name</th>
                            <th style={{ width: "160px" }}>Personal Leave</th>
                            <th style={{ width: "150px" }}>Sick Leave</th>
                            <th style={{ width: "150px" }}>Privilege Leave</th>
                            <th style={{ width: "150px" }}>Permission</th>
                            <th style={{ width: "120px" }}>WFH</th>
                            {role === "ADMIN" && (
                              <th style={{ width: "110px" }}>Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {displayedCards.map((e, index) => (
                            <tr key={index}>
                              <td>{e?.employee?.employeeId}</td>
                              <td>
                                {e?.employee?.employeeFirstName}{" "}
                                {e?.employee?.employeeLastName}
                              </td>
                              <td>
                                {editIndex === index ? (
                                  <input
                                    type="number"
                                    name="personalLeaveBalance"
                                    value={editData?.personalLeaveBalance}
                                    onChange={handleInputChange}
                                    style={{
                                      border: "1px solid lightgrey",
                                      borderRadius: "5px",
                                      paddingLeft: "10px",
                                    }}
                                  />
                                ) : (
                                  e?.personalLeaveBalance
                                )}
                              </td>
                              <td>
                                {editIndex === index ? (
                                  <input
                                    type="number"
                                    name="sickLeaveBalance"
                                    value={editData?.sickLeaveBalance}
                                    onChange={handleInputChange}
                                    style={{
                                      border: "1px solid lightgrey",
                                      borderRadius: "5px",
                                      paddingLeft: "10px",
                                    }}
                                  />
                                ) : (
                                  e?.sickLeaveBalance
                                )}
                              </td>
                              {/* Privilege */}
                              <td>  {editIndex === index ? (
                                <input
                                  type="number"
                                  name="privilegeLeaveBalance"
                                  value={editData?.privilegeLeaveBalance}
                                  onChange={handleInputChange}
                                  style={{
                                    border: "1px solid lightgrey",
                                    borderRadius: "5px",
                                    paddingLeft: "10px",
                                  }}
                                />
                              ) : (
                                e?.privilegeLeaveBalance
                              )}</td>
                              <td>
                                {editIndex === index ? (
                                  <input
                                    type="number"
                                    name="permissionBalance"
                                    value={editData?.permissionBalance}
                                    onChange={handleInputChange}
                                    style={{
                                      border: "1px solid lightgrey",
                                      borderRadius: "5px",
                                      paddingLeft: "10px",
                                    }}
                                  />
                                ) : (
                                  e?.permissionBalance
                                )}
                              </td>
                              <td>
                                {editIndex === index ? (
                                  <input
                                    type="number"
                                    name="wfhBalance"
                                    value={editData?.wfhBalance}
                                    onChange={handleInputChange}
                                    style={{
                                      border: "1px solid lightgrey",
                                      borderRadius: "5px",
                                      paddingLeft: "10px",
                                    }}
                                  />
                                ) : (
                                  e?.wfhBalance
                                )}
                              </td>
                              {role === "ADMIN" && (
                                <td>
                                  <div className="d-flex justify-content-between">
                                    <span
                                      onClick={() => handleEditClick(index)}
                                    >
                                      <Edit />
                                    </span>
                                    {editIndex === index ? (
                                      <>
                                        <span onClick={handleUpdateClick}>
                                          <Update />
                                        </span>
                                        <span onClick={handleCancelClick}>
                                          <Cancel />
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className="row mt-3"
                  style={{ boxShadow: "0px -2px  #F3EEEA" }}
                >
                  <div className="col w-50 d-flex align-items-center reportees-timesheet-record-list">
                    <span>Showing</span>
                    <div style={{ width: "fit-content", position: "relative" }}>
                      <select
                        value={recordsPerPage}
                        onChange={handleRecordsPerPageChange}
                      >
                        {cardsPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="reportees-timesheet-record-list-dropdown-icon">
                        <Dropdowns />
                      </div>
                    </div>
                  </div>
                  <div className="col w-50 reportees-timsheet-pagination-container">
                    {Math.ceil(totalElements / recordsPerPage) > 1 ? (
                      <>
                        {totalElements && (
                          <Pagination
                            className="pagination d-inline-flex"
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                              totalElements / recordsPerPage
                            )}
                            onPageChange={onPageChange}
                            nextLabel={<RightArrow />}
                            previousLabel={<LeftArrow />}
                            activePageLinkStyle={{
                              color: "#ffffff !important",
                            }}
                            icon={<span>{currentPage}</span>}
                          />
                        )}
                      </>
                    ) : (
                      <nav
                        class="pagination d-inline-flex"
                        activepagelinkstyle="[object Object]"
                        icon="[object Object]"
                      >
                        <ul class="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.4685 17.5856C14.7919 17.3269 14.8444 16.8549 14.5856 16.5315L10.9604 12L14.5856 7.46849C14.8444 7.14505 14.7919 6.67308 14.4685 6.41432C14.145 6.15556 13.673 6.208 13.4143 6.53145L9.41429 11.5315C9.19516 11.8054 9.19516 12.1946 9.41429 12.4685L13.4143 17.4685C13.673 17.7919 14.145 17.8444 14.4685 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                          <li aria-current="page">
                            <button
                              type="button"
                              class="w-12 border border-gray-300 py-2 leading-tight enabled:hover:bg-gray-100 enabled:hover:text-gray-700 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                            >
                              1
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReporteesLeaveBalance;