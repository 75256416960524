import React from "react";

const Export = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50758 4.28906L8.21212 1.65234M8.21212 1.65234L10.9167 4.28906M8.21212 1.65234V10.4414M3.70475 7.80469C2.86465 7.80469 2.44459 7.80469 2.11325 7.93849C1.67145 8.1169 1.32024 8.4593 1.13725 8.89002C1 9.21305 1 9.62237 1 10.4414V14.6602C1 15.6446 1 16.1365 1.19652 16.5125C1.36938 16.8433 1.64501 17.1127 1.98427 17.2813C2.36958 17.4727 2.87424 17.4727 3.88206 17.4727H12.5426C13.5504 17.4727 14.0544 17.4727 14.4397 17.2813C14.7789 17.1127 15.0551 16.8433 15.2279 16.5125C15.4242 16.1369 15.4242 15.6454 15.4242 14.6629V10.4414C15.4242 9.62237 15.4241 9.21305 15.2869 8.89002C15.1039 8.4593 14.753 8.1169 14.3112 7.93849C13.9799 7.80469 13.5598 7.80469 12.7197 7.80469"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Export;
