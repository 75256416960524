import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import TimesheetList from "../timesheet-list/TimesheetList";

const TimesheetRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/time-sheet") {
      navigate("/time-sheet/timesheet-list");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="timesheet-list" element={<TimesheetList />} />
    </Routes>
  );
};

export default TimesheetRoutes;
