import React from 'react';
import { Link } from 'react-router-dom';
 
const Breadcrumbs = ({ paths }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {paths.map((path, index) => (
          <React.Fragment key={index}>
            <li className="breadcrumb-item">
              <Link to={path.url} className="employeeList-header-Links">{path.label}</Link>
            </li>
            {index < paths.length - 1 && <span className="breadcrumb-separator" style={{ marginRight:'10px' }}> {'>'} </span>}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
};
 
export default Breadcrumbs;