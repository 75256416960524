import React from "react";

const LookUpLogo = ({ color }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 17L14 14M8.0002 18H5.19692C4.07901 18 3.5192 18 3.0918 17.7822C2.71547 17.5905 2.40973 17.2842 2.21799 16.9079C2 16.4801 2 15.9203 2 14.8002V5.2002C2 4.08009 2 3.51962 2.21799 3.0918C2.40973 2.71547 2.71547 2.40973 3.0918 2.21799C3.51962 2 4.08009 2 5.2002 2H14.8002C15.9203 2 16.4796 2 16.9074 2.21799C17.2837 2.40973 17.5905 2.71547 17.7822 3.0918C18 3.5192 18 4.07899 18 5.19691V8.0002M11.5 15C9.567 15 8 13.433 8 11.5C8 9.567 9.567 8 11.5 8C13.433 8 15 9.567 15 11.5C15 13.433 13.433 15 11.5 15Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LookUpLogo;
