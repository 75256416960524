import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AttendanceList from "../attendance-list/AttendanceList";

const AttendanceRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/attendance") {
      navigate("/attendance/attendance-list");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="attendance-list" element={<AttendanceList />} />
    </Routes>
  );
};

export default AttendanceRoutes;
