import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AllEmployeesLogo from "../../../../assets/images-svg/AllEmployeesLogo";
import AssetLogo from "../../../../assets/images-svg/AssetLogo";
import AttendanceLogo from "../../../../assets/images-svg/AttendanceLogo";
import DashboardLogo from "../../../../assets/images-svg/DashboardLogo";
import HolidayLogo from "../../../../assets/images-svg/HolidayLogo";
import LeavesLogo from "../../../../assets/images-svg/LeavesLogo";
import LookUpLogo from "../../../../assets/images-svg/LookUpLogo";
import ReporteeIcon from "../../../../assets/images-svg/ReporteeIcon";
import TimesheetLogo from "../../../../assets/images-svg/TimesheetLogo";
import digibytelogo from "../../../../assets/images-svg/digibytelogo.svg";
import digibytelogotext from "../../../../assets/images-svg/digibytelogotext.svg";
import Profilelogo from "../../../../assets/images-svg/profileLogo";
import "./SideBar.css";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

function SideBar() {
  const [collapsed, setCollaped] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const role = userData?.role;

  return (
    <div
      className={`sidebar-container d-none d-md-block ${
        collapsed ? "collapsed" : "expanded"
      }`}
      style={{ height: "100%" }}
    >
      <div
        className={`sidebar-logo-container ${
          collapsed && "sidebar-logo-container-collapsed"
        }`}
      >
        <img
          src={collapsed ? digibytelogo : digibytelogotext}
          className="sidebar-logo"
          alt="beta-logo"
        />
      </div>
      <div
        className={`sidebar-list-container ${
          collapsed && "sidebar-list-container-collapsed"
        }`}
        style={{ backgroundColor: "#242C44", transition: "all 1s ease-in" }}
      >
        <div className="nav-item-list">
          <NavLink
            to={"/dashboard"}
            style={({ isActive }) =>
              isActive
                ? {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#3FC28A",
                  }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container mt-3 ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <DashboardLogo color={"#ffffff"} />
            </div>
            <div className="hover-show-logo">
              <DashboardLogo color={"#3FC28A"} />
            </div>

            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text d-none"
              }`}
            >
              Dashboard
            </span>
          </NavLink>
          {role === "ADMIN" && (
            <NavLink
              to={"/all-employees"}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3FC28A",
                    }
                  : { color: "#ffffff" }
              }
              className={`sidebar-list-inner-container ${
                collapsed &&
                "d-flex justify-content-center sidebar-list-inner-container-collapsed"
              }`}
            >
              <div className="hover-hide-logo">
                <AllEmployeesLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <AllEmployeesLogo color={"#3FC28A"} />
              </div>

              <span
                className={`sidebar-list-text ${
                  collapsed && "collapsed-sidebar-list-text d-none"
                }`}
              >
                All Employees
              </span>
            </NavLink>
          )}

          {role === "USER" && (
            <NavLink
              to={"/profile"}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3FC28A",
                    }
                  : { color: "#ffffff" }
              }
              className={`sidebar-list-inner-container ${
                collapsed &&
                "d-flex justify-content-center sidebar-list-inner-container-collapsed"
              }`}
            >
              <div className="hover-hide-logo">
                <Profilelogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <Profilelogo color={"#3FC28A"} />
              </div>

              <span
                className={`sidebar-list-text ${
                  collapsed && "collapsed-sidebar-list-text d-none"
                }`}
              >
                Profile
              </span>
            </NavLink>
          )}

          {role === "USER" && (
            <NavLink
              to={"/attendance"}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3FC28A",
                    }
                  : { color: "#ffffff" }
              }
              className={`sidebar-list-inner-container ${
                collapsed &&
                "d-flex justify-content-center sidebar-list-inner-container-collapsed"
              }`}
            >
              <div className="hover-hide-logo">
                <AttendanceLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <AttendanceLogo color={"#3FC28A"} />
              </div>

              <span
                className={`sidebar-list-text ${
                  collapsed && "collapsed-sidebar-list-text d-none"
                }`}
              >
                Attendance
              </span>
            </NavLink>
          )}
          {/* <NavLink
          to={"/payroll"}
          style={({ isActive }) =>
            isActive
              ? {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#3FC28A",
                }
              : { color: "#ffffff" }
          }
          className={`sidebar-list-inner-container ${
            collapsed &&
            "d-flex justify-content-center sidebar-list-inner-container-collapsed"
          }`}
        >
          <div className="hover-hide-logo">
            <PayrollLogo color={"#FFFFFF"} />
          </div>
          <div className="hover-show-logo">
            <PayrollLogo color={"#3FC28A"} />
          </div>

          <span
            className={`sidebar-list-text ${
              collapsed && "collapsed-sidebar-list-text d-none"
            }`}
          >
            Payroll
          </span>
        </NavLink> */}
          {role === "USER" && (
            <NavLink
              to={"/leaves"}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3FC28A",
                    }
                  : { color: "#ffffff" }
              }
              className={`sidebar-list-inner-container ${
                collapsed &&
                "d-flex justify-content-center sidebar-list-inner-container-collapsed"
              }`}
            >
              <div className="hover-hide-logo">
                <LeavesLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <LeavesLogo color={"#3FC28A"} />
              </div>

              <span
                className={`sidebar-list-text ${
                  collapsed && "collapsed-sidebar-list-text d-none"
                }`}
              >
                Leaves
              </span>
            </NavLink>
          )}
          {role === "USER" && (
            <NavLink
              to={"/time-sheet"}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3FC28A",
                    }
                  : { color: "#ffffff" }
              }
              className={`sidebar-list-inner-container ${
                collapsed &&
                "d-flex justify-content-center sidebar-list-inner-container-collapsed"
              }`}
            >
              <div className="hover-hide-logo">
                <TimesheetLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <TimesheetLogo color={"#3FC28A"} />
              </div>

              <span
                className={`sidebar-list-text ${
                  collapsed && "collapsed-sidebar-list-text d-none"
                }`}
              >
                Timesheet
              </span>
            </NavLink>
          )}
          {role === "USER" && (
            <NavLink
              to={"/holidays"}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3FC28A",
                    }
                  : { color: "black" }
              }
              className={`sidebar-list-inner-container ${
                collapsed &&
                "d-flex justify-content-center sidebar-list-inner-container-collapsed"
              }`}
            >
              <div className="hover-hide-logo">
                <HolidayLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <HolidayLogo color={"#3FC28A"} />
              </div>

              <span
                className={`sidebar-list-text ${
                  collapsed && "collapsed-sidebar-list-text d-none"
                }`}
              >
                Holidays
              </span>
            </NavLink>
          )}
          {role === "ADMIN" && (
            <NavLink
              to={"/look-up"}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3FC28A",
                    }
                  : { color: "black" }
              }
              className={`sidebar-list-inner-container ${
                collapsed &&
                "d-flex justify-content-center sidebar-list-inner-container-collapsed"
              }`}
            >
              <div className="hover-hide-logo">
                <LookUpLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <LookUpLogo color={"#3FC28A"} />
              </div>

              <span
                className={`sidebar-list-text ${
                  collapsed && "collapsed-sidebar-list-text d-none"
                }`}
              >
                Look-Up
              </span>
            </NavLink>
          )}
          <NavLink
            to={"/reportees"}
            style={({ isActive }) =>
              isActive
                ? {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    color: "#3FC28A",
                  }
                : { color: "#ffffff" }
            }
            className={`sidebar-list-inner-container ${
              collapsed &&
              "d-flex justify-content-center sidebar-list-inner-container-collapsed"
            }`}
          >
            <div className="hover-hide-logo">
              <ReporteeIcon color={"#FFFFFF"} />
            </div>
            <div className="hover-show-logo">
              <ReporteeIcon color={"#3FC28A"} />
            </div>

            <span
              className={`sidebar-list-text ${
                collapsed && "collapsed-sidebar-list-text d-none"
              }`}
            >
              {role === "ADMIN" ? "Reports" : " My Reportees"}
            </span>
          </NavLink>

          {role === "ADMIN" && (
            <NavLink
              to={"/asset"}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3FC28A",
                    }
                  : { color: "black" }
              }
              className={`sidebar-list-inner-container ${
                collapsed &&
                "d-flex justify-content-center sidebar-list-inner-container-collapsed"
              }`}
            >
              <div className="hover-hide-logo">
                <AssetLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <AssetLogo color={"#3FC28A"} />
              </div>

              <span
                className={`sidebar-list-text ${
                  collapsed && "collapsed-sidebar-list-text d-none"
                }`}
              >
                Asset
              </span>
            </NavLink>
          )}

          {/* <NavLink
          to={"/settings"}
          style={({ isActive }) =>
            isActive
              ? {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "#3FC28A",
                }
              : { color: "#ffffff" }
          }
          className={`sidebar-list-inner-container ${
            collapsed &&
            "d-flex justify-content-center sidebar-list-inner-container-collapsed"
          }`}
        >
          <div className="hover-hide-logo">
            <SettingsLogo color={"#FFFFFF"} />
          </div>
          <div className="hover-show-logo">
            <SettingsLogo color={"#3FC28A"} />
          </div>

          <span
            className={`sidebar-list-text ${
              collapsed && "collapsed-sidebar-list-text d-none"
            }`}
          >
            Settings
          </span>
        </NavLink> */}
        </div>

        <div
          // className={`sidebar-list-collapse-container ${
          //   collapsed && " sidebar-list-inner-container-collapsed-icon"
          // }`}
          className="sidebar-list-collapse-container "
          onClick={() => setCollaped(!collapsed)}
        >
          <span
            // className={`sidebar-list-text  ${
            //   collapsed && "collapsed-sidebar-list-text"
            // }`}
            className="sidebar-list-text "
          >
            {collapsed ? <FaArrowRight /> : <FaArrowLeft />}
          </span>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
