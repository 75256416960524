import React, { useState } from "react";

const ProfileOtherInfo = ({ values }) => {
  const [geoTag, setGeoTag] = useState(values?.isGeotag);
  const [latitude, setLatitude] = useState(values?.latitude);
  const [longitude, setLongitude] = useState(values?.longitude);
  const [range, setRange] = useState(values?.range);
  const [geoFencing, setGeoFencing] = useState(values?.isGeofence);
  const [facial, setFacial] = useState(values?.isFacial);

  return (
    <div className=" employee-list-container ">
      <div className="employee-list-banner ">
        <div
          className=" pt-0 mt-0 w-100"
          style={{
            marginTop: "0",
            borderTop: "0",
            paddingLeft: "15px",
            marginBottom: "15px",
          }}
        >
          <div className="Empoyee-profile-container w-100 ">
            <div className=" d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex gap-2">
                  <label style={{ width: "120px" }}>GEO TAG</label>
                  <input
                    className="checkbox-presence"
                    style={{
                      accentColor: "#3FC28A !important",
                    }}
                    type="checkbox"
                    checked={geoTag}
                    readOnly
                  />
                </div>
              </div>

              <div className="d-flex gap-2">
                <label style={{ width: "120px" }}>GEO FENCING</label>
                <input
                  className="checkbox-presence"
                  style={{
                    accentColor: "#3FC28A !important",
                  }}
                  type="checkbox"
                  checked={geoFencing}
                  readOnly
                />
              </div>
              {geoFencing && (
                <div className="d-flex gap-4">
                  <div className="d-flex gap-2 align-items-center ps-3">
                    <label>Latitude</label>
                    <input
                      className="lat-long-input"
                      value={latitude}
                      readOnly
                    />
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    <label>Longitude</label>
                    <input
                      className="lat-long-input"
                      value={longitude}
                      readOnly
                    />
                  </div>
                  <div className="d-flex gap-2 align-items-center ps-3">
                    <label>Range</label>
                    <input
                      className="lat-long-input"
                      style={{ width: "50px" }}
                      value={range}
                      readOnly
                    />
                    <span style={{ fontSize: 12, color: "gray" }}>Meters</span>
                  </div>
                </div>
              )}

              <div className="d-flex gap-2">
                <label style={{ width: "120px" }}>FACIAL</label>
                <input
                  className="checkbox-presence"
                  style={{
                    accentColor: "#3FC28A !important",
                  }}
                  type="checkbox"
                  checked={facial}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileOtherInfo;
