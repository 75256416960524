import React from "react";

const AssetLogo = ({ color }) => {
  return (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9375 5.59401V5.20026C4.9375 3.73012 4.9375 2.9945 5.22361 2.43298C5.47528 1.93906 5.87656 1.53778 6.37048 1.28611C6.932 1 7.66762 1 9.13776 1H20.4253C21.8954 1 22.6294 1 23.191 1.28611C23.6849 1.53778 24.0875 1.93906 24.3392 2.43298C24.625 2.99395 24.625 3.72868 24.625 5.19594V13.8666C24.625 15.3338 24.625 16.0675 24.3392 16.6285C24.0875 17.1224 23.6851 17.525 23.1912 17.7767C22.6302 18.0625 21.8963 18.0625 20.4291 18.0625H10.8438M1 17.8003V10.4503C1 8.98012 1 8.2445 1.28611 7.68298C1.53778 7.18906 1.93906 6.78778 2.43298 6.53611C2.9945 6.25 3.73012 6.25 5.20026 6.25H5.98776C7.45789 6.25 8.19194 6.25 8.75346 6.53611C9.24738 6.78778 9.65 7.18906 9.90167 7.68298C10.1875 8.24395 10.1875 8.97868 10.1875 10.4459V17.8041C10.1875 19.2713 10.1875 20.005 9.90167 20.566C9.65 21.0599 9.24738 21.4625 8.75346 21.7142C8.19249 22 7.45882 22 5.99156 22H5.19594C3.72868 22 2.99395 22 2.43298 21.7142C1.93906 21.4625 1.53778 21.0599 1.28611 20.566C1 20.0044 1 19.2704 1 17.8003Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AssetLogo;
