import { AppConfig } from "../../../env.development";

export const ASSET_URL = {
  GET_ALL_ASSET: `${AppConfig.api_url}asset/v1/pagination-filter`,
  ADD_ASSET: `${AppConfig.api_url}asset/v1`,
  UPDATE_ASSET:`${AppConfig.api_url}asset/v1`,
  GET_ASSET: `${AppConfig.api_url}asset-master/v1`,
  DELETE_ASSET_DATA:`${AppConfig.api_url}asset/v1`,
  GET_FILTER_ASSET: `${AppConfig.api_url}asset/v1`,

  
};
