import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AllEmployeeNavbar from "../all-employee-navbar/AllEmployeeNavbar";
import AllEmployeesList from "../all-employees-list/AllEmployeesList";

function AllEmployeesRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/all-employees") {
      navigate("/all-employees/employees-list");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="employees-list" element={<AllEmployeesList />} />

      <Route path="add-employees" element={<AllEmployeeNavbar />} />
      <Route path="view-employees" element={<AllEmployeeNavbar />} />
      <Route path="edit-employees" element={<AllEmployeeNavbar />} />
    </Routes>
  );
}

export default AllEmployeesRoutes;
