import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosService } from "../../../../services/axios.service";
import { ALL_EMPLOYEES_URL } from "../../constants/all-employees-urls";
import CancelModal from "../cancel-modal/CancelModal";
import "./EmployeesDocuments.css";
import Dropzone from "react-dropzone";
import { BiCheck } from "react-icons/bi";
import { FaEye } from "react-icons/fa";

function EmployeesDocuments({ onSubmit, type, values, setActiveTab }) {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const containerRef = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [isUploading3, setIsUploading3] = useState(false);
  const [isUploading4, setIsUploading4] = useState(false);
  const [isUploading5, setIsUploading5] = useState(false);
  const [aadharFile, setAadharFile] = useState(values?.aadharFile);
  const [panFile, setPanFile] = useState(values?.panFile);
  const [bankPassbookFile, setBankPassbookFile] = useState(values?.bankPassbookFile);
  const [passportSizePhoto, setPassportSizePhoto] = useState(values?.passportSizePhoto);
  const [degreeFile, setDegreeFile] = useState(values?.degreeFile);
  const [aadharFileName, setAadharFileName] = useState(values?.aadharFileName);
  const [panFileName, setPanFileName] = useState(values?.panFileName);
  const [bankPassbookFileName, setBankPassbookFileName] = useState(values?.bankPassbookFileName);
  const [passportFileName, setPassportFileName] = useState(values?.passportFileName);
  const [degreeFileName, setDegreeFileName] = useState(values?.degreeFileName);
  const handleFileDrop = async (fieldName, acceptedFiles, setFieldValue) => {
    const file = acceptedFiles[0];
    if (acceptedFiles[0]?.name?.length > 150) {
      toast.error("IMAGE LENGTH");
      return;
    }
    const allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];
    const maxFileSize = 2 * 1024 * 1024;

    if (
      file &&
      allowedFileTypes.includes(file.type) &&
      file.size <= maxFileSize
    ) {
      try {
        if (fieldName === "aadharFile") {
          setIsUploading(true);
        } else if (fieldName === "panFile") {
          setIsUploading2(true);
        } else if (fieldName === "bankPassbookFile") {
          setIsUploading3(true);
        } else if (fieldName === "passportSizePhoto") {
          setIsUploading4(true);
          console.log("coming1")
        } else if (fieldName === "degreeFile") {
          setIsUploading5(true);
        } 

        const formData = new FormData();
        formData.append("file", acceptedFiles);
        const response = await axiosService.postFile(
          `${ALL_EMPLOYEES_URL.UPLOAD_FILE_URL}`,
          file,
          acceptedFiles
        );
        if (response.status === 200) {
          toast.success("File Uploaded Successfully");
          const data = response?.data?.data;
          if (fieldName === "aadharFile") {
            setIsUploading(false);
            setAadharFile(data);
            setAadharFileName(acceptedFiles[0]?.name)
          } else if (fieldName === "panFile") {
            setIsUploading2(false);
            setPanFile(data);
            setPanFileName(acceptedFiles[0]?.name)
          } else if (fieldName === "bankPassbookFile") {
            setIsUploading3(false);
            setBankPassbookFile(data);
            setBankPassbookFileName(acceptedFiles[0]?.name)
          } else if (fieldName === "passportSizePhoto") {
            console.log("coming2")
            setIsUploading4(false);
            setPassportSizePhoto(data);
            
            setPassportFileName(acceptedFiles[0]?.name)
          } else if (fieldName === "degreeFile") {
            setIsUploading5(false);
            setDegreeFile(data);
            setDegreeFileName(acceptedFiles[0]?.name)
          } 
        } else {
          toast.error("Upload File Failure");
        }
      } catch (error) {
        if (fieldName === "aadharFile") {
          setIsUploading(false);
        } else if (fieldName === "panFile") {
          setIsUploading2(false);
        } else if (fieldName === "bankPassbookFile") {
          setIsUploading3(false);
        } else if (fieldName === "passportSizePhoto") {
          setIsUploading4(false);
        } else if (fieldName === "degreeFile") {
          setIsUploading5(false);
        }

        if (error.response) {
          toast.error(`Error: ${error.response.data.message}`);
        } 
      }
    } else {
      if (!allowedFileTypes.includes(file.type)) {
        toast.error(
          "Invalid file type. Please upload only .png, .jpeg, .jpg, or .pdf files."
        );
      } else if (file.size > maxFileSize) {
        toast.error("File size exceeds the limit of 2MB.");
      }
    }
  };



  const [errors, setErrors] = useState({});
  const validateFields = (values) => {
    const errors = {};

    if (!aadharFile) {
      errors.aadharFile = "Upload Aadhar File";
    }

    if (!panFile) {
      errors.panFile = "Upload PAN File";
    }

    if (!bankPassbookFile) {
      errors.bankPassbookFile = "Upload Bank Passbook ";
    }
    if (!passportSizePhoto) {
      errors.passportSizePhoto = "Upload Passport Size Photo";
    }
    if (!degreeFile) {
      errors.degreeFile = "Upload Degree Certificate";
    }

    return errors;
  };

  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    return isoDateString;
  }
  const OnEditSubmit = async (values) => {
    let mergedValues;
    try {
      mergedValues = {
        ...values,
        supervisor: { empId: values?.supervisor?.empId },
        employeeDob: convertDateFormat(values?.employeeDob),
        joiningDate: convertDateFormat(values?.joiningDate),
        lastWorkingDate: convertDateFormat(values?.lastWorkingDate),
        aadharFile:aadharFile,
        aadharFileName:aadharFileName,
        panFile:panFile,
        panFileName:panFileName,
        passportSizePhoto:passportSizePhoto,
        passportFileName:passportFileName,
        bankPassbookFile:bankPassbookFile,
        bankPassbookFileName:bankPassbookFileName,
        degreeFile:degreeFile,
        degreeFileName:degreeFileName
      };
      const response = await axiosService.put(
        `${ALL_EMPLOYEES_URL.UPDATE_EMPLOYEE_DATA}`,
        mergedValues
      );

      if (response.status === 200) {
        navigate("/all-employees/employees-list");
        toast.success("Updated successfully");
      }
    } catch (error) {
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(error?.response?.data?.message?.split(":")[1]);
      }
    }
  };

  const handleSubmit = (values) => {
    const validationErrors = validateFields(values);
const sentItems={
  ...values,
  aadharFile:aadharFile,
  aadharFileName:aadharFileName,
  panFile:panFile,
  panFileName:panFileName,
  passportSizePhoto:passportSizePhoto,
  passportFileName:passportFileName,
  bankPassbookFile:bankPassbookFile,
  bankPassbookFileName:bankPassbookFileName,
  degreeFile:degreeFile,
  degreeFileName:degreeFileName
}
    if (type === "View") {
      onSubmit(sentItems, true);
    } else if (type === "Edit") {
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        OnEditSubmit(values, true);
      }
    } else {
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        onSubmit(sentItems, true);
      }
    }
  };

  return (
    <div className=" employee-list-container " ref={containerRef}>
      <div className="employee-list-banner ">
        <div
          className="employee-list-table-holder pt-0 mt-0 w-100"
          style={{
            marginTop: "0",
            borderTop: "0",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <div className="Empoyee-profile-container w-100 ">
            <div className="addEmployee-details  w-100">
              <Formik
                onSubmit={handleSubmit}
                initialValues={values}
                // validationSchema={validateFields}
              >
                {(setFieldValue,touched) => (
                  <Form>
                    <div className="upload-files-outer-container">
                    <div className="upload-files-main-container">
                      <div className="col-3 Employee-document-filename">
                        Aadhar Card *
                      </div>
                      <div className="col-5 employee-document-data">
                      <Dropzone
                          onDrop={async (acceptedFiles) => {
                            setAadharFile(null);
                            handleFileDrop(
                              "aadharFile",
                              acceptedFiles,
                              // setFieldValue
                            );
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps,isDragActive }) => (
                            <div {...getRootProps()} 
                            className="dropzone-data">
                              <input
                                {...getInputProps()}
                                // className="d-none"
                                type="file"
                                id="aadharFile"
                                name="aadharFile"
                              />

                              <div className="document-file-container">
                                <span className="document-choose">
                                  Choose file
                                </span>

                             
                             <span className="document-image">
                             {aadharFileName ? (
                                  aadharFileName
                           
                              ) : (
                                "No file chosen"
                              )}
                                   </span>
                              </div>
                            </div>
                          )}
                         
                        </Dropzone>
                      </div>
                    
                      {(aadharFile) &&
                      !isUploading ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {isUploading && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {(aadharFile) && (
                        <a
                          href={aadharFile}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye color="#000000"/>
                        </a>
                      )}
                     {aadharFile === null && (
                            <span className="error-message-text-document">
                              {errors.aadharFile}
                            </span>
                          )}
                    </div>
                    <div className="upload-files-main-container">
                      <div className="col-3 Employee-document-filename">
                        PAN Card *
                      </div>
                      <div className="col-5 employee-document-data">
                      <Dropzone
                          onDrop={async (acceptedFiles) => {
                            setPanFile(null);
                            handleFileDrop(
                              "panFile",
                              acceptedFiles,
                              // setFieldValue
                            );
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps,isDragActive }) => (
                            <div {...getRootProps()} 
                            className="dropzone-data">
                              <input
                                {...getInputProps()}
                                // className="d-none"
                                type="file"
                                id="panFile"
                                name="panFile"
                              />

                              <div className="document-file-container">
                                <span className="document-choose">
                                  Choose file
                                </span>

                             
                             <span className="document-image">
                             {panFileName ? (
                                  panFileName
                           
                              ) : (
                                "No file chosen"
                              )}
                                   </span>
                              </div>
                            </div>
                          )}
                         
                        </Dropzone>
                      </div>
                    
                      {(panFile) &&
                      !isUploading2 ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {isUploading2 && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {(panFile) && (
                        <a
                          href={panFile}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye color="#000000"/>
                        </a>
                      )}
                       {panFile === null && (
                            <span className="error-message-text-document">
                              {errors.panFile}
                            </span>
                          )}
                    </div>
                    <div className="upload-files-main-container">
                      <div className="col-3 Employee-document-filename">
                        Bank Passbook *
                      </div>
                      <div className="col-5 employee-document-data">
                      <Dropzone
                          onDrop={async (acceptedFiles) => {
                            setBankPassbookFile(null);
                            handleFileDrop(
                              "bankPassbookFile",
                              acceptedFiles,
                              // setFieldValue
                            );
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps,isDragActive }) => (
                            <div {...getRootProps()} 
                            className="dropzone-data">
                              <input
                                {...getInputProps()}
                                // className="d-none"
                                type="file"
                                id="bankPassbookFile"
                                name="bankPassbookFile"
                              />

                              <div className="document-file-container">
                                <span className="document-choose">
                                  Choose file
                                </span>

                             
                             <span className="document-image">
                             {bankPassbookFileName ? (
                                  bankPassbookFileName
                           
                              ) : (
                                "No file chosen"
                              )}
                                   </span>
                              </div>
                            </div>
                          )}
                         
                        </Dropzone>
                      </div>
                    
                      {(bankPassbookFile) &&
                      !isUploading3 ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {isUploading3 && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {(bankPassbookFile) && (
                        <a
                          href={bankPassbookFile}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye color="#000000"/>
                        </a>
                      )}
                       {bankPassbookFile === null && (
                            <span className="error-message-text-document">
                              {errors.bankPassbookFile}
                            </span>
                          )}
                    </div>
                    <div className="upload-files-main-container">
                      <div className="col-3 Employee-document-filename">
                        Passport Size Photo *
                      </div>
                      <div className="col-5 employee-document-data">
                      <Dropzone
                          onDrop={async (acceptedFiles) => {
                            setPassportSizePhoto(null);
                            handleFileDrop(
                              "passportSizePhoto",
                              acceptedFiles,
                              // setFieldValue
                            );
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps,isDragActive }) => (
                            <div {...getRootProps()} 
                            className="dropzone-data">
                              <input
                                {...getInputProps()}
                                // className="d-none"
                                type="file"
                                id="passportSizePhoto"
                                name="passportSizePhoto"
                              />

                              <div className="document-file-container">
                                <span className="document-choose">
                                  Choose file
                                </span>

                             
                             <span className="document-image">
                             {passportFileName ? (
                                  passportFileName
                           
                              ) : (
                                "No file chosen"
                              )}
                                   </span>
                              </div>
                            </div>
                          )}
                         
                        </Dropzone>
                      </div>
                    
                      {(passportSizePhoto) &&
                      !isUploading4 ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {isUploading4 && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {(passportSizePhoto) && (
                        <a
                          href={passportSizePhoto}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye color="#000000"/>
                        </a>
                      )}
                       {passportSizePhoto === null && (
                            <span className="error-message-text-document">
                              {errors.passportSizePhoto}
                            </span>
                          )}
                    </div>
                    <div className="upload-files-main-container">
                      <div className="col-3 Employee-document-filename">
                        Degree *
                      </div>
                      <div className="col-5 employee-document-data">
                      <Dropzone
                          onDrop={async (acceptedFiles) => {
                            setDegreeFile(null);
                            handleFileDrop(
                              "degreeFile",
                              acceptedFiles,
                              // setFieldValue
                            );
                          }}
                          accept="image/*, application/pdf"
                        >
                          {({ getRootProps, getInputProps,isDragActive }) => (
                            <div {...getRootProps()} 
                            className="dropzone-data">
                              <input
                                {...getInputProps()}
                                // className="d-none"
                                type="file"
                                id="degreeFile"
                                name="degreeFile"
                              />

                              <div className="document-file-container">
                                <span className="document-choose">
                                  Choose file
                                </span>

                             
                             <span className="document-image">
                             {degreeFileName ? (
                                  degreeFileName
                           
                              ) : (
                                "No file chosen"
                              )}
                                   </span>
                              </div>
                            </div>
                          )}
                         
                        </Dropzone>
                      </div>
                    
                      {(degreeFile) &&
                      !isUploading5 ? (
                        <BiCheck color="green" size={25} />
                      ) : null}

                      {isUploading5 && (
                        <div className="lds-ring lds-ring-uploadpage">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {(degreeFile) && (
                        <a
                          href={degreeFile}
                          target="_blank"
                          rel="noreferrer"
                          className="view-eye-icon"
                        >
                          <FaEye color="#000000"/>
                        </a>
                      )}
                       {degreeFile === null && (
                            <span className="error-message-text-document">
                              {errors.degreeFile}
                            </span>
                          )}
                    </div>
                    </div>

                
                    <div
                      className="add-Employeepage-buttons"
                      style={{ textAlign: "right", marginTop: "20px" }}
                    >
                      {type !== "View" && (
                        <button
                          className="Cancel-btn"
                          type="button"
                          onClick={() => setModalOpen(true)}
                        >
                          Cancel
                        </button>
                      )}
                      {type === "View" && (
                        <button
                          className="Cancel-btn"
                          type="button"
                          onClick={() =>
                            navigate("/all-employees/employees-list")
                          }
                        >
                          Cancel
                        </button>
                      )}
                      {modalOpen && (
                        <CancelModal
                          modalOpen={modalOpen}
                          closeModal={() => setModalOpen(false)}
                          onSubmit={() =>
                            navigate("/all-employees/employees-list")
                          }
                          cancelButton={true}
                          submitButton={true}
                          onCloseTitle={"Cancel"}
                        />
                      )}
                      {type === "Add" ||
                      (type !== "View" && type !== "Edit") ? (
                        <button
                          className="add-button-prev"
                          type="button"
                          onClick={() => setActiveTab("Asset")}
                        >
                          Previous
                        </button>
                      ) : null}
                      {type === "Edit" ? (
                        <button type="editSubmit" className="add-button-next">
                          Update
                        </button>
                      ) : type === "View" ? (
                        ""
                      ) : (
                        <button type="submit" className="add-button-next">
                          Next
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeesDocuments;
