import { Form, Formik } from "formik";
import React from "react";
import View from "../../../../assets/images-svg/View";


const Mydocument = ({values}) => {
  console.log(values,"VVVVVVVVV")
  return (
    <div className="my-profile-content pt-1">
      <Formik>
        <Form>
          <div className="row add-document-container ">
            <div className="row mb-3">
              <div className="col-lg-2 Employee-document-filename">AADHAAR</div>
              <div className="col-lg-5 employee-document-data">
                <div className="document-file-container">
                  <span className="document-choose">Choose Files</span>
                  <span className="document-image">{values?.aadharFileName}</span>
                </div>
              </div>
              <div
                className="col-lg-2"
                style={{ padding: "10px 0px 0px 10px" }}
              >
                <a
                  href={values?.aadharFile}
                  target="_blank"
                  rel="noreferrer"
                  className="document-view-eye-icon"
                >
                  <View />
                </a>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 Employee-document-filename">PAN</div>
              <div className="col-lg-5 employee-document-data">
                <div className="document-file-container">
                  <span className="document-choose">Choose Files</span>
                  <span className="document-image">{values?.panFileName}</span>
                </div>
              </div>
              <div
                className="col-lg-2"
                style={{ padding: "10px 0px 0px 10px" }}
              >
                <a
                  href={values?.panFile}
                  target="_blank"
                  rel="noreferrer"
                  className="document-view-eye-icon"
                >
                  <View />
                </a>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 Employee-document-filename">
                BANK PASSBOOK
              </div>
              <div className="col-lg-5 employee-document-data">
                <div className="document-file-container">
                  <span className="document-choose">Choose Files</span>
                  <span className="document-image">{values?.bankPassbookFileName}</span>
                </div>
              </div>
              <div
                className="col-lg-2"
                style={{ padding: "10px 0px 0px 10px" }}
              >
                <a
                  href={values?.bankPassbookFile}
                  target="_blank"
                  rel="noreferrer"
                  className="document-view-eye-icon"
                >
                  <View />
                </a>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 Employee-document-filename">PHOTO</div>
              <div className="col-lg-5 employee-document-data">
                <div className="document-file-container">
                  <span className="document-choose">Choose Files</span>
                  <span className="document-image">{values?.passportFileName}</span>
                </div>
              </div>
              <div
                className="col-lg-2"
                style={{ padding: "10px 0px 0px 10px" }}
              >
                <a
                  href={values?.passportSizePhoto}
                  target="_blank"
                  rel="noreferrer"
                  className="document-view-eye-icon"
                >
                  <View />
                </a>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-2 Employee-document-filename">Degree </div>
              <div className="col-lg-5 employee-document-data">
                <div className="document-file-container">
                  <span className="document-choose">Choose Files</span>
                  <span className="document-image">{values?.degreeFileName}</span>
                </div>
              </div>
              <div
                className="col-lg-2"
                style={{ padding: "10px 0px 0px 10px" }}
              >
                <a
                  href={values?.degreeFile}
                  target="_blank"
                  rel="noreferrer"
                  className="document-view-eye-icon"
                >
                  <View />
                </a>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Mydocument;
