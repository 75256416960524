function Logout() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 11.6666L15.9596 10.7071C16.3501 10.3165 16.3501 9.68338 15.9596 9.29285L15 8.33329"
        stroke="#E25319"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8333 10L10.8333 10M3.33325 14.3886V6.05527M13.3333 14.3886C13.3333 15.3091 12.5871 16.0553 11.6666 16.0553H8.33325M13.3333 6.05527C13.3333 5.1348 12.5871 4.38861 11.6666 4.38861H8.33325M4.07542 16.5501L5.74209 17.6612C6.84968 18.3996 8.33325 17.6056 8.33325 16.2744V4.16947C8.33325 2.83831 6.84968 2.04432 5.74209 2.78272L4.07542 3.89383C3.61175 4.20294 3.33325 4.72333 3.33325 5.28058V15.1633C3.33325 15.7206 3.61175 16.2409 4.07542 16.5501Z"
        stroke="#E25319"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}
export default Logout;
