import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { axiosService } from "../../../../services/axios.service";
import { LOOKUP_URL } from "../../../look-up/constants/all-look-up-urls";
import { ALL_EMPLOYEES_URL } from "../../constants/all-employees-urls";
import { useNavigate } from "react-router";
import CancelModal from "../cancel-modal/CancelModal";

const AllEmployeeOtherInfo = ({ onSubmit, type, values, setActiveTab }) => {
  const [geoTag, setGeoTag] = useState(values?.isGeotag);
  const [latitude, setLatitude] = useState(values?.latitude);
  const [longitude, setLongitude] = useState(values?.longitude);
  const [range, setRange] = useState(values?.range);
  const [geoFencing, setGeoFencing] = useState(values?.isGeofence);
  const [facial, setFacial] = useState(values?.isFacial);
  const [userMasterId, setUserMasterId] = useState(null);
  const containerRef = useRef();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  // const handleSubmit = async () => {
  //   const payload = {
  //     isGeotag: geoTag,
  //     isGeofence: geoFencing,
  //     isFacial: facial,
  //     latitude: latitude,
  //     longitude: longitude,
  //     range: range,
  //     userMasterId: userMasterId,
  //   };

  //   if (!geoFencing || (latitude && longitude && range)) {
  //     try {
  //       const response = await axiosService.put(
  //         LOOKUP_URL.POST_PRESENCE_DETAILS,

  //         payload
  //       );
  //       if (response) {
  //         toast.success("data updated successfully");
  //       }
  //     } catch (error) {
  //       console.log(error, "error");
  //     }
  //   } else {
  //     toast.error(
  //       "Latitude,longitude and Range are required when geo-tagging is enabled."
  //     );
  //   }
  // };

  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    return isoDateString;
  }
  console.log(values, "VVVVVVVVVINFO");
  const newSave = async () => {
    let mergedValues;
    try {
      const mergedValues = {
        ...values,
        isGeotag: geoTag,
        isGeofence: geoFencing,
        isFacial: facial,
        latitude: latitude,
        longitude: longitude,
        range: range,
        supervisor: { empId: values?.supervisor?.empId },
        employeeDob: convertDateFormat(values?.employeeDob),
        joiningDate: convertDateFormat(values?.joiningDate),
        lastWorkingDate: convertDateFormat(values?.lastWorkingDate),
        assetList: values?.assetList?.every((asset) =>
          Object.values(asset).every((value) => value === "" || value === null)
        )
          ? []
          : values?.assetList,
      };

      console.log(mergedValues, "SAVE VALUES");
      const response = await axiosService.post(
        `${ALL_EMPLOYEES_URL.ADD_EMPLOYEE_DATA}`,
        mergedValues
      );

      if (response.status === 200) {
        navigate("/all-employees/employees-list");
        toast.success("Successfully a new employee has been added");
      }
    } catch (error) {
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(error?.response?.data?.message?.split(":")[1]);
      }
    }
  };

  const editSave = async () => {
    let mergedValues;

    try {
      mergedValues = {
        ...values,
        supervisor: { empId: values?.supervisor?.empId },
        isGeotag: geoTag,
        isGeofence: geoFencing,
        isFacial: facial,
        latitude: latitude,
        longitude: longitude,
        range: range,
        employeeDob: convertDateFormat(values?.employeeDob),
        joiningDate: convertDateFormat(values?.joiningDate),
        lastWorkingDate: convertDateFormat(values?.lastWorkingDate),
      };
      console.log(mergedValues, "EDIT VALUES");
      const response = await axiosService.put(
        `${ALL_EMPLOYEES_URL.UPDATE_EMPLOYEE_DATA}`,
        mergedValues
      );

      if (response.status === 200) {
        navigate("/all-employees/employees-list");
        toast.success("Updated successfully");
      }
    } catch (error) {
      console.error(error?.response, "response::");
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(error?.response?.data?.message?.split(":")[1]);
      }
    }
  };
  // const getData = async () => {
  //   try {
  //     const response = await axiosService.get(LOOKUP_URL.POST_PRESENCE_DETAILS);

  //     if (response) {
  //       console.log(response);
  //       const data =
  //         response?.data?.data?.length > 0 ? response?.data?.data[0] : {};
  //       setGeoFencing(data?.isGeofence);
  //       setGeoTag(data?.isGeotag);
  //       setFacial(data?.isFacial);
  //       setUserMasterId(data?.userMasterId);
  //       setRange(data?.range);
  //       setLatitude(data?.latitude);
  //       setLongitude(data?.longitude);
  //     }
  //   } catch {}
  // };
  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <div className=" employee-list-container " ref={containerRef}>
      <div className="employee-list-banner ">
        <div
          className="employee-list-table-holder pt-0 mt-0 w-100"
          style={{
            marginTop: "0",
            borderTop: "0",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <div className="Empoyee-profile-container w-100 ">
            <div className=" d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-2">
                <div className="d-flex gap-2">
                  <label style={{ width: "120px" }}>GEO TAG</label>
                  <input
                    className="checkbox-presence"
                    style={{
                      accentColor: "#3FC28A !important",
                    }}
                    type="checkbox"
                    checked={geoTag}
                    onChange={() => {
                      setGeoTag(!geoTag);
                    }}
                  />
                </div>
              </div>

              <div className="d-flex gap-2">
                <label style={{ width: "120px" }}>GEO FENCING</label>
                <input
                  className="checkbox-presence"
                  style={{
                    accentColor: "#3FC28A !important",
                  }}
                  type="checkbox"
                  checked={geoFencing}
                  onChange={() => {
                    setRange(null);
                    setLatitude(null);
                    setLongitude(null);
                    setGeoFencing(!geoFencing);
                  }}
                />
              </div>
              {geoFencing && (
                <div className="d-flex gap-4">
                  <div className="d-flex gap-2 align-items-center ps-3">
                    <label>Latitude</label>
                    <input
                      className="lat-long-input"
                      value={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                    />
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    <label>Longitude</label>
                    <input
                      className="lat-long-input"
                      value={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                    />
                  </div>
                  <div className="d-flex gap-2 align-items-center ps-3">
                    <label>Range</label>
                    <input
                      className="lat-long-input"
                      style={{ width: "50px" }}
                      value={range}
                      onChange={(e) => setRange(e.target.value)}
                    />
                    <span style={{ fontSize: 12, color: "gray" }}>Meters</span>
                  </div>
                </div>
              )}

              <div className="d-flex gap-2">
                <label style={{ width: "120px" }}>FACIAL</label>
                <input
                  className="checkbox-presence"
                  style={{
                    accentColor: "#3FC28A !important",
                  }}
                  type="checkbox"
                  checked={facial}
                  onChange={() => setFacial(!facial)}
                />
              </div>
              <div
                className="add-Employeepage-buttons"
                style={{ textAlign: "right", marginTop: "20px" }}
              >
                {type !== "View" && (
                  <button
                    className="Cancel-btn"
                    type="button"
                    onClick={() => setModalOpen(true)}
                  >
                    Cancel
                  </button>
                )}
                {type === "View" && (
                  <button
                    className="Cancel-btn"
                    type="button"
                    onClick={() => navigate("/all-employees/employees-list")}
                  >
                    Cancel
                  </button>
                )}
                {modalOpen && (
                  <CancelModal
                    modalOpen={modalOpen}
                    closeModal={() => setModalOpen(false)}
                    onSubmit={() => navigate("/all-employees/employees-list")}
                    cancelButton={true}
                    submitButton={true}
                    onCloseTitle={"Cancel"}
                  />
                )}
                {type === "Add" || (type !== "View" && type !== "Edit") ? (
                  <button
                    className="add-button-prev"
                    type="button"
                    onClick={() => setActiveTab("Documents")}
                  >
                    Previous
                  </button>
                ) : null}
                {type === "Edit" ? (
                  <button
                    type="editSubmit"
                    className="add-button-next"
                    onClick={() => editSave()}
                  >
                    Update
                  </button>
                ) : type === "View" ? (
                  ""
                ) : (
                  <button
                    type="submit"
                    className="add-button-next"
                    onClick={() => newSave()}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllEmployeeOtherInfo;
