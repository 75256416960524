import "./ForgotPassword.css";
import MidfinLogo from "../../../../assets/images-png/midfin-logo.png";
import MifinFont from "../../../../assets/images-png/midfin-logofont.png";
import { Field, Form, Formik } from "formik";
import WorkImage from "../../../../assets/images-png/LoginWork-image.png";
import Cactus from "../../../../assets/images-png/Cactus.png.png";
import { Link } from "react-router-dom";
import BackArrow from "../../../../assets/images-svg/BackArrow";

function ForgotPassword() {
  return (
    <div className="container-fluid Login-banner">
      <div className="row d-flex image-row">
        <div className="col-md-7 col-sm-12 col-11 login-card-col">
          <div className="logincard-con">
            <div className="Login-card p-5">
              <div className="Midfin-logo d-flex">
                <div className="Midfin-LogoPng">
                  <img src={MidfinLogo} alt="Logo" className="Midfin-png" />
                </div>
                <div className="midfin-LogoFont">
                  <img src={MifinFont} alt="Logo" className="Midfin-png-text" />
                </div>
              </div>
              <Link className="back-text " to="/">
                <BackArrow />
                Back
              </Link>
              <div className="welcome-text mt-2">Forgot Password</div>
              <div className="loginhere-text">
                Enter your registered email address. we’ll send you a code to
                reset your password.
              </div>
              <Formik>
                <Form className="loginform-container">
                  <div className="login-inputs">
                    <label htmlFor="" className="Login-Label">
                      Email address
                    </label>
                    <Field className="login-inputBox" name="username"></Field>
                  </div>

                  <button className="Login-button">Send OTP</button>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
        <div className="col-md-5 position-relative d-none d-md-block p-0">
          <div
            className="loginimage-background me-0"
            style={{ height: "100%" }}
          ></div>
          <div className="work-with-laptop-image position-absolute ">
            <img src={Cactus} alt="Cactus" className="Cactus-image" />
          </div>
          <div className="work-with-laptop-image position-absolute ">
            <img src={WorkImage} alt="Laptop" className="WorkingLaptopImage" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
