import React, { useEffect, useState, useCallback } from "react";
import "./DashboardNavBar.css";
import MyDashboard from "../myDashboard/MyDashboard";
import MyReportees from "../myReportees/MyReportees";
import { axiosService } from "../../../../services/axios.service";
import { DASHBOARD_URL } from "../../constants/dashboard-urls";
import sessionService from "../../../../services/sessionstorage.service";

const DashboardNavBar = () => {
  // const [activeTab, setActiveTab] = useState("My-Reportees");
  const [activeTab, setActiveTab] = useState("");
  const [reporteesData, setReporteesData] = useState(null);

  const User = sessionService.getUserData("userData");
  console.log(User, "uuuuuuuuuuuuuuuuuuuuuuuu")
  const empId = User?.employee?.empId;

  // useEffect(() => {
  //   reporteesData?.totalCount > 0 ? setActiveTab("My-Reportees") : setActiveTab("My-Dashboard");
  // }, [reporteesData]);

  const fetchReporteesData = useCallback(async (empId) => {
    try {
      const response = await axiosService.get(
        `${DASHBOARD_URL.GET_DASHBOARD_REPORTEES_URL}`,
        { empId }
      );
      setReporteesData(response.data);
      setActiveTab(response.data.totalCount > 0 ? "My-Reportees" : "My-Dashboard");
      console.log(response.data, "Data fetched successfully");
    } catch (error) {
      console.error("Error fetching attendance overview:", error);
    }
  }, []);

  useEffect(() => {
    if (empId) {
      fetchReporteesData(empId);
    }
  }, [empId, fetchReporteesData]);



  // useEffect(() => {
  //   // const navbar = document.getElementById("navbarSupportedContent");
  //   // navbar.addEventListener("hidden.bs.collapse", () => {
  //   //   setNavbarCollapsed(true);
  //   // });
  //   // navbar.addEventListener("shown.bs.collapse", () => {
  //   //   setNavbarCollapsed(false);
  //   // });
  // }, []);

  return (
    <div
      className="dashboard-main-div"
      style={{ backgroundColor: "#D9D9D933" }}
    >
      <div
        className="container-fluid g-0"
        style={{ width: "96%", marginTop: "10px" }}
      >
        <div
        // id="navbarSupportedContent"
        >
          <ul
            className="add-employee-nav gap-md-3 border-bottom w-100 ps-0 d-md-flex flex-wrap"
            style={{ marginBottom: "0" }}
          >
            {reporteesData?.totalCount > 0 && (
              <span
                className={`add-NewEmployee-navbar-text ${activeTab === "My-Reportees" && "active"
                  }`}
                onClick={() => setActiveTab("My-Reportees")}
              >
                My Reportees
              </span>
            )}
            {User?.role !== "ADMIN" && (
            <span
              className={`add-NewEmployee-navbar-text ${activeTab === "My-Dashboard" && "active"
                }`}
              onClick={() => setActiveTab("My-Dashboard")}
            >
              My Dashboard
            </span>
            )}
          </ul>
        </div>
      </div>

      <div className="dashboard-content-div">
        {activeTab === "My-Reportees" && (
          <MyReportees reporteesData={reporteesData} />
        )}

        {activeTab === "My-Dashboard" && <MyDashboard />}
      </div>
    </div>
  );
};

export default DashboardNavBar;
