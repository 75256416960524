import { Field, Form, Formik } from "formik";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import React from "react";

const MyProfessionalInfo = ({ values }) => {
  return (
    <div className="my-profile-content">
      <Formik initialValues={values} enableReinitialize={true}>
        {({ values }) => (
          <Form>
            <div className="row add-professional-container ">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="employeeId"
                    type="text"
                    readOnly
                    value={values.employeeId}
                    placeholder=""
                    style={{
                      backgroundColor: values.employeeId
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.employeeId
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Employee ID</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="userName"
                    value={values.userName}
                    type="text"
                    style={{
                      backgroundColor: values.userName
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.userName
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                    placeholder=""
                    readOnly
                  />
                  <label className="add-Employee-label">User Name</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text "
                    name="designation"
                    type="text"
                    readOnly
                    value={values.designation}
                    placeholder=""
                    style={{
                      backgroundColor: values.designation
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.designation
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Designation</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    type="text"
                    name="officeEmail"
                    readOnly
                    placeholder=""
                    style={{
                      backgroundColor: values.officeEmail
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.officeEmail
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">
                    Office Email Address
                  </label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text "
                    name="designation"
                    type="text"
                    readOnly
                    value={values?.joiningDate?.split("-").reverse().join("/")}
                    placeholder=""
                    style={{
                      backgroundColor: values.joiningDate
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.joiningDate
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">
                    Select Joining Date
                  </label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="salary"
                    type="text"
                    readOnly
                    value={values.salary}
                    placeholder=""
                    style={{
                      backgroundColor: values.salary
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.salary
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Current Salary</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text "
                    name="active"
                    type="text"
                    readOnly
                    value={values.employeeStatus}
                    placeholder=""
                    style={{
                      backgroundColor: values.employeeStatus
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.employeeStatus
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Active</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text "
                    name="supervisor"
                    type="text"
                    readOnly
                    placeholder=""
                    value={values.supervisor.employeeFirstName}
                    style={{
                      backgroundColor: values.supervisor
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.supervisor
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Supervisor</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="designation"
                    type="text"
                    disabled={!values.lastWorkingDate}
                    value={
                      values.lastWorkingDate
                        ? values.lastWorkingDate.split("-").reverse().join("/")
                        : ""
                    }
                    placeholder=""
                    style={{
                      backgroundColor: values.lastWorkingDate
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.lastWorkingDate
                        ? "1px solid rgba(0, 0, 0, 0.5)"
                        : "none",
                    }}
                  />

                  <label className="add-Employee-label">Last Working Day</label>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyProfessionalInfo;
