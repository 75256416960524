import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Pagination } from "flowbite-react";
import { useFormik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import React, { useEffect, useRef, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Delete from "../../../assets/images-svg/Delete";
import Dropdowns from "../../../assets/images-svg/Drop_down";
import Edit from "../../../assets/images-svg/Edit";
import Eye from "../../../assets/images-svg/Eye";
import LeftArrow from "../../../assets/images-svg/LeftArrow";
import RightArrow from "../../../assets/images-svg/RightArrow";
import Vector from "../../../assets/images-svg/Vector";
import { axiosService } from "../../../services/axios.service";
import sessionService from "../../../services/sessionstorage.service";
import { LEAVE_URL } from "../../leaves/constants/leave-urls";
import "./LeaveLists.css";
function LeaveLists() {
  const User = sessionService.getUserData("userData");
  const empId = User?.employee?.empId;
  const [attendanceData, setAttendanceData] = useState([]);

  const [leaveId, setLeaveId] = useState();
  const [dates, setDates] = useState(null);

  const [startDateWithDifference, setStartDateWithDifference] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const cardsPerPageOptions = [10, 20, 30];
  const [showStartDayInput, setShowStartDayInput] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(cardsPerPageOptions[0]);
  const [leaveBalances, setLeaveBalances] = useState({
    SICK_LEAVE: 0,
    PERSONAL_LEAVE: 0,
    TWO_HOUR_PERMISSION: 0,
    WFH: 0,
  });
  const [appComments, setAppComments] = useState("");

  const [viewDialog, setViewDialog] = useState(false);

  const [balanceType, setBalanceType] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [InitialValues, setInitialValues] = useState({
    dates: [],
    duration: "",
    isHalfDay: false,
    comments: "",
  });

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("Date is required"),
    duration: Yup.number().required("Duration is required"),

    isHalfDay: Yup.boolean().required("HalfDay is required"),
    comments: Yup.string()
      .required("Comments is required")
      .trim()
      .notOneOf([""], "Field cannot be empty"),
  });

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => { },
  });

  useEffect(() => {
    if (!dates || dates.length === 0) {
      setInputFocused(false);
    }
  }, [dates]);

  useEffect(() => {
    if (dates && dates.length) {
      setInputFocused(true);
    }
  }, [dates]);

  const Types = [
    { value: "SICK_LEAVE", name: "Sick Leave" },
    { value: "PERSONAL_LEAVE", name: "Personal Leave" },
    { value: "PRIVILEGE_LEAVE", name: "Privilege Leave" },
    { value: "WFH", name: "Wfh" },
    { value: "TWO_HOUR_PERMISSION", name: "Two Hours Permission" },
    { value: "LOSS_OF_PAY", name: "Loss Of Pay" },
  ];

  const days = [
    { value: "STARTDAY", name: "Start Day" },
    { value: "ENDDAY", name: "End Day" },
    { value: "STARTANDENDDAY", name: "Start and End Day" },
    { value: "ALLDAYS", name: "All Day" },
  ];

  const names = [
    { value: "PENDING", label: "Pending" },
    { value: "APPROVED", label: "Approved" },
    { value: "REJECTED", label: "Rejected" },
    { value: "CANCELLED", label: "Cancelled" },
    { value: "APPROVEDANDCANCELLED", label: "Approved and Cancelled" },
  ];

  useEffect(() => {
    if (formik.values.leaveType === "LOSS_OF_PAY") {
      setBalanceType("");
    }
  }, [formik.values.leaveType]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleResetForm = () => {
    setDates(null);
    setStartDateWithDifference(0);
    setBalanceType("");
    formik.values.leaveType = "";
    setShowStartDayInput("");
    formik.values.halfType = "";
    formik.values.comments = "";
    formik.resetForm();
  };
  const handleClose = async () => {
    formik.values.leaveType = "";
    formik.values.comments = "";
    formik.values.halfType = "";
    setDates(null);

    setBalanceType(null);
    setStartDateWithDifference(0);
    setShowStartDayInput("");

    setLeaveId();

    setInitialValues({
      dates: [],
      duration: "",
      isHalfDay: false,
      comments: "",
    });
    await setViewDialog(false);
    await setOpen(false);
  };

  const handleStatusFilterChange = (selectedOption) => {
    setStatusFilter(selectedOption);
  };

  function convertDate(a) {
    const date = new Date(a);

    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, "0");
    const day = String(date?.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  function handleDate(e) {
    setDates(e.value);

    if (
      e.value &&
      e.value != null &&
      e.value[0] != null &&
      e.value[1] != null
    ) {
      const Date1 = moment(e.value[0]);
      const Date2 = moment(e.value[1]);
      const differenceInDays = Date2.diff(Date1, "days") + 1;

      if (showStartDayInput) {
        setStartDateWithDifference(differenceInDays - 0.5);
      } else {
        setStartDateWithDifference(differenceInDays);
      }
    }

    if (e.value[1]) {
      formik.values.startDate = convertDate(e.value[0]);
      formik.values.endDate = convertDate(e.value[1]);
    }
  }

  const [holidays, setHolidays] = useState(null);
  useEffect(() => {
    axiosService
      .get(LEAVE_URL.GET_ALL_HOLIDAYS)
      .then((res) => {
        setHolidays(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log(formik.values.halfType, "half");
  function calculateDuration() {
    const start = new Date(formik.values.startDate);
    const end = new Date(formik.values.endDate);
    let days = 0;

    while (start <= end) {
      const dayOfWeek = start.getDay();
      const date = start.getDate();
      const month = start.getMonth() + 1;

      const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
      const isHoliday = holidays.some((holiday) => {
        const holidayDate = new Date(holiday.holidayDate);
        return (
          holidayDate.getDate() === date && holidayDate.getMonth() + 1 === month
        );
      });

      if (!isWeekend && !isHoliday) {
        days++;
      }
      start.setDate(start.getDate() + 1);
    }

    if (
      formik.values.halfType &&
      new Date(formik.values.startDate) > new Date(formik.values.endDate)
    ) {
      return 0;
    }

    if (formik.values.halfType) {
      if (
        formik.values.halfType === "STARTDAY" ||
        formik.values.halfType === "ENDDAY"
      ) {
        return days - 0.5;
      } else if (formik.values.halfType === "STARTANDENDDAY") {
        return days - 1;
      } else if (formik.values.halfType === "ALLDAYS") {
        return days / 2;
      }
    }
    return days;
  }

  const getDuration = () => {
    if (formik.values.startDate && formik.values.endDate) {
      return calculateDuration();
    }
    return "";
  };
  const [duration, setDuration] = useState(null);
  console.log(duration, "DURATION");
  function editObject(id) {
    const filteredObject = attendanceData.filter((a) => a.leaveId === id);

    setLeaveId(filteredObject[0].leaveId);

    formik.values.startDate = filteredObject[0].startDate;
    formik.values.endDate = filteredObject[0].endDate;
    formik.values.duration = filteredObject[0].duration;

    setDates([
      new Date(filteredObject[0].startDate),
      new Date(filteredObject[0].endDate),
    ]);

    setStartDateWithDifference(filteredObject[0].duration);

    formik.values.leaveType = filteredObject[0].leaveType;
    setShowStartDayInput(filteredObject[0].isHalfDay);

    formik.values.halfType = filteredObject[0].halfType;
    formik.values.comments = filteredObject[0].comments;
    setBalanceType(leaveBalances[filteredObject[0].leaveType]);
    setAppComments(filteredObject[0].requestComments);
    handleClickOpen();
  }

  const fetchData = async () => {
    const url = `${LEAVE_URL.GET_LEAVES}${empId}`;

    const params = {
      pageNumber: currentPage,
      itemsPerPage: recordsPerPage,
    };

    if (statusFilter) {
      params.leaveStatus = statusFilter?.value;
    }

    try {
      const res = await axiosService.get(url, params);

      setAttendanceData(res?.data?.data?.data);
      setTotalPages(res.data.data.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [recordsPerPage, currentPage, statusFilter]);

  const handleAddSubmit = async () => {
    const formData = {
      employee: {
        empId,
      },
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
      duration: duration,
      leaveType: formik.values.leaveType,
      isHalfDay: showStartDayInput ? showStartDayInput : false,
      comments: formik.values.comments,
    };
    if (showStartDayInput) {
      formData.halfType = formik.values.halfType;
    }

    if (!formik.values.leaveType) {
      toast.error("Leave type is manditory.");
      return;
    }

    try {
      const response = await axiosService.post(
        LEAVE_URL.ADD_LEAVES,
        formData,
        {}
      );
      if (response.status === 200) {
        toast.success("Leave Request Submitted Successfully!", {
          autoClose: 3000,
          closeButton: false,
          style: { backgroundColor: "green", color: "white" },
        });
        formik.resetForm();
        handleResetForm();
        handleClose();
        fetchData();

        LeaveMaster();
      }
    } catch (error) {
      console.error("Error:", error?.response?.data?.message);

      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleEditSubmit = async () => {
    const editData = {
      leaveId: leaveId,
      employee: {
        empId,
      },

      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
      duration: duration,
      leaveType: formik.values.leaveType,
      halfType: formik.values.halfType,
      isHalfDay: showStartDayInput,
      comments: formik.values.comments,
    };

    try {
      const response = await axiosService.put(LEAVE_URL.EDIT_LEAVES, editData);
      if (response.status === 200) {
        toast.success("Leave Request Updated Successfully!", {
          autoClose: 3000,
          closeButton: false,
          style: { backgroundColor: "green", color: "white" },
        });
        handleClose();
        handleResetForm();
        setLeaveId();
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const LeaveMaster = () => {
    axiosService
      .get(`${LEAVE_URL.GET_EMPLOYEE_LEAVES}${empId}`)
      .then((res) => {
        setLeaveBalances({
          SICK_LEAVE: res.data.sickLeaveBalance,
          PERSONAL_LEAVE: res.data.personalLeaveBalance,
          TWO_HOUR_PERMISSION: res.data.permissionBalance,
          WFH: res.data.wfhBalance,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    LeaveMaster();
  }, []);

  const handleChangeLeaveType = (e) => {
    setBalanceType(leaveBalances[e.value]);
    formik.values.leaveType = e.value;
  };

  const handleCancelLeave = async (leaveId, status) => {
    if (
      status !== "REJECTED" &&
      status !== "CANCELLED" &&
      status !== "APPROVEDANDCANCELLED"
    ) {
      try {
        const response = await axiosService.post(
          `${LEAVE_URL.GET_EMP_LEAVES}${leaveId}`
        );
        if (response.status === 200) {
          toast.success("Leave cancelled successfully!", {
            autoClose: 3000,
            closeButton: false,
            style: { backgroundColor: "green", color: "white" },
          });
          fetchData();
          LeaveMaster();
        }
      } catch (error) {
        console.error("Error:", error);
        if (error?.response?.data?.message.startsWith("E")) {
          toast.error(error?.response?.data?.message.split(":")[1]);
        } else {
          toast.error(error?.response?.data?.message);
        }
      }
    }
  };

  function convertMonthNumberToString(monthString) {
    let splittedValue = monthString.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthName = monthNames.find(
      (month, idx) => idx + 1 === parseInt(splittedValue[1])
    );
    splittedValue[1] = monthName;

    splittedValue.reverse();
    let joined = splittedValue.join(" ");

    return joined;
  }

  const toggleStartDayInput = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setStartDateWithDifference(startDateWithDifference - 0.5);
    } else {
      formik.setFieldValue("halfType", "");
    }

    setShowStartDayInput(isChecked);
  };

  const containerRef = useRef();

  const onPageChange = (page) => {
    setCurrentPage(page);
    containerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const onNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getStatusButton = (leaveStatus) => {
    if (leaveStatus === "APPROVED") {
      return <div className="leave-list-active">Approved</div>;
    } else if (leaveStatus === "REJECTED") {
      return <div className="leave-list-inactive">Rejected</div>;
    } else if (leaveStatus === "PENDING") {
      return <div className="leave-list-pending">Pending</div>;
    } else if (leaveStatus === "CANCELLED") {
      return <div className="leave-list-cancel">Cancelled</div>;
    } else if (leaveStatus === "APPROVEDANDCANCELLED") {
      return <div className="leave-list-cancel">Approved and Cancelled</div>;
    }
    return null;
  };

  return (
    <div className="w-100 leave-list-container" ref={containerRef}>
      <div className="leave-list-banner">
        <div className="leave-list-table-holder ">
          <div className="leave-container-fluid ">
            <div className="row">
              <div className="col w-100 column1">
                <div className="reportees-attendance-list-filter-input-container">
                  <Dropdown
                    id="Employee"
                    // name="leaveStatus"
                    // labelKey="leaveStatus"
                    placeholder="Status"
                    value={statusFilter?.value}
                    onChange={handleStatusFilterChange}
                    options={names}
                    optionLabel="label"
                    showClear
                    className="reportees-custom-dropdown w-full md:w-14rem"
                    style={{ zIndex: "0" }}
                  />
                </div>
              </div>
              <div
                className="col w-100"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  gap: "50px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    className="personal-leave-p"
                    style={{ margin: "0", display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    Personal Leave Balance{" "}
                    <span style={{ color: "rgba(63, 194, 138, 1)", fontSize: "20px" }}>
                      {leaveBalances.PERSONAL_LEAVE}
                    </span>
                  </div>
                  <div className="seperator-of-leaves"></div>

                  <div
                    className="sick-leave-p"
                    style={{ margin: "0", display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    Sick Leave Balance{" "}
                    <span style={{ color: "rgba(63, 194, 138, 1)", fontSize: "20px" }}>
                      {leaveBalances.SICK_LEAVE}
                    </span>

                  </div>
                </div>
                <div className="leave-list-add-leave-button">
                  <button
                    className="leave-add-button"
                    onClick={handleClickOpen}
                  >
                    <span style={{ marginRight: "10px" }}>
                      <Vector />
                    </span>
                    Apply Leave
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="leave-list-table-container ">
            <table className="leave-list-table">
              <thead>
                <tr>
                  {/* Columns for LG Devices */}
                  <th className="leave-list-table-lg-columns" scope="col">
                    S.No
                  </th>
                  <th className="leave-list-table-lg-columns" scope="col">
                    Applied On
                  </th>
                  <th className="leave-list-table-lg-columns" scope="col">
                    Date
                  </th>
                  <th className="leave-list-table-lg-columns" scope="col">
                    Duration
                  </th>
                  <th className="leave-list-table-lg-columns" scope="col">
                    Leave Type
                  </th>
                  <th className="leave-list-table-lg-columns" scope="col">
                    Status
                  </th>
                  <th className="leave-list-table-lg-columns" scope="col">
                    Action
                  </th>
                  {/* Columns for MD Devices */}
                  <th className="leave-list-table-md-columns" scope="col">
                    Leave Type
                  </th>
                  <th className="leave-list-table-md-columns" scope="col">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendanceData.map((row, index) => (
                  <tr key={index}>
                    {/* Columns for LG Devices */}

                    <td
                      className="leave-list-table-lg-columns"
                      style={{ width: "7%" }}
                    >
                      <div className="d-inline-flex gap-1 leave-list-table-profile mt-1">
                        <p className="mt-2">
                          {(currentPage - 1) * recordsPerPage + index + 1}
                        </p>
                      </div>
                    </td>
                    <td
                      className="leave-list-table-lg-columns"
                      style={{ width: "13%" }}
                    >
                      {convertMonthNumberToString(row.appliedDate)}
                    </td>
                    <td
                      className="leave-list-table-lg-columns"
                      style={{ width: "20%" }}
                    >
                      {convertMonthNumberToString(row.startDate)} -{" "}
                      {convertMonthNumberToString(row.endDate)}
                    </td>
                    <td
                      className="leave-list-table-lg-columns"
                      style={{ width: "12%" }}
                    >
                      {row.duration} days
                    </td>
                    <td
                      className="leave-list-table-lg-columns"
                      style={{ width: "15%" }}
                    >
                      {row.leaveType.replaceAll("_", " ")}
                    </td>
                    <td
                      className="leave-list-table-lg-columns"
                      style={{ width: "15%" }}
                    >
                      {getStatusButton(row.leaveStatus)}
                    </td>
                    <td
                      className="leave-list-table-lg-columns"
                      style={{ width: "12%" }}
                    >
                      <div className="leave-action">
                        <span
                          id={row.leaveId}
                          onClick={() => {
                            editObject(row.leaveId);
                            setViewDialog(true);
                          }}
                        >
                          <Eye />
                        </span>{" "}
                        {row.leaveStatus === "PENDING" ? (
                          <span
                            id={row.leaveId}
                            onClick={() => {
                              editObject(row.leaveId);
                              LeaveMaster();
                            }}
                          >
                            <Edit />
                          </span>
                        ) : (
                          <span id={row.leaveId} style={{ opacity: 0.5 }}>
                            <Edit />
                          </span>
                        )}
                        {row.leaveStatus !== "REJECTED" &&
                          row.leaveStatus !== "CANCELLED" &&
                          row.leaveStatus !== "APPROVEDANDCANCELLED" ? (
                          <span
                            style={{
                              height: "25px",
                              marginLeft: "8px",
                            }}
                            id={row.leaveId}
                            onClick={() => {
                              handleCancelLeave(row.leaveId, row.leaveStatus);
                            }}
                          >
                            <Delete style={{ height: "25px" }} />
                          </span>
                        ) : (
                          <span
                            style={{
                              height: "25px",
                              marginLeft: "8px",
                              opacity: 0.5,
                            }}
                            id={row.leaveId}
                          >
                            <Delete style={{ height: "25px" }} />
                          </span>
                        )}
                      </div>
                    </td>

                    {/* Columns for MD Devices */}

                    <td className="leave-list-table-md-columns">
                      {row.leaveType}
                    </td>
                    <td className="leave-list-table-md-columns">
                      {row.leaveStatus}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col w-50 d-flex align-items-center reportees-timesheet-record-list">
              <span>Showing</span>
              <div style={{ width: "fit-content", position: "relative" }}>
                <select
                  value={recordsPerPage}
                  onChange={handleRecordsPerPageChange}
                >
                  {cardsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <div className="reportees-timesheet-record-list-dropdown-icon">
                  <Dropdowns />
                </div>
              </div>
            </div>
            <div className="col w-50  pagination-container">
              {totalPages > 1 ? (
                <>
                  <Pagination
                    className="pagination d-inline-flex"
                    currentPage={currentPage}
                    // totalPages={Math.ceil(attendanceData.length / recordsPerPage)}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                    nextLabel={<RightArrow onClick={onNextPage} />}
                    previousLabel={<LeftArrow onClick={onPreviousPage} />}
                    activePageLinkStyle={{ color: "#ffffff !important" }}
                    icon={<span>{currentPage}</span>}
                  />
                </>
              ) : (
                <nav
                  class="pagination d-inline-flex"
                  activepagelinkstyle="[object Object]"
                  icon="[object Object]"
                >
                  <ul class="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                    <li>
                      <button
                        type="button"
                        class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.4685 17.5856C14.7919 17.3269 14.8444 16.8549 14.5856 16.5315L10.9604 12L14.5856 7.46849C14.8444 7.14505 14.7919 6.67308 14.4685 6.41432C14.145 6.15556 13.673 6.208 13.4143 6.53145L9.41429 11.5315C9.19516 11.8054 9.19516 12.1946 9.41429 12.4685L13.4143 17.4685C13.673 17.7919 14.145 17.8444 14.4685 17.5856Z"
                            fill="black"
                          ></path>
                        </svg>
                      </button>
                    </li>
                    <li aria-current="page">
                      <button
                        type="button"
                        class="w-12 border border-gray-300 py-2 leading-tight enabled:hover:bg-gray-100 enabled:hover:text-gray-700 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                      >
                        1
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
                            fill="black"
                          ></path>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "40%", borderRadius: "20px" } }}
        maxWidth="100px"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: "custom-dialog-paper" }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogActions
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              borderBottom: "1px solid lightgrey",
              padding: "10px 25px",
              // paddingRight: "10px",
              // marginLeft: "20px",
              // marginRight: "20px",
              // marginTop: "-16px",
            }}
          >
            {/* <span> */}
            <p className="leave-title">
              {viewDialog
                ? "View Leave"
                : leaveId
                  ? "Edit Leave"
                  : "Apply Leave"}
            </p>
            {/* </span> */}
          </DialogActions>
          <DialogTitle id="alert-dialog-title">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <div
                className="leave-select-date-range"
                style={{
                  position: "relative",
                  width: "48%",
                  paddingTop: "12px",
                }}
              >
                <span
                  className={`p-float-label ${dates ? "p-input-filled" : ""}`}
                  style={{ width: "100%" }}
                >
                  <Calendar
                    id="startDate"
                    name="startDate"
                    value={dates}
                    dateFormat="yy-mm-dd"
                    onChange={(e) => {
                      handleDate(e);
                    }}
                    selectionMode="range"
                    showIcon
                    readOnlyInput
                    disabled={viewDialog}
                    hideOnRangeSelection
                    style={{
                      width: "100%",
                    }}
                  />

                  <label
                    htmlFor="startDate"
                    style={{
                      position: "absolute",
                      top: inputFocused ? "0" : "0",
                      left: "0",
                      transform: inputFocused
                        ? "translate(7px, -23px)"
                        : "translate(10px, 17px)",
                      transition: "0.2s all ease",
                      pointerEvents: "none",
                      color: viewDialog ? " rgba(0, 0, 0, 1)" : "#000",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      padding: "0 5px",
                      fontSize: inputFocused ? "16px" : "16px",
                      fontWeight: "300",
                    }}
                  >
                    Date Range
                  </label>
                </span>
              </div>

              <div style={{ width: "48%" }}>
                <span className="p-float-label" style={{ paddingTop: "12px" }}>
                  <InputText
                    className="leave-duration"
                    onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    name="Duration"
                    style={{
                      width: "100%",
                      height: "48px",
                      borderRadius: "10px",
                      border:
                        editObject?.duration || startDateWithDifference
                          ? "1px solid rgba(107 105 105)"
                          : "1px solid rgba(162, 161, 168, 0.2)",
                      backgroundColor:
                        editObject?.duration || startDateWithDifference
                          ? "white"
                          : "rgba(0, 0, 0, 0.05)",
                      color: "rgba(0, 0, 0, 1)",
                    }}
                    // value={
                    //   editObject?.duration || startDateWithDifference || null
                    // }
                    value={getDuration()}
                    readOnly
                    disabled={viewDialog}
                  ></InputText>
                  <label className="leave-floating-label">Duration</label>
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <div style={{ width: "48%" }}>
                <span className="p-float-label ">
                  <Dropdown
                    className="leave-absence-type"
                    name="leaveType"
                    onBlur={formik.handleBlur}
                    value={formik.values.leaveType}
                    optionLabel="name"
                    options={Types}
                    onChange={handleChangeLeaveType}
                    style={{
                      width: "100%",
                      height: "48px",
                      borderRadius: "10px",
                      // border: "1px solid rgba(162, 161, 168, 0.2)",
                      border: formik.values.leaveType
                        ? "1px solid rgba(107 105 105)"
                        : "1px solid rgba(162, 161, 168, 0.2)",
                      backgroundColor: formik.values.leaveType
                        ? "white"
                        : "rgba(0, 0, 0, 0.05)",
                      color: "rgba(0, 0, 0, 1)",
                      marginTop: "14px",
                      alignItems: "center",
                    }}
                    disabled={viewDialog}
                  >
                    <option
                      selected
                      disabled
                      hidden
                      value=""
                      className="leave-absence-type"
                    ></option>
                  </Dropdown>

                  {/* {formik.touched.leaveType && formik.errors.leaveType ? (
                    <div className="writing-parameter-formik-error">
                      {formik.errors.leaveType}
                    </div>
                  ) : null} */}
                  <label className="leave-floating-label">Absence Type</label>
                </span>
              </div>
              {!viewDialog && (
                <div style={{ width: "48%" }}>
                  <span className="p-float-label">
                    <InputText
                      name="balanceType"
                      className="leave-balance-type"
                      onChange={(e) => setBalanceType(e.target.value)}
                      value={balanceType}
                      style={{
                        width: "100%",
                        height: "48px",
                        borderRadius: "10px",
                        border: balanceType
                          ? "1px solid rgba(107 105 105)"
                          : "1px solid rgba(162, 161, 168, 0.2)",
                        backgroundColor: balanceType
                          ? "white"
                          : "rgba(0, 0, 0, 0.05)",
                        color: "rgba(0, 0, 0, 1)",
                        marginTop: "14px",
                      }}
                      readOnly
                      disabled={
                        formik.values.leaveType === "LOSS_OF_PAY" || viewDialog
                      }
                    ></InputText>
                    {formik.touched.balanceType && formik.errors.balanceType ? (
                      <div className="writing-parameter-formik-error">
                        {formik.errors.balanceType}
                      </div>
                    ) : null}
                    <label className="leave-floating-label">
                      Balance Leave
                    </label>
                  </span>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <div style={{ width: "48%", marginTop: "20px" }}>
                <input
                  name="isHalfDay"
                  type="checkbox"
                  onChange={toggleStartDayInput}
                  // onBlur={formik.handleBlur}
                  value={formik.values.isHalfDay}
                  checked={showStartDayInput}
                  disabled={viewDialog}
                />

                <label className="half-day-label">Select Half Day</label>
              </div>
              <div style={{ width: "48%" }}>
                {showStartDayInput && (
                  <span className="p-float-label relative">
                    <Dropdown
                      className="leave-start-day"
                      name="halfType"
                      disabled={viewDialog}
                      onChange={(event) => {
                        formik.handleChange(event);
                      }}
                      // onBlur={formik.handleBlur}
                      value={formik.values.halfType}
                      options={days}
                      optionLabel="name"
                      style={{
                        width: "100%",
                        height: "48px",
                        borderRadius: "10px",
                        border: formik.values.halfType
                          ? "1px solid rgba(107 105 105)"
                          : "1px solid rgba(162, 161, 168, 0.2)",
                        backgroundColor: formik.values.halfType
                          ? "white"
                          : "rgba(0, 0, 0, 0.05)",
                        color: "rgba(0, 0, 0, 1)",
                        // marginTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <option
                        selected
                        disabled
                        hidden
                        value=""
                        className="leave-start-day"
                      ></option>
                    </Dropdown>
                    {/* {formik.touched.halfType && formik.errors.halfType ? (
                      <div className="writing-parameter-formik-error">
                        {formik.errors.halfType}
                      </div>
                    ) : null} */}
                    <label className="leave-floating-label"
                      style={{ top: "0", bottom: "0", display: "flex", padding: "0" }}>Days</label>
                  </span>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <span className="p-float-label">
                  <InputText
                    disabled={viewDialog}
                    className="leave-comments"
                    name="comments"
                    onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    value={formik.values.comments}
                    style={{
                      width: "100%",

                      borderRadius: "10px",
                      border: formik.values.comments
                        ? "1px solid rgba(107 105 105)"
                        : "1px solid rgba(162, 161, 168, 0.2)",
                      backgroundColor: formik.values.comments
                        ? "white"
                        : "rgba(0, 0, 0, 0.05)",
                      color: "rgba(0, 0, 0, 1)",
                      marginTop: "10px",
                      alignItems: "center",
                    }}
                  />
                  {/* {formik.touched.comments && formik.errors.comments ? (
                    <div className="writing-parameter-formik-error">
                      {formik.errors.comments}
                    </div>
                  ) : null} */}
                  <label className="leave-floating-label">Comments</label>
                </span>
              </div>
              {viewDialog && appComments && (
                <div style={{ width: "100%", marginTop: "10px" }}>
                  <label className="leave-floating-label">
                    Approve/Reject Comments
                  </label>
                  <input
                    disabled={viewDialog}
                    className="leave-comments"
                    name="comments"
                    value={appComments}
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid rgba(107, 105, 105)",
                      backgroundColor: "white",
                      color: "rgba(0, 0, 0, 1)",
                      marginTop: "30px",
                      alignItems: "center",
                    }}
                  />
                </div>
              )}
            </div>
          </DialogTitle>

          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="add-hol"
            >
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                {viewDialog ? (
                  <div className="">
                    <button
                      type="button"
                      className="leave-cancel-bttn"
                      onClick={handleClose}
                    >
                      BACK
                    </button>
                  </div>
                ) : (
                  <div className="">
                    <button
                      type="button"
                      className="leave-cancel-bttn"
                      onClick={handleClose}
                    >
                      CANCEL
                    </button>
                  </div>
                )}
                {leaveId ? (
                  <div className="">
                    <button
                      type="submit"
                      className="leave-save-bttn"
                      onClick={handleEditSubmit}
                      hidden={viewDialog}
                    >
                      UPDATE
                    </button>
                  </div>
                ) : (
                  <div className="">
                    <button
                      type="submit"
                      className="leave-save-bttn"
                      onClick={handleAddSubmit}
                    >
                      SAVE
                    </button>
                  </div>
                )}
              </div>
            </DialogContentText>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}

export default LeaveLists;
