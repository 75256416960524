import React from "react";

function ReporteeIcon({ color }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 8C9.20914 8 11 6.20914 11 4C11 1.79086 9.20914 0 7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8ZM7 18C10.866 18 14 16.2091 14 14C14 11.7909 10.866 10 7 10C3.13401 10 0 11.7909 0 14C0 16.2091 3.13401 18 7 18ZM10.9614 7.81546C11.9141 6.82652 12.5 5.48172 12.5 4.00008C12.5 3.29549 12.3675 2.62184 12.1261 2.00269C13.7244 2.0688 14.9999 3.38548 14.9999 5.00008C14.9999 6.65694 13.6568 8.00008 11.9999 8.00008C11.6349 8.00008 11.285 7.93487 10.9614 7.81546ZM15.4999 13.9999C15.4999 14.5387 15.3802 15.0595 15.157 15.5515C16.8634 15.0226 17.9999 14.0776 17.9999 12.9999C17.9999 11.4937 15.7799 10.2468 12.8867 10.0325C14.4974 11.0333 15.4999 12.4409 15.4999 13.9999Z"
        fill={color}
      />
    </svg>
  );
}

export default ReporteeIcon;
