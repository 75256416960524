import { Field, Form, Formik } from "formik";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { axiosService } from "../../../../services/axios.service";
import { ALL_EMPLOYEES_URL } from "../../constants/all-employees-urls";
import "../all-employee-professional-info/ProfessionalInfo.css";
import CancelModal from "../cancel-modal/CancelModal";
import { toast } from "react-toastify";

function ProfessionalInfo({ onSubmit, type, values, setActiveTab }) {
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const isFilled = false;
  const containerRef = useRef();

  const [selectedSupervisor, setSelectedSupervisor] = useState(
    values?.supervisor ? values?.supervisor : ""
  );
  const [isSupervisorFocused, setIsSupervisorFocused] = useState(false);

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "42px",
      boxShadow: state.isFocused ? "none" : "none",
      backgroundColor: state.isFocused ? "#ffff" : "rgba(0 0 0 0.05)",
      borderRadius: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: '"Lexend", sans-serif',
      fontSize: "16px",
      marginTop: "0",
      backgroundColor: state.isSelected ? "#3FC28A" : "#ffffff",
      "&:hover": {
        backgroundColor: "#3FC28A",
        color: "#ffffff",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      fontFamily: '"Lexend", sans-serif',
      fontSize: "16px",
      fontWeight: 400,
    }),
  };

  const handleSupervisorFocus = () => {
    setIsSupervisorFocused(true);
  };

  const handleSupervisorBlur = () => {
    setIsSupervisorFocused(false);
  };

  const [fieldsAreReadOnly, setFieldsAreReadOnly] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "View") {
      setFieldsAreReadOnly(true);
    }
  }, [type]);

  const [errors, setErrors] = useState({});

  const validateFields = (values) => {
    const errors = {};

    if (!values.employeeId) {
      errors.employeeId = "Enter Employee ID";
    }

    if (!values.userName) {
      errors.userName = "Enter User Name";
    }

    if (!values.designation) {
      errors.designation = "Enter designation";
    }
    if (!values.officeEmail) {
      errors.officeEmail = "Enter Email";
    } else if (
      // !/^[a-zA-Z0-9._%+-]+@(midfinstudios\.com|digibyte\.com)$/i.test(
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(
        values.officeEmail
      )
    ) {
      errors.officeEmail = "Invalid official email";
    }

    if (!values.joiningDate) {
      errors.joiningDate = "Select Joining Date";
    }
   
    if (!values.supervisor) {
      errors.supervisor = "Select SuperVisor";
    }

    return errors;
  };

  useEffect(() => {
    axiosService
      .get(`${ALL_EMPLOYEES_URL.GET_ALL_EMPLOYEES_NAME}`)
      .then((res) => {
        const employeeData = res.data.data;

        const supervisorOptions = employeeData.map((employee) => ({
          label: employee?.employeeFirstName,
          value: { empId: parseInt(employee.empId) },
        }));

        setSupervisorOptions(supervisorOptions);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    return isoDateString;
  }
  const OnEditSubmit = async (values) => {
    let mergedValues;
    try {
      mergedValues = {
        ...values,
        supervisor: { empId: values?.supervisor?.empId },
        employeeDob: convertDateFormat(values?.employeeDob),
        joiningDate: convertDateFormat(values?.joiningDate),
        lastWorkingDate: convertDateFormat(values?.lastWorkingDate),
      };
      const response = await axiosService.put(
        `${ALL_EMPLOYEES_URL.UPDATE_EMPLOYEE_DATA}`,
        mergedValues
      );

      if (response.status === 200) {
        navigate("/all-employees/employees-list");
        toast.success("Updated successfully");
      }
    } catch (error) {
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(error?.response?.data?.message?.split(":")[1]);
      }
    }
  };
  const handleSubmit = (values) => {

    const validationErrors = validateFields(values);

    if (type === "View") {
      onSubmit(values, true);
    } else if (type === "Edit") {
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        OnEditSubmit(values, true);
      }
    } else {
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        onSubmit(values, true);
      }
    }
  };

  const handleNumericInputChange = (fieldName, e, setFieldValue) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setFieldValue(fieldName, numericValue);
  };

  return (
    <div
      className=" employee-list-container "
      ref={containerRef}
      style={{ zIndex: "1" }}
    >
      <div className="employee-list-banner ">
        <div
          className="employee-list-table-holder pt-3 mt-0"
          style={{
            marginTop: "0px",
            borderTop: "0",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <div className="Empoyee-profile-container w-100 ">
            <div className="addEmployee-details w-100 ">
              <Formik
                onSubmit={handleSubmit}
                initialValues={values}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className="row add-professional-container ">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="employeeId"
                            type="text"
                            readOnly={fieldsAreReadOnly}
                            style={{
                              backgroundColor: values.employeeId
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.employeeId
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Employee ID
                          </label>
                          {errors.employeeId && !values.employeeId && (
                            <span className="error-message-text">
                              {errors.employeeId}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 ">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="userName"
                            type="text"
                            style={{
                              backgroundColor: values.userName
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.userName
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                            readOnly={fieldsAreReadOnly}
                          />
                          <label className="add-Employee-label">
                            User Name
                          </label>
                          {errors.userName && !values.userName && (
                            <span className="error-message-text">
                              {errors.userName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text "
                            name="designation"
                            type="text"
                            readOnly={fieldsAreReadOnly}
                            style={{
                              backgroundColor: values.designation
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.designation
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Designation
                          </label>
                          {errors.designation && !values.designation && (
                            <span className="error-message-text">
                              {errors.designation}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            type="text"
                            name="officeEmail"
                            readOnly={fieldsAreReadOnly}
                            style={{
                              backgroundColor: values.officeEmail
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.officeEmail
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Office Email Address
                          </label>
                          {errors.officeEmail &&
                            !/^[a-zA-Z0-9._%+-]+@(midfinstudios\.com|digibyte\.com)$/i.test(
                              values.officeEmail
                            ) && (
                              <span className="error-message-text">
                                {errors.officeEmail}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 add-box">
                        <div className="floating-label-datepicker">
                          <span
                            className="p-float-label-personal"
                            style={{
                              position: "relative",
                            }}
                          >
                            <Calendar
                              inputId="joiningDate"
                              name="joiningDate"
                              value={values.joiningDate}
                              onChange={(e) =>
                                setFieldValue("joiningDate", e.value)
                              }
                              appendTo={document.body}
                              className={
                                "custom-calendar" +
                                +(isFilled ? " filled" : "") +
                                (values.joiningDate ? " filled-bg" : "")
                              }
                              showIcon
                              readOnlyInput
                              disabled={fieldsAreReadOnly}
                              style={{
                                width: "100%",
                                color: "#000",
                                zIndex: "0",
                              }}
                            />

                            <label
                              className="date-picker-label"
                              htmlFor="joiningDate"
                            >
                              Select Joining Date
                            </label>
                            {errors.joiningDate && !values.joiningDate && (
                              <span
                                className="error-message-text"
                                style={{ marginTop: "-15px" }}
                              >
                                {errors.joiningDate}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="salary"
                            type="text"
                            readOnly={fieldsAreReadOnly}
                            maxLength={8}
                            onChange={(e) =>
                              handleNumericInputChange(
                                "salary",
                                e,
                                setFieldValue
                              )
                            }
                            style={{
                              backgroundColor: values.salary
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.salary
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Current Salary
                          </label>
                          
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <div
                            className={`select-container mt-0 ${isSupervisorFocused ||
                              selectedSupervisor ||
                              values.supervisor
                              ? "active"
                              : ""
                              }`}
                            style={{
                              backgroundColor:
                                values.supervisor ||
                                  isSupervisorFocused ||
                                  selectedSupervisor
                                  ? "white"
                                  : "rgba(0,0,0,0.05)",
                              border:
                                values.supervisor ||
                                  isSupervisorFocused ||
                                  selectedSupervisor
                                  ? "1px solid rgba(0, 0, 0, 0.5) "
                                  : "none",
                            }}
                          >
                            <label
                              htmlFor="supervisor"
                              className="floating-label"
                            >
                              Supervisor
                            </label>
                            <Field name="supervisor">
                              {({ field }) => (
                                <Select
                                  name="supervisor"
                                  {...field}
                                  options={supervisorOptions}
                                  styles={selectStyles}
                                  classNamePrefix="atnd_supervisor"
                                  value={supervisorOptions.find(
                                    (option) =>
                                      option.value &&
                                      option.value.empId === field.value?.empId
                                  )}
                                  onChange={(selectedOption) => {
                                    if (
                                      selectedOption &&
                                      selectedOption.value
                                    ) {
                                      setFieldValue(
                                        "supervisor",
                                        selectedOption.value
                                      );
                                      setSelectedSupervisor(selectedOption);
                                    }
                                  }}
                                  onFocus={handleSupervisorFocus}
                                  onBlur={handleSupervisorBlur}
                                  isDisabled={fieldsAreReadOnly}
                                  placeholder=""
                                />
                              )}
                            </Field>
                          </div>
                          {errors.supervisor && !values.supervisor && (
                            <span className="error-message-text">
                              {errors.supervisor}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6  col-md-6 col-sm-12 add-box">
                        <div className="floating-label-datepicker">
                          <span
                            className="p-float-label-personal"
                            style={{
                              position: "relative",
                            }}
                          >
                            <Calendar
                              inputId="lastWorkingDate"
                              name="lastWorkingDate"
                              value={values.lastWorkingDate}
                              onChange={(e) =>
                                setFieldValue("lastWorkingDate", e.value)
                              }
                              appendTo={document.body}
                              className={
                                "custom-calendar" +
                                +(isFilled ? " filled" : "") +
                                (values.lastWorkingDate ? " filled-bg" : "")
                              }
                              showIcon
                              readOnlyInput
                              disabled={fieldsAreReadOnly}
                              style={{
                                width: "100%",
                                color: "#000",
                                zIndex: "0",
                              }}
                            />

                            <label
                              className="date-picker-label"
                              htmlFor="lastWorkingDate"
                            >
                              Last Working Day
                            </label>
                            {errors.lastWorkingDate &&
                              !values.lastWorkingDate && (
                                <span
                                  className="error-message-text"
                                  style={{ marginTop: "-15px" }}
                                >
                                  {errors.lastWorkingDate}
                                </span>
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="add-Employeepage-buttons mt-3 ms-auto"
                      style={{ textAlign: "right" }}
                    >
                      {type !== "View" && (
                        <button
                          className="Cancel-btn"
                          type="button"
                          onClick={() => setModalOpen(true)}
                        >
                          Cancel
                        </button>
                      )}
                      {type === "View" && (
                        <button
                          className="Cancel-btn"
                          type="button"
                          onClick={() =>
                            navigate("/all-employees/employees-list")
                          }
                        >
                          Cancel
                        </button>
                      )}
                      {modalOpen && (
                        <CancelModal
                          modalOpen={modalOpen}
                          closeModal={() => setModalOpen(false)}
                          onSubmit={() =>
                            navigate("/all-employees/employees-list")
                          }
                          cancelButton={true}
                          submitButton={true}
                          onCloseTitle={"Cancel"}
                        />
                      )}
                      {type === "Add" || (type !== "View" && type !== "Edit") ? (
                        <button
                          className="add-button-prev"
                          type="button"
                          onClick={() => setActiveTab("BankInfo")}
                        >
                          Previous
                        </button>
                      ) : null}
                      {type === "Add" && (
                        <button className="add-button-next" type="submit">
                          Next
                        </button>
                      )}
                      {type === "Edit" && (
                        <button className="add-button-next" type="submit" >
                          Update
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfessionalInfo;
