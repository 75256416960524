import { Route, Routes } from "react-router-dom";
import SideBar from "../sidebar/SideBar";
import Login from "../login/Login";
import ForgotPassword from "../forgot-password/ForgotPasword";

function AuthRoutes() {
  return (
    <Routes>
       <Route path="*" element={<SideBar />} />
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

    </Routes>
  );
}

export default AuthRoutes;
