import React, { useEffect, useRef, useState } from "react";
import "../../../all-employees/components/all-employee-navbar/AllEmployeeNavbar.css";
import LookUpHolidayTab from "../look-up-all-tabs/LookUpHolidayTab";
import LookUpLeaveTab from "../look-up-all-tabs/LookUpLeaveTab";

import LookUpAssetTab from "../look-up-all-tabs/LookUpAssetTab";

import LookUpProjectTab from "../look-up-all-tabs/LookUpProjectTab";
import LookUpTaskTab from "../look-up-all-tabs/LookUpTaskTab";

const LookUpNavbar = () => {
  const [activeTab, setActiveTab] = useState("Project");
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);
  const containerRef = useRef();

  useEffect(() => {
    const navbar = document.getElementById("navbarSupportedContent");
    navbar.addEventListener("hidden.bs.collapse", () => {
      setNavbarCollapsed(true);
    });
    navbar.addEventListener("shown.bs.collapse", () => {
      setNavbarCollapsed(false);
    });
  }, []);

  return (
    <div className="mb-0 pb-0 look-up-navbar">
      <div
        className="employee-list-container sticky-header  "
        ref={containerRef}
      >
        <div className="">
          <div className=" look-up-list-table-holder">
            <div className="AllEmployee-navibar-container w-100">
              <nav
                className="navbar navbar-expand-md navbar-light m-2 p-0"
                style={{ background: "white", height: "fit-content" }}
              >
                <div
                  className="container-fluid g-0  pt-3"
                  style={{ height: "fit-content", width: "96%" }}
                >
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setNavbarCollapsed(!isNavbarCollapsed)}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className={`collapse navbar-collapse ${
                      isNavbarCollapsed ? "" : " show"
                    }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="add-employee-nav gap-md-3 border-bottom w-100 ps-0 d-md-flex flex-wrap">
                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "Project" && "active"
                        }`}
                        onClick={() => setActiveTab("Project")}
                      >
                        Project
                      </span>
                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "Task" && "active"
                        }`}
                        onClick={() => setActiveTab("Task")}
                      >
                        Task
                      </span>
                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "Holiday" && "active"
                        }`}
                        onClick={() => setActiveTab("Holiday")}
                      >
                        Holiday
                      </span>

                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "Asset" && "active"
                        }`}
                        onClick={() => setActiveTab("Asset")}
                      >
                        Asset
                      </span>

                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "Leave" && "active"
                        }`}
                        onClick={() => setActiveTab("Leave")}
                      >
                        Leave
                      </span>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="faculty-all-student-navbar-inner-container look-up-tab-details">
        {activeTab === "Project" && <LookUpProjectTab />}
        {activeTab === "Task" && <LookUpTaskTab />}
        {activeTab === "Holiday" && (
          <LookUpHolidayTab
            className="w-100"
            style={{ width: "100% !important" }}
          />
        )}
        {activeTab === "Asset" && <LookUpAssetTab />}
        {activeTab === "Leave" && <LookUpLeaveTab />}
      </div>
    </div>
  );
};

export default LookUpNavbar;
