import React from 'react'

const ClockIn = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.8 10.6836L13.1 8.04688M13.1 8.04688L9.8 5.41016M13.1 8.04688H1M6.5 3.87083V3.8283C6.5 2.84383 6.5 2.35123 6.73979 1.97521C6.95071 1.64446 7.28702 1.37574 7.70098 1.20722C8.17158 1.01562 8.7881 1.01562 10.0202 1.01562H15.0802C16.3123 1.01562 16.9275 1.01562 17.3981 1.20722C17.8121 1.37574 18.1495 1.64446 18.3604 1.97521C18.6 2.35086 18.6 2.84287 18.6 3.82541V12.2688C18.6 13.2513 18.6 13.7426 18.3604 14.1183C18.1495 14.449 17.8121 14.7182 17.3981 14.8867C16.928 15.0781 16.3131 15.0781 15.0834 15.0781H10.0166C8.78689 15.0781 8.17112 15.0781 7.70098 14.8867C7.28702 14.7182 6.95071 14.4488 6.73979 14.118C6.5 13.742 6.5 13.2501 6.5 12.2656V12.2217" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default ClockIn