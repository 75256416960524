import React, { useEffect, useState } from "react";
import { axiosService } from "../../../../services/axios.service";
import sessionService from "../../../../services/sessionstorage.service";
import { PROFILE_URL } from "../../constants/profile-urls";
import MyBankInfo from "../profile-bankinfo/MyBankInfo";
import Mydocument from "../profile-document/Mydocument";
import MyPersonalInfo from "../profile-personal/MyPersonalInfo";
import MyProfessionalInfo from "../profile-professional/MyProfessionalInfo";
import "./ProfileNavbar.css";
import MyAsset from "../profile-asset/MyAsset";
import ProfileOtherInfo from "../profile-otherinfo/ProfileOtherInfo";
const ProfileNavbar = () => {
  const [activeTab, setActiveTab] = useState("Personalinfo");
  const [profileData, setProfileData] = useState([]);
  const User = sessionService.getUserData("userData");
  const empId = User?.employee?.empId;

  const fetchData = async () => {
    try {
      const url = `${PROFILE_URL.GET_EMPLOYEES_PROFILE}${empId}`;
      const response = await axiosService.get(url);
      const data = response?.data?.data;

      if (data) {
        setProfileData(data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="profile-main">
      <div
        className="container-fluid"
        style={{ width: "96%", marginTop: "10px" }}
      >
        <div>
          <ul className=" gap-md-3 border-bottom w-100 ps-0 d-md-flex flex-wrap">
            <span
              className={`add-NewEmployee-navbar-text ${
                activeTab === "Personalinfo" && "active"
              }`}
              onClick={() => setActiveTab("Personalinfo")}
            >
              Personal Info
            </span>
            <span
              className={`add-NewEmployee-navbar-text ${
                activeTab === "National ID & Bank Info" && "active"
              }`}
              onClick={() => setActiveTab("National ID & Bank Info")}
            >
              National ID & Bank Info
            </span>
            <span
              className={`add-NewEmployee-navbar-text ${
                activeTab === "Professional Information" && "active"
              }`}
              onClick={() => setActiveTab("Professional Information")}
            >
              Professional Information
            </span>
            <span
              className={`add-NewEmployee-navbar-text ${
                activeTab === "Asset" && "active"
              }`}
              onClick={() => setActiveTab("Asset")}
            >
              Asset
            </span>
            <span
              className={`add-NewEmployee-navbar-text ${
                activeTab === "Document" && "active"
              }`}
              onClick={() => setActiveTab("Document")}
            >
              Documents
            </span>
            <span
              className={`add-NewEmployee-navbar-text ${
                activeTab === "OtherInfo" && "active"
              }`}
              onClick={() => setActiveTab("OtherInfo")}
            >
              OtherInfo
            </span>
          </ul>
        </div>
      </div>
      <div className="profile-content">
        {activeTab === "Personalinfo" && (
          <MyPersonalInfo values={profileData} />
        )}
        {activeTab === "National ID & Bank Info" && (
          <MyBankInfo values={profileData} />
        )}
        {activeTab === "Professional Information" && (
          <MyProfessionalInfo values={profileData} />
        )}
        {activeTab === "Asset" && <MyAsset values={profileData} />}
        {activeTab === "Document" && <Mydocument values={profileData}/>}
        {activeTab === "OtherInfo" && <ProfileOtherInfo values={profileData}/>}
      </div>
    </div>
  );
};

export default ProfileNavbar;
