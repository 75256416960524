import { Pagination } from "flowbite-react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Dropdowns from "../../../../assets/images-svg/Drop_down";
import Edit from "../../../../assets/images-svg/Edit";
import LeftArrow from "../../../../assets/images-svg/LeftArrow";
import Plus from "../../../../assets/images-svg/Plus";
import RightArrow from "../../../../assets/images-svg/RightArrow";
import Trash from "../../../../assets/images-svg/Trash";
import View from "../../../../assets/images-svg/View";
import { axiosService } from "../../../../services/axios.service";
import Deletemodal from "../../../holidays/components/holidays-list/Deletemodal";
import { ASSET_URL } from "../../constants/asset-urls";
import "./AssetList.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    width: "550px",
    height: "590px",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    padding: "0",
  },
};

const AssetList = () => {
  const [assetData, setAssetData] = useState([]);
  const [selectedAssetName, setSelectedAssetName] = useState(null);
  const [selectSerialNumber, setSelectSerialNumber] = useState(null);
  const [assetType, setAssetType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState(null);
  const cardsPerPageOptions = [10, 20, 30];
  const [recordsPerPage, setRecordsPerPage] = useState(cardsPerPageOptions[0]);
  const onPageChange = (page) => setCurrentPage(page);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [assetTypeData, setAssetTypeData] = useState([]);
  const [assetFilter, setAssetFilter] = useState([]);
  const [type, setType] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectAssetId, setSelectAssetId] = useState(null);
  const openDeleteModal = (assetId) => {
    setSelectAssetId(assetId);
    setDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setDeleteModalOpen(false);
  };

  // const date = useState(null);
  function formatDateForsubmit(date) {
    const year = date ? date.getFullYear() : "";
    const month = date ? String(date.getMonth() + 1).padStart(2, "0") : "";
    const day = date ? String(date.getDate()).padStart(2, "0") : "";

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const isFilled = false;
  const closeModal = () => {
    setIsOpen(false);
  };

  const initialValues = {
    assetName: "",
    assetType: "",
    serialNumber: "",
    purchaseDate: "",
    warranty: "",
    additionalInfo: "",
  };

  const [startValues, setStartValues] = useState(initialValues);

  const openModal = (editData = null) => {
    if (editData) {
      setStartValues({
        assetId: editData.assetId || "",
        assetName: editData.assetName || "",
        assetType: editData.assetType
          ? editData.assetType.assetTypeMasterId
          : "",
        serialNumber: editData.serialNo || "",
        purchaseDate: editData.purchaseDate
          ? new Date(editData.purchaseDate)
          : "",
        warranty: editData.warranty || "",
        warrantyExpiryDate: editData.warrantyExpiryDate
          ? new Date(editData.warrantyExpiryDate)
          : "",
        additionalInfo: editData.assetDescription || "",
      });

      setType("edit");
    } else {
      setStartValues(initialValues);
      setType("add");
    }
    setIsOpen(true);
  };

  // ------API------

  const fetchData = async () => {
    try {
      const url = ASSET_URL.GET_ALL_ASSET;
      const params = {
        assetName: selectedAssetName,
        serialNumber: selectSerialNumber,
        assetType: assetType,
        itemsPerPage: recordsPerPage,
        pageNumber: currentPage,
      };
      const response = await axiosService.get(url, params);
      const data = response?.data?.data?.data;
      setAssetData(data);
      setTotalElements(parseInt(response.data.data.totalElements));
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedAssetName,
    selectSerialNumber,
    assetType,
    currentPage,
    recordsPerPage,
  ]);

  const getFilter = async () => {
    try {
      const response = await axiosService.get(ASSET_URL.GET_FILTER_ASSET);
      const responseget = response?.data?.data;
      setAssetFilter(responseget);
    } catch (error) {
      console.error("Error fetching filter data:", error);
    }
  };

  useEffect(() => {
    getFilter();
  }, []);

  const getAssetData = async () => {
    try {
      const getData = await axiosService.get(ASSET_URL.GET_ASSET);
      const responceData = getData?.data?.data;

      setAssetTypeData(responceData);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    getAssetData();
  }, []);

  const validationSchema = Yup.object().shape({
    assetName: Yup.string().required("Asset Name is required"),
    assetType: Yup.string().required("Asset Type is required"),
    serialNumber: Yup.string().required("Serial No is required"),
    purchaseDate: Yup.date().nullable().required("Purchase Date is required"),
    warranty: Yup.string().required("Warranty is required"),
    additionalInfo: Yup.string().required("Asset Name is required"),
  });

  const onSubmit = async (values) => {
    try {
      const requestData = {
        assetName: values?.assetName,
        assetType: {
          assetTypeMasterId: parseInt(values?.assetType),
        },
        serialNo: values?.serialNumber,
        warranty: values?.warranty,
        purchaseDate: formatDateForsubmit(values?.purchaseDate),
        warrantyExpiryDate:
          values?.warranty === "NO"
            ? null
            : formatDateForsubmit(values?.warrantyExpiryDate),
        assetDescription: values?.additionalInfo,
      };

      const responce = await axiosService.post(
        ASSET_URL.ADD_ASSET,
        requestData
      );
      if (responce?.status === 200) {
        toast.success("Asset added successfully");
        fetchData();
        closeModal();
      } else {
        toast.error("Failed to add the asset");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage.split(":")[1]);
      } else {
        toast.error("An error occurred while processing your request.");
      }
    }
  };

  const editSubmit = async (values) => {
    try {
      const requestData = {
        assetId: values?.assetId,
        assetName: values?.assetName,
        assetType: {
          assetTypeMasterId: parseInt(values?.assetType),
        },
        serialNo: values?.serialNumber,
        warranty: values?.warranty,
        purchaseDate: formatDateForsubmit(values?.purchaseDate),
        warrantyExpiryDate:
          values?.warranty === "NO"
            ? null
            : formatDateForsubmit(values?.warrantyExpiryDate),
        assetDescription: values?.additionalInfo,
      };

      const responce = await axiosService.put(
        ASSET_URL.UPDATE_ASSET,
        requestData
      );
      if (responce?.status === 200) {
        toast.success("Asset Updated successfully");
        fetchData();
        closeModal();
      } else {
        toast.error("Failed to update the asset");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage.split(":")[1]);
      } else {
        toast.error("An error occurred while processing your request.");
      }
    }
  };

  const handleClickEdit = (e) => {
    openModal(e);
  };

  const handleClickView = async (e) => {
    setStartValues({
      assetName: e.assetName || "",
      assetType: e.assetType ? e.assetType.assetTypeMasterId : "",
      serialNumber: e.serialNo || "",
      purchaseDate: e.purchaseDate ? new Date(e.purchaseDate) : "",
      warranty: e.warranty || "",
      warrantyExpiryDate: e.warrantyExpiryDate
        ? new Date(e.warrantyExpiryDate)
        : "",
      additionalInfo: e.assetDescription || "",
    });
    setType("view");
    setIsOpen(true);
  };

  // ----- Dropdown option-------
  const handleAssetName = (select) => {
    setSelectedAssetName(select);
    setCurrentPage(1);
  };

  const assetNameOption = assetFilter?.map((e) => ({
    label: e.assetName,
    value: e.assetName,
  }));

  const handleSerialNumber = (select) => {
    setSelectSerialNumber(select);
    setCurrentPage(1);
  };

  const assetSerial = assetFilter.map((e) => ({
    label: e.serialNo,
    value: e.serialNo,
  }));

  const handleAssetType = (select) => {
    setAssetType(select);
    setCurrentPage(1);
  };

  const assetTypeOption = assetTypeData.map((e) => ({
    label: e.assetName,
    value: e.assetName,
  }));

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const warrantyOptions = [
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
  ];
  const [isWarrantyFocused, setIsWarrantyFocused] = useState(false);
  const [selectedWarranty, setSelectedWarranty] = useState("");

  const handleWarrantyFocus = () => {
    setIsWarrantyFocused(true);
  };

  const [isAssetTypeFocused, setIsAssetTypeFocused] = useState(false);
  const [selectedAssetType, setSelectedAssetType] = useState("");

  const handleAssetTypeFocus = () => {
    setIsAssetTypeFocused(true);
  };

  const assetTypeOptions = assetTypeData.map((option) => ({
    label: option.assetName,
    value: option.assetTypeMasterId,
  }));

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "42px",
      boxShadow: state.isFocused ? "none" : "none",
      backgroundColor: state.isFocused ? "#ffff" : "rgba(0 0 0 0.05)",
      borderRadius: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: '"Lexend", sans-serif',
      fontSize: "16px",
      marginTop: "0",
      backgroundColor: state.isFocused ? "#3FC28A" : "#ffffff",
      color: "black",
      "&:hover": {
        backgroundColor: "#3FC28A",
        color: "#ffffff",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      fontFamily: '"Lexend", sans-serif',
      fontSize: "16px",
      fontWeight: 400,
    }),
    menu: (provided) => ({
      ...provided,
    }),
  };

  return (
    <>
      <div className="asset-list">
        <div className="asset-list-container">
          <div className="asset-list-banner">
            <div className="container-fluid">
              <div className="row">
                {/* Top Section */}
                <div className="asset-list-top-column">
                  <div className="accet-name-filter">
                    <Dropdown
                      value={selectedAssetName}
                      onChange={(e) => handleAssetName(e.target.value)}
                      options={assetNameOption}
                      optionLabel="label"
                      filter={true}
                      showClear
                      placeholder="Asset Name"
                      className="w-full md:w-14rem asset-name"
                      style={{ zIndex: "0" }}
                    />
                  </div>
                  <div className="accet-type-filter">
                    <Dropdown
                      value={assetType}
                      onChange={(e) => handleAssetType(e.target.value)}
                      options={assetTypeOption}
                      optionLabel="label"
                      showClear
                      placeholder="Asset Type"
                      className="w-full md:w-14rem asset-types"
                      style={{ zIndex: "0" }}
                    />
                  </div>
                  <div className="accet-serial-filter">
                    <Dropdown
                      value={selectSerialNumber}
                      onChange={(e) => handleSerialNumber(e.target.value)}
                      options={assetSerial}
                      optionLabel="label"
                      filter={true}
                      showClear
                      placeholder="Serial Number"
                      className="w-full md:w-14rem accet-serial"
                      style={{ zIndex: "0" }}
                    />
                  </div>
                  <div className="ms-auto">
                    <button
                      className="asset-add-button"
                      onClick={() => openModal()}
                    >
                      <span className="asset-add-icon">
                        <Plus />
                      </span>
                      Add New Asset
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="asset-list-table-container">
                  <table className="asset-list-table">
                    <thead>
                      <tr>
                        <th>Asset Name</th>
                        <th>Asset Type</th>
                        <th>Serial Number</th>
                        <th>Assigned To</th>
                        <th>Purchase Date</th>
                        <th>Warranty</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assetData.map((e, index) => (
                        <tr key={index}>
                          <td>{e.assetName}</td>
                          <td>{e.assetType.assetName}</td>
                          <td>{e.serialNo}</td>
                          <td>
                            {e.employee
                              ? `${e.employee.employeeFirstName} ${e.employee.employeeLastName}`
                              : "NA"}
                          </td>
                          <td>{e.purchaseDate}</td>
                          <td>
                            <div
                              className="asset-list-warranty"
                              style={{
                                background:
                                  e.warranty === "NO"
                                    ? "#E2531933"
                                    : "#3FC28A33",
                                color:
                                  e.warranty === "NO" ? "#E25319" : "#3FC28A",
                              }}
                            >
                              {e.warranty}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-between accet-action">
                              <span onClick={() => handleClickView(e)}>
                                <View />
                              </span>
                              <span onClick={() => handleClickEdit(e)}>
                                <Edit />
                              </span>
                              <span onClick={() => openDeleteModal(e?.assetId)}>
                                <Trash />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Deletemodal
                    modalOpen={deleteModalOpen}
                    closeModal={handleCloseModal}
                    cancelButton={true}
                    submitButton={true}
                    onCloseTitle="Close"
                    assetDatas={selectAssetId}
                    fetchData={fetchData}
                    title="Are you sure"
                    data="You want to delete?"
                  />
                </div>
                <div
                  className="row mt-3"
                  style={{
                    boxShadow: "0px -2px  #F3EEEA",
                    margin: "0px 10px 0px 5px",
                  }}
                >
                  <div className="col w-50  d-flex align-items-center asset-record-list">
                    <span>Showing</span>
                    <div style={{ width: "fit-content", position: "relative" }}>
                      <select
                        value={recordsPerPage}
                        onChange={handleRecordsPerPageChange}
                      >
                        {cardsPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <div className="asset-record-list-icon">
                        <Dropdowns />
                      </div>
                    </div>
                  </div>
                  <div className="col w-50 asset-pagination-container">
                    {Math.ceil(totalElements / recordsPerPage) > 1 ? (
                      <>
                        {totalElements && (
                          <Pagination
                            className="asset-pagination d-inline-flex"
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                              totalElements / recordsPerPage
                            )}
                            onPageChange={onPageChange}
                            previousLabel={<LeftArrow />}
                            nextLabel={<RightArrow />}
                            activePageLinkStyle={{
                              color: "#ffffff !important",
                            }}
                            icon={<span>{currentPage}</span>}
                          />
                        )}
                      </>
                    ) : (
                      <nav
                        class="pagination d-inline-flex"
                        activepagelinkstyle="[object Object]"
                        icon="[object Object]"
                      >
                        <ul class="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.4685 17.5856C14.7919 17.3269 14.8444 16.8549 14.5856 16.5315L10.9604 12L14.5856 7.46849C14.8444 7.14505 14.7919 6.67308 14.4685 6.41432C14.145 6.15556 13.673 6.208 13.4143 6.53145L9.41429 11.5315C9.19516 11.8054 9.19516 12.1946 9.41429 12.4685L13.4143 17.4685C13.673 17.7919 14.145 17.8444 14.4685 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                          <li aria-current="page">
                            <button
                              type="button"
                              class="w-12 border border-gray-300 py-2 leading-tight enabled:hover:bg-gray-100 enabled:hover:text-gray-700 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                            >
                              1
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>
                {/* Modal Section */}
                <div>
                  <Modal
                    isOpen={modalIsOpen}
                    style={customStyles}
                    contentLabel="Asset Modal"
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <div className="asset-modal-title">
                        {type === "edit"
                          ? "Edit Asset"
                          : type === "view"
                          ? "View Asset"
                          : "Add Asset"}
                      </div>
                      <hr />
                      <Formik
                        initialValues={startValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={type === "edit" ? editSubmit : onSubmit}
                      >
                        {({ errors, touched, values, setFieldValue }) => (
                          <Form>
                            <div className="row">
                              <div
                                className=" relative col-lg-6 col-md-6 asset-form-item"
                                style={{ marginTop: "20px" }}
                              >
                                <Field
                                  name="assetName"
                                  className="asset-modal-input"
                                  type="text"
                                  placeholder=""
                                  readOnly={type === "view"}
                                  value={values.assetName}
                                  style={{
                                    backgroundColor: values.assetName
                                      ? "white"
                                      : "rgba(0,0,0,0.05)",
                                    border: values.assetName
                                      ? "1px solid rgba(0, 0, 0, 0.5) "
                                      : "none",
                                  }}
                                />
                                <label className="asset-modal-label">
                                  Asset Name
                                </label>
                                {errors.assetName && touched.assetName && (
                                  <ErrorMessage
                                    name="assetName"
                                    component="div"
                                    className="p-error1"
                                  />
                                )}
                              </div>
                              <div
                                className="col-lg-6 col-md-6 asset-form-item"
                                style={{ marginTop: "20px" }}
                              >
                                <div
                                  className={`select-container-asset mt-0 ${
                                    isAssetTypeFocused ||
                                    selectedAssetType ||
                                    values.assetType
                                      ? "active"
                                      : ""
                                  }`}
                                  style={{
                                    backgroundColor:
                                      values.assetType ||
                                      isAssetTypeFocused ||
                                      selectedAssetType
                                        ? "white"
                                        : "rgba(0,0,0,0.05)",
                                    border:
                                      values.assetType ||
                                      isAssetTypeFocused ||
                                      selectedAssetType
                                        ? "1px solid rgba(0, 0, 0, 0.5) "
                                        : "none",
                                  }}
                                >
                                  <label
                                    htmlFor="assetType"
                                    className="floating-label-asset"
                                  >
                                    AssetType
                                  </label>
                                  <Field name="assetType">
                                    {({ field }) => (
                                      <Select
                                        name="assetType"
                                        {...field}
                                        options={assetTypeOptions}
                                        styles={selectStyles}
                                        classNamePrefix="atnd_gender"
                                        value={assetTypeOptions.find(
                                          (option) =>
                                            option.value === field.value
                                        )}
                                        onChange={(selectedOption) => {
                                          if (
                                            selectedOption &&
                                            selectedOption.value
                                          ) {
                                            setFieldValue(
                                              "assetType",
                                              selectedOption.value
                                            );
                                            setSelectedAssetType(
                                              selectedOption
                                            );
                                          }
                                        }}
                                        onFocus={handleAssetTypeFocus}
                                        isDisabled={type === "view"}
                                        placeholder=""
                                      />
                                    )}
                                  </Field>
                                  {errors.assetType && touched.assetType && (
                                    <ErrorMessage
                                      name="assetType"
                                      component="div"
                                      className="p-errors"
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                className="col-lg-6 col-md-6 asset-form-item"
                                style={{ marginTop: "35px" }}
                              >
                                <Field
                                  name="serialNumber"
                                  className="asset-modal-input"
                                  type="text"
                                  placeholder=""
                                  readOnly={type === "view"}
                                  value={values.serialNumber}
                                  style={{
                                    backgroundColor: values.serialNumber
                                      ? "white"
                                      : "rgba(0,0,0,0.05)",
                                    border: values.serialNumber
                                      ? "1px solid rgba(0, 0, 0, 0.5) "
                                      : "none",
                                  }}
                                />
                                <label className="asset-modal-label">
                                  Serial Number
                                </label>
                                {errors.serialNumber &&
                                  touched.serialNumber && (
                                    <ErrorMessage
                                      name="serialNumber"
                                      component="div"
                                      className="p-error1"
                                    />
                                  )}
                              </div>
                              <div
                                className="col-lg-6 col-md-6 asset-form-item"
                                style={{ marginTop: "35px" }}
                              >
                                <span
                                  className="p-float-label-asset"
                                  style={{ position: "relative" }}
                                >
                                  <Calendar
                                    inputId="purchaseDate"
                                    name="purchaseDate"
                                    value={values.purchaseDate}
                                    disabled={type === "view"}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "purchaseDate",
                                        e.target.value
                                      )
                                    }
                                    className={
                                      "asset-calendar" +
                                      (isFilled ? " filled" : "") +
                                      (values.purchaseDate ? " filled-bg" : "")
                                    }
                                    style={{
                                      width: "100%",
                                      color: "#000",
                                      zIndex: "0",
                                    }}
                                    showIcon
                                    readOnlyInput
                                  />
                                  <label className="asset-date-picker-lable">
                                    Purchase Date
                                  </label>
                                  {errors.purchaseDate &&
                                    touched.purchaseDate && (
                                      <ErrorMessage
                                        name="purchaseDate"
                                        component="div"
                                        className="p-error1"
                                      />
                                    )}
                                </span>
                              </div>
                              <div
                                className="col-lg-6 col-md-6 asset-form-item"
                                style={{ marginTop: "35px" }}
                              >
                                <div
                                  className={`select-container-asset mt-0 ${
                                    isWarrantyFocused ||
                                    selectedWarranty ||
                                    values.warranty
                                      ? "active"
                                      : ""
                                  }`}
                                  style={{
                                    backgroundColor:
                                      values.warranty ||
                                      isWarrantyFocused ||
                                      selectedWarranty
                                        ? "white"
                                        : "rgba(0,0,0,0.05)",
                                    border:
                                      values.warranty ||
                                      isWarrantyFocused ||
                                      selectedWarranty
                                        ? "1px solid rgba(0, 0, 0, 0.5) "
                                        : "none",
                                  }}
                                >
                                  <label
                                    htmlFor="warranty"
                                    className="floating-label-asset"
                                  >
                                    Warranty
                                  </label>
                                  <Field name="warranty">
                                    {({ field }) => (
                                      <Select
                                        name="warranty"
                                        {...field}
                                        options={warrantyOptions}
                                        styles={selectStyles}
                                        classNamePrefix="atnd_gender"
                                        value={warrantyOptions.find(
                                          (option) =>
                                            option.value === field.value
                                        )}
                                        onChange={(selectedOption) => {
                                          if (
                                            selectedOption &&
                                            selectedOption.value
                                          ) {
                                            setFieldValue(
                                              "warranty",
                                              selectedOption.value
                                            );
                                            setSelectedWarranty(selectedOption);
                                          }
                                        }}
                                        onFocus={handleWarrantyFocus}
                                        isDisabled={type === "view"}
                                        placeholder=""
                                      />
                                    )}
                                  </Field>
                                  {errors.warranty && touched.warranty && (
                                    <ErrorMessage
                                      name="warranty"
                                      component="div"
                                      className="p-errors"
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                className="col-lg-6 col-md-6 asset-form-item"
                                style={{ marginTop: "35px" }}
                              >
                                <span
                                  className="p-float-label-asset"
                                  style={{ position: "relative" }}
                                >
                                  <Calendar
                                    inputId="warrantyExpiryDate"
                                    name="warrantyExpiryDate"
                                    disabled={
                                      type === "view" ||
                                      values.warranty === "NO"
                                    }
                                    value={
                                      values.warranty !== "NO"
                                        ? values.warrantyExpiryDate
                                        : null
                                    }
                                    onChange={(e) =>
                                      setFieldValue(
                                        "warrantyExpiryDate",
                                        e.value
                                      )
                                    }
                                    className={
                                      "asset-calendar" +
                                      (isFilled ? " filled" : "") +
                                      (values.warrantyExpiryDate
                                        ? " filled-bg"
                                        : "")
                                    }
                                    style={{
                                      width: "100%",
                                      color: "#000",
                                      zIndex: "0",
                                    }}
                                    showIcon
                                    readOnlyInput
                                  />
                                  <label className="asset-date-picker-lable">
                                    Warranty expiry Date
                                  </label>
                                </span>
                              </div>
                              <div
                                className="col-lg-12 col-md-10 asset-form-item"
                                style={{ marginTop: "35px" }}
                              >
                                <Field
                                  name="additionalInfo"
                                  className="asset-modal-input"
                                  type="text"
                                  placeholder=""
                                  readOnly={type === "view"}
                                  value={values.additionalInfo}
                                  style={{
                                    backgroundColor: values.additionalInfo
                                      ? "white"
                                      : "rgba(0,0,0,0.05)",
                                    border: values.additionalInfo
                                      ? "1px solid rgba(0, 0, 0, 0.5) "
                                      : "none",
                                    height: "80px",
                                  }}
                                />
                                <label className="asset-modal-labels">
                                  Additional Info
                                </label>
                                {errors.additionalInfo &&
                                  touched.additionalInfo && (
                                    <ErrorMessage
                                      name="additionalInfo"
                                      component="div"
                                      className="p-error1"
                                    />
                                  )}
                              </div>
                              {type === "view" && (
                                <>
                                  <div
                                    className="col-lg-6 col-md-6 asset-form-item"
                                    style={{ marginTop: "30px" }}
                                  >
                                    <Field
                                      name="assignedTo"
                                      className="asset-modal-input"
                                      type="text"
                                      placeholder=""
                                      value={values.assignedTo}
                                      readOnly={type === "view"}
                                      style={{
                                        backgroundColor: values.assignedTo
                                          ? "white"
                                          : "rgba(0,0,0,0.05)",
                                        border: values.assignedTo
                                          ? "1px solid rgba(0, 0, 0, 0.5) "
                                          : "none",
                                      }}
                                    />
                                    <label className="asset-modal-label">
                                      Assigned To
                                    </label>
                                  </div>
                                  <div
                                    className="col-lg-6 col-md-6 asset-form-item"
                                    style={{ marginTop: "30px" }}
                                  >
                                    <span
                                      className="p-float-label-asset"
                                      style={{ position: "relative" }}
                                    >
                                      <Calendar
                                        inputId="AssignedDate"
                                        name="AssignedDate"
                                        value={values.AssignedDate}
                                        disabled
                                        onChange={(e) =>
                                          setFieldValue(e.target.value)
                                        }
                                        className={
                                          "asset-calendar" +
                                          (isFilled ? " filled" : "") +
                                          (values.AssignedDate
                                            ? " filled-bg"
                                            : "")
                                        }
                                        style={{
                                          width: "100%",
                                          color: "#000",
                                          zIndex: "0",
                                        }}
                                        showIcon
                                        readOnlyInput
                                      />
                                      <label className="asset-date-picker-lable">
                                        Assigned Date
                                      </label>
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                            <div
                              className="gap-3 d-flex justify-content-center"
                              style={{ marginTop: "20px" }}
                            >
                              {type === "view" ? (
                                <button
                                  onClick={closeModal}
                                  className="Asset-cancle-button"
                                >
                                  Close
                                </button>
                              ) : (
                                <button
                                  onClick={closeModal}
                                  className="Asset-cancle-button"
                                >
                                  Cancel
                                </button>
                              )}
                              {type === "edit" ? (
                                <button
                                  type="submit"
                                  className="asset-add-buton"
                                >
                                  Update
                                </button>
                              ) : type === "view" ? (
                                ""
                              ) : (
                                <button
                                  type="submit"
                                  className="asset-add-buton"
                                >
                                  Add
                                </button>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetList;
