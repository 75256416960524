import { Field, Form, Formik } from "formik";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import React from "react";

const MyPersonalInfo = ({ values }) => {
  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }


  return (
    <div className="my-profile-content-personal">
      <Formik initialValues={values} enableReinitialize={true}>
        {({ values }) => (
          <Form>
            <div className="row add-emp-row-1">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="employeeFirstName"
                    type="text"
                    placeholder=""
                    readOnly
                    value={values.employeeFirstName}
                    style={{
                      backgroundColor: values.employeeFirstName
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.employeeFirstName
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">First Name</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="employeeLastName"
                    type="text"
                    placeholder=""
                    readOnly
                    style={{
                      backgroundColor: values.employeeLastName
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.employeeLastName
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Last Name</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text "
                    name="mobileNumber"
                    readOnly
                    style={{
                      backgroundColor: values.mobileNumber
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.mobileNumber
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                    placeholder=""
                  />
                  <label className="add-Employee-label">Mobile Number</label>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="employeeDob"
                    type="text"
                    placeholder=""
                    readOnly
                    value={values?.employeeDob?.split("-").reverse().join("/")}
                    style={{
                      backgroundColor: values.employeeDob
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.employeeDob
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label"> Date of Birth</label>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="bloodGroup"
                    type="text"
                    placeholder=""
                    readOnly
                    style={{
                      backgroundColor: values.bloodGroup
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.bloodGroup
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Blood Group</label>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="nationality"
                    type="text"
                    placeholder=""
                    readOnly
                    value={capitalizeFirstLetter(values.gender)}
                    style={{
                      backgroundColor: values.gender
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.gender
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label"> Gender</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="email"
                    type="text"
                    placeholder=""
                    readOnly
                    style={{
                      backgroundColor: values.email
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.email
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">
                    Personal Email Address
                  </label>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="nationality"
                    type="text"
                    placeholder=""
                    readOnly
                    value={values.nationality}
                    style={{
                      backgroundColor: values.nationality
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.nationality
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label"> Nationality</label>
                </div>
              </div>
              <div
                className="col-lg-8 col-md-6 col-sm-12"
                style={{ marginTop: "-8px" }}
              >
                <div className="add-Employee-form-item add-Employee-form-item--text w-100 mt-2">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="address"
                    type="text"
                    placeholder=""
                    readOnly
                    style={{
                      backgroundColor: values.address
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.address
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Address</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="pinCode"
                    type="text"
                    placeholder=""
                    readOnly
                    style={{
                      backgroundColor: values.pinCode
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.pinCode
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">ZIP Code</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="state"
                    type="text"
                    placeholder=""
                    readOnly
                    value={values.state}
                    style={{
                      backgroundColor: values.state
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.state
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label"> State</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="city"
                    type="text"
                    placeholder=""
                    readOnly
                    value={values.city}
                    style={{
                      backgroundColor: values.city
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.city
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">City</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-6  col-sm-12">
                <div
                  className="add-Employee-form-item add-Employee-form-item--text w-100"
                  style={{ margin: "0px" }}
                >
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text "
                    name="contactName"
                    type="text"
                    placeholder=""
                    readOnly
                    style={{
                      backgroundColor: values.contactName
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.contactName
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">
                    Emergency Contact Name
                  </label>
                </div>
              </div>
              <div className="col-lg-4 col-md-6  col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="relationship"
                    type="text"
                    placeholder=""
                    readOnly
                    style={{
                      backgroundColor: values.relationship
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.relationship
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Relationship</label>
                </div>
              </div>
              <div className="col-lg-4 col-md-6  col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="emergencyContactNo"
                    style={{
                      backgroundColor: values.emergencyContactNo
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values.emergencyContactNo
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                    placeholder=""
                    readOnly
                  />
                  <label className="add-Employee-label">
                    Emergency Contact Number
                  </label>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyPersonalInfo;
