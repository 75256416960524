import { AppConfig } from "../../../env.development";

export const TIMESHEET_URL = {
  ADD_TIMESHEET: `${AppConfig.api_url}time-sheet/v1`,
  UPDATE_TIMESHEET: `${AppConfig.api_url}time-sheet/v1`,
  GET_ALL_TIMESHEET_FILTER: `${AppConfig.api_url}time-sheet/v1/pagination-filter`,
  GET_ALL_TIMESHEET: `${AppConfig.api_url}time-sheet/v1`,
  GET_TIMESHEET_BY_EMPID: `${AppConfig.api_url}time-sheet/v2/my-timesheet/`,
  GET_TIMESHEET_EXPORT: `${AppConfig.api_url}time-sheet/v1/export/`

  

};