import { Field, FieldArray, Form, Formik } from "formik";
import { Calendar } from "primereact/calendar";
import React from "react";

const MyAsset = ({ values }) => {
  console.log(values, "values");
  function formatDateForsubmit(date) {
    const year = date ? date.getFullYear() : "";
    const month = date ? String(date.getMonth() + 1).padStart(2, "0") : "";
    const day = date ? String(date.getDate()).padStart(2, "0") : "";
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  function parseDate(dateString) {
    if (!dateString) return null;
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }
  return (
    <div className="employee-list-container-personal">
      <div className="employee-list-banner mt-0">
        <div
          className="employee-list-table-holder pt-0 mt-0"
          style={{
            marginTop: "0",
            borderTop: "none",
          }}
        >
          <div className="Employee-profile-container w-100">
            <div className="addEmployee-details w-100">
              <Formik initialValues={values}>
                {() => (
                  <Form>
                    <FieldArray name="assetList">
                      {() => (
                        <>
                          <div className="row employee-asset-container">
                            <div className="col-lg-3 col-md-3 ">
                              <label style={{ marginLeft: "2px" }}>
                                Asset Name
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <label style={{ marginLeft: "-1px" }}>
                                Asset Type
                              </label>
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <label style={{ marginLeft: "-7px" }}>
                                Serial Number
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <label style={{ marginLeft: "-12px" }}>
                                Assign Date
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <label style={{ marginLeft: "-19px" }}>
                                Surrender Date
                              </label>
                            </div>
                            {values.assetList &&
                              values.assetList.map((assetList, index) => (
                                <div
                                  className="row employee-asset-main row-gap-3"
                                  style={{ marginTop: "10px" }}
                                  key={index}
                                >
                                  <div className="col-lg-3 col-md-3">
                                    <div className="employee-asset-drop">
                                      <Field
                                        name={`assetList[${index}].assetName`}
                                      >
                                        {({ field, form }) => (
                                          <div
                                            className="w-full md:w-14rem asset-name-drop"
                                            style={{
                                              backgroundColor: field.value
                                                ? "white"
                                                : "rgba(0,0,0,0.05)",
                                              color: field.value
                                                ? "black"
                                                : "none",
                                              padding: "2px 10px",
                                            }}
                                          >
                                            {field.value}
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <Field
                                      name={`assetList[${index}].assetType.assetName`}
                                      className="add-employee-asset-input"
                                      type="text"
                                      readOnly
                                      style={{
                                        backgroundColor: values.assetList[index]
                                          .assetType
                                          ? "white"
                                          : "rgba(0,0,0,0.05)",
                                        color: values.assetList[index].assetType
                                          ? "black"
                                          : "none",
                                        border: values.assetList[index]
                                          .assetType
                                          ? " 1px solid lightgray"
                                          : "rgba(0,0,0,0.05)",
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-3">
                                    <div className="employee-asset-drop">
                                      <Field
                                        name={`assetList[${index}].serialNo`}
                                      >
                                        {({ field, form }) => (
                                          <div
                                            className="w-full md:w-14rem asset-name-drop"
                                            style={{
                                              backgroundColor: field.value
                                                ? "white"
                                                : "rgba(0,0,0,0.05)",
                                              color: field.value
                                                ? "black"
                                                : "none",
                                              padding: "2px 10px",
                                            }}
                                          >
                                            {field.value}
                                          </div>
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <span className="p-float-employee-asset">
                                      <Field
                                        name={`assetList[${index}].assignDate`}
                                      >
                                        {({ field, form }) => (
                                          <Calendar
                                            inputId={`assignDate-${index}`}
                                            value={parseDate(field.value)}
                                            onChange={(e) => {
                                              const formattedDate =
                                                formatDateForsubmit(e.value);
                                              form.setFieldValue(
                                                field.name,
                                                formattedDate
                                              );
                                            }}
                                            className={
                                              "employee-asset-calendar" +
                                              (field.value ? " filled-bg" : "")
                                            }
                                            style={{
                                              width: "100%",
                                              color: "#000",
                                              zIndex: "0",
                                            }}
                                            showIcon
                                            readOnlyInput
                                          />
                                        )}
                                      </Field>
                                    </span>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <span className="p-float-employee-asset">
                                      <Field
                                        name={`assetList[${index}].surrenderDate`}
                                      >
                                        {({ field, form }) => (
                                          <Calendar
                                            inputId={`surrenderDate-${index}`}
                                            value={parseDate(field.value)}
                                            onChange={(e) => {
                                              const formattedDate =
                                                formatDateForsubmit(e.value);
                                              form.setFieldValue(
                                                field.name,
                                                formattedDate
                                              );
                                            }}
                                            className={
                                              "employee-asset-calendar" +
                                              (field.value ? " filled-bg" : "")
                                            }
                                            style={{
                                              width: "100%",
                                              color: "#000",
                                              zIndex: "0",
                                            }}
                                            showIcon
                                            readOnlyInput
                                          />
                                        )}
                                      </Field>
                                    </span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAsset;
