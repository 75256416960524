import React, { useEffect, useState } from "react";
import "./MyReportees.css";
import Chart from "react-apexcharts";
import { FaUserFriends } from "react-icons/fa";
import sampleImage from "../../../../assets/images-png/dashboard/reportees/user.png";
import { useNavigate } from "react-router";
import { Tooltip } from 'react-tooltip';

const MyReportees = ({ reporteesData }) => {
  console.log(reporteesData, "reprrrrrrrrrrrrrrrr")
  const navigate = useNavigate();
  const graphReportOnTime = reporteesData?.graphReports || {};
  const dates = Object.keys(graphReportOnTime);
  const onTimeData = dates.map((date) => graphReportOnTime[date]?.onTime || 0);

  const splineChart1 = {
    chart: {
      type: "area",
      height: 80,
      redrawOnWindowResize: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#3FC28A"],
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        colorStops: [
          {
            offset: 0,
            color: "#3FC28A",
            opacity: 0.7,
          },
          {
            offset: 100,
            color: "#FFFFFF",
            opacity: 0.9,
          },
        ],
      },
    },
    xaxis: {
      type: "datetime",
      categories: dates,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        right: 10,
        left: 0,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const chartData1 = [
    {
      name: "On Time",
      data: onTimeData,
    },
  ];

  const graphReportLate = reporteesData?.graphReports || {};
  const datesLate = Object.keys(graphReportLate);
  const lateData = dates.map((date) => graphReportLate[date]?.late || 0);

  const splineChart2 = {
    chart: {
      type: "area",
      height: 80,
      redrawOnWindowResize: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",

      colors: ["#FEB85B"],
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        colorStops: [
          {
            offset: 0,
            color: "#FEB85B",

            opacity: 0.7,
          },
          {
            offset: 100,

            color: "#FFFFFF",

            opacity: 0.9,
          },
        ],
      },
    },
    xaxis: {
      type: "datetime",
      categories: datesLate,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,

      padding: {
        right: 10,
        left: 0,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const chartData2 = [
    {
      name: "Late",
      data: lateData,
    },
  ];

  const graphReportAbsent = reporteesData?.graphReports || {};
  const datesAbsent = Object.keys(graphReportAbsent);
  const ansentData = dates.map((date) => graphReportAbsent[date]?.absent || 0);

  const splineChart3 = {
    chart: {
      type: "area",
      height: 80,
      redrawOnWindowResize: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",

      colors: ["#F45B69"],
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
        colorStops: [
          {
            offset: 0,
            color: "#F45B69",
            opacity: 0.7,
          },
          {
            offset: 100,

            color: "#FFFFFF",

            opacity: 0.9,
          },
        ],
      },
    },
    xaxis: {
      type: "datetime",
      categories: datesAbsent,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        right: 10,
        left: 0,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const chartData3 = [
    {
      name: "Absent",
      data: ansentData,
    },
  ];

  /*-----Attendance overview chart-----*/

  if (!reporteesData || !reporteesData.mapAttendanceReports) {
    return <div>No data available</div>;
  }
  const mapAttendanceReports = reporteesData.mapAttendanceReports;
  const daysOfWeek = Object.keys(mapAttendanceReports);

  const dayAbbreviations = {
    MONDAY: "Mon",
    TUESDAY: "Tue",
    WEDNESDAY: "Wed",
    THURSDAY: "Thu",
    FRIDAY: "Fri",
    SATURDAY: "Sat",
    SUNDAY: "Sun",
  };

  // Extract and abbreviate days
  const abbreviatedDays = daysOfWeek.map((day) => dayAbbreviations[day] || day);

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "10px",
        borderRadius: 4,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "all",

        barSpacing: 10,
        // barHeight: "10",
        // distributed: true,
      },
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        formatter: (value) => `${value}%`
      },
    },
    legend: {
      position: "right",
    },
    xaxis: {
      categories: abbreviatedDays,
      axisBorder: {
        show: true,
        color: "#16151C1A",
      },
      axisTicks: {
        show: true,
        color: "#16151C1A",
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },
    colors: ["#3FC28A", "#FEB85B", "#F45B69"],

    dataLabels: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "On Time",
      data: daysOfWeek.map((day) => mapAttendanceReports[day]?.onTime || 0),
    },
    {
      name: "Late",
      data: daysOfWeek.map((day) => mapAttendanceReports[day]?.late || 0),
    },
    {
      name: "Absent",
      data: daysOfWeek.map((day) => mapAttendanceReports[day]?.absent || 0),
    },
  ];

  function convertMonthNumberToString(dateString) {
    let [year, month, day] = dateString.split("-");

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${monthName}-${String(day).padStart(2, "0")}-${year}`;
  }

  const getStatusButton = (leaveStatus) => {
    if (leaveStatus === "APPROVED") {
      return <div className="leave-list-active">Approved</div>;
    } else if (leaveStatus === "REJECTED") {
      return <div className="leave-list-inactive">Rejected</div>;
    } else if (leaveStatus === "PENDING") {
      return <div className="leave-list-pending">Pending</div>;
    } else if (leaveStatus === "CANCELLED") {
      return <div className="leave-list-cancel">Cancelled</div>;
    } else if (leaveStatus === "APPROVEDANDCANCELLED") {
      return <div className="leave-list-cancel">Approved & Cancelled</div>;
    }
    return null;
  };

  const onTimePercentage = (
    (reporteesData?.onTime / reporteesData?.totalCount) *
    100
  ).toFixed(0);
  const latePercentage = (
    (reporteesData?.late / reporteesData?.totalCount) *
    100
  ).toFixed(0);
  const absentPercentage = (
    (reporteesData?.absent / reporteesData?.totalCount) *
    100
  ).toFixed(0);

  const lastFiveReports = reporteesData?.leaveReports
    ? reporteesData.leaveReports
      .sort((a, b) => new Date(b.appliedDate) - new Date(a.appliedDate))
      .slice(0, 5)
    : [];


  const lastFiveTodayReports = reporteesData?.attendanceReports
    ? reporteesData.attendanceReports
      .sort((a, b) => new Date(b.checkInTime) - new Date(a.checkInTime))
      .slice(0, 5)
    : [];


  return (
    <div className="myreportees-main-div">
      <div className="myreportees-line-chart-div-container">
        <div
          className="myreportees-line-chart-div"
          style={{ backgroundColor: "#FFFFFF", padding: "15px" }}
        >
          {/* <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "#3FC28A" }}>
            <FaUserFriends style={{ fontSize: "22px" }} />
            On Time
          </div>
          <div
            style={{
              fontSize: "24px",
              fontWeight: "700",
            }}
          >
            {onTimePercentage}%
          </div> */}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <span style={{ display: "flex", alignItems: "center", gap: "10px", color: "#3FC28A", fontSize:"15px", fontWeight:"500" }}>
                <FaUserFriends style={{ fontSize: "22px"}}/>
                On Time
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {reporteesData?.onTime}/{reporteesData?.totalCount}
              </span>
            </div>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "700",
              }}
            >
              {onTimePercentage}%
            </span>
          </div>

          <div style={{ marginTop: "-15px" }}>
            <Chart
              options={splineChart1}
              series={chartData1}
              type="area"
              height={80}
            />
          </div>
        </div>
        <div
          className="myreportees-line-chart-div"
          style={{ backgroundColor: "#FFFFFF", padding: "15px" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 5px" }}>
            <div>
              <span style={{ display: "flex", alignItems: "center", gap: "10px", color: "#FEB85B", fontSize:"15px", fontWeight:"500" }}>
                <FaUserFriends style={{ fontSize: "22px" }} />
                Late
              </span>
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                {reporteesData?.late}/{reporteesData?.totalCount}
              </span>
            </div>
            <span
              style={{
                fontSize: "25px",
                fontWeight: "600",
              }}
            >
              {latePercentage}%
            </span>
          </div>
          <div style={{ marginTop: "-15px" }}>
            <Chart
              options={splineChart2}
              series={chartData2}
              type="area"
              height={80}
            />
          </div>
        </div>
        <div
          className="myreportees-line-chart-div"
          style={{ backgroundColor: "#FFFFFF", padding: "15px" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 5px" }}>
            <div>
              <span style={{ display: "flex", alignItems: "center", gap: "10px", color: "#F45B69",fontSize:"15px", fontWeight:"500" }}>
                <FaUserFriends style={{ fontSize: "22px" }} />
                Absent
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {reporteesData?.absent}/{reporteesData?.totalCount}
              </span>
            </div>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "700",
              }}
            >
              {absentPercentage}%
            </span>
          </div>
          <div style={{ marginTop: "-15px" }}>
            <Chart
              options={splineChart3}
              series={chartData3}
              type="area"
              height={80}
            />
          </div>
        </div>
      </div>
      <div className="myreportees-table-chart-div-container">
        <div
          className="myreportees-table-chart-div"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div className="myreportees-attendance-overview-container">
            <h5
              style={{ fontSize: "16px", fontWeight: "600", margin: "5px 0 0 0" }}
            >
              Reportees Attendance Overview (Last 7 Days)
            </h5>
            {/* <p>
              ( Last <span>7</span> days )
            </p> */}
          </div>
          <Chart options={options} series={series} type="bar" height={295} />
        </div>
        <div
          className="myreportees-table-chart-div"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div className="myreportees-leave-overview-section">
            <h5
              style={{ fontSize: "16px", fontWeight: "600", margin: "0", textOverflow: "ellipsis" }}
            >
              Reportees Leave Overview
            </h5>
            <button onClick={() => navigate("/reportees", { state: { activeTab: "AppliedLeaves" } })}>View all</button>
          </div>

          <div className="reportees-timesheet-list-table-container">
            <table
              className="dashboard-leave-overview-table"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {lastFiveReports?.map((e, index) => (
                  <tr key={index} style={{ height: "47px", position: "relative" }}  >
                    <td className="myreportees-leave-overview"
                      // data-tooltip={`${e?.employee?.employeeFirstName} ${e?.employee?.employeeLastName}`}
                      data-tooltip-id={`tooltip-${index}`}
                    >

                      {/* {e?.profileImage ? (
                        <img
                          src={e?.employee?.profileImage}
                          alt="employee_image"
                        />
                      ) : (
                        <img src={sampleImage} alt="employee_image" />
                      )} */}
                      {e?.employee?.employeeFirstName}{" "}
                      {e?.employee?.employeeLastName}
                    </td>

                    <td style={{ width: "45%" }}>
                      {convertMonthNumberToString(e.startDate)} -{" "}
                      {/* <br /> */}
                      {convertMonthNumberToString(e.endDate)}
                    </td>

                    <td style={{ width: "22%" }}>{getStatusButton(e?.leaveStatus)}</td>
                    <Tooltip id={`tooltip-${index}`} place="top" effect="solid"
                      style={{
                        backgroundColor: '#333',
                        color: '#fff',
                        borderRadius: '6px',
                        padding: '5px 10px',
                        fontSize: '14px',
                        zIndex: "11"
                      }}>
                      {`${e?.employee?.employeeFirstName} ${e?.employee?.employeeLastName}`}

                    </Tooltip>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="myreportees-table-chart-div-container">
        <div
          className="myreportees-table-chart-div"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <div className="myreportees-table-attendance-overview-header">
            <h5 style={{ fontSize: "16px", fontWeight: "600", margin: '0' }}>Reportees Attendance Overview (Today)</h5>
            <button onClick={() => navigate("/reportees", { state: { activeTab: "ReporteesAttendance" } })}>View All</button>
          </div>
          <div className="myreportees-table-attendance-overview">
            <table
              className="reportees-views-list-table"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Check In</th>
                  <th>Check Out</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {lastFiveTodayReports?.map((data, index) => (
                  <tr key={index} style={{ height: "50px" }}>
                    <td className="myreportees-name-ans-image"   data-tooltip-id={`tooltips-${index}`}>
                      {/* {data?.employee?.profileImage ? (
                        <img
                          src={data?.employee?.profileImage}
                          alt="employee_image"
                        />
                      ) : (
                        <img src={sampleImage} alt="employee_image" />
                      )} */}
                      {data?.employee?.employeeFirstName}{" "}
                      {data?.employee?.employeeLastName}
                    </td>
                    
                    <td style={{ width: "22%" }}>
                      {data?.checkInTime ? (
                        <span>
                          {new Date(data?.checkInTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td style={{ width: "22%" }}>
                      {data?.checkOutTime ? (
                        <sapn>
                          {new Date(data?.checkOutTime).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </sapn>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td
                      className="myreportees-attendance-status-btn"
                      style={{
                        width:"22%",
                        color:
                          data?.attendanceStatus === "ONTIME"
                            ? "#3FC28A"
                            : data?.attendanceStatus === "LATE"
                              ? "#F45B69"
                              : "black",
                        backgroundColor:
                          data?.attendanceStatus === "ONTIME"
                            ? "#3FC28A33"
                            : data?.attendanceStatus === "LATE"
                              ? "#F45B6933"
                              : "white",
                      }}
                    >
                      {data?.attendanceStatus}
                    </td>
                    <Tooltip id={`tooltips-${index}`} place="top" effect="solid"
                      style={{
                        backgroundColor: '#333',
                        color: '#fff',
                        borderRadius: '6px',
                        padding: '5px 10px',
                        fontSize: '14px',
                        zIndex: "11"
                      }}>
                      {`${data?.employee?.employeeFirstName} ${data?.employee?.employeeLastName}`}

                    </Tooltip>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="myreportees-table-chart-div"></div>
      </div>
    </div>
  );
};

export default MyReportees;
