import React from 'react'

const Action = () => {
  return (
    <svg width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="33" y="36" width="33" height="36" rx="10" transform="rotate(-180 33 36)" fill="#E25319" fill-opacity="0.8"/>
<path d="M19.8 15L16.5 18M16.5 18L19.8 21M16.5 18H28.6M23.1 22.7514V22.7998C23.1 23.9199 23.1 24.4804 22.8602 24.9082C22.6493 25.2845 22.313 25.5903 21.899 25.782C21.4284 26 20.8119 26 19.5798 26H14.5198C13.2876 26 12.6724 26 12.2018 25.782C11.7879 25.5903 11.4504 25.2845 11.2395 24.9082C11 24.4808 11 23.921 11 22.8031V13.1964C11 12.0785 11 11.5195 11.2395 11.0921C11.4504 10.7158 11.7879 10.4095 12.2018 10.2178C12.672 10 13.2869 10 14.5166 10H19.5834C20.8131 10 21.4289 10 21.899 10.2178C22.313 10.4095 22.6493 10.7161 22.8602 11.0924C23.1 11.5202 23.1 12.0799 23.1 13.2V13.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default Action