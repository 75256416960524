import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ProfileNavbar from "../profile-navbar/ProfileNavbar";
const ProfileRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/profile") {
      navigate("/profile/profile-list");
    }
  }, [location.pathname, navigate]);
  return (
    <Routes>
      <Route path="profile-list" element={<ProfileNavbar/>} />
    </Routes>
  );
};

export default ProfileRoutes;

