import React from "react";

function Reject() {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9999 19.6142L11.5 11.1143M11.5 11.1143L3 2.61426M11.5 11.1143L20 2.61426M11.5 11.1143L3 19.6143"
        stroke="#A41515"
        stroke-opacity="0.7"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Reject;
