import React from "react";

const Update = () => {
  return (
    <svg
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19.0002L5 19M15 19.0002L15.8031 19C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2843 18.7822 17.908C19 17.4806 19 16.921 19 15.8031V7.21955C19 6.77072 19 6.54521 18.9521 6.33105C18.9095 6.14 18.8393 5.95652 18.7432 5.78595C18.6366 5.59674 18.487 5.43055 18.1929 5.10378L15.4377 2.04241C15.0969 1.66374 14.9242 1.47181 14.7168 1.33398C14.5303 1.21 14.3242 1.11858 14.1073 1.06287C13.8625 1 13.5998 1 13.075 1H4.2002C3.08009 1 2.51962 1 2.0918 1.21799C1.71547 1.40973 1.40973 1.71547 1.21799 2.0918C1 2.51962 1 3.08009 1 4.2002V15.8002C1 16.9203 1 17.4796 1.21799 17.9074C1.40973 18.2837 1.71547 18.5905 2.0918 18.7822C2.5192 19 3.07899 19 4.19691 19H5M15 19.0002V15.1969C15 14.079 15 13.5192 14.7822 13.0918C14.5905 12.7155 14.2837 12.4097 13.9074 12.218C13.4796 12 12.9203 12 11.8002 12H8.2002C7.08009 12 6.51962 12 6.0918 12.218C5.71547 12.4097 5.40973 12.7155 5.21799 13.0918C5 13.5196 5 14.0801 5 15.2002V19M13 5H7"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  );
};

export default Update;
