import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
 
function Toaster() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      style={{
        zIndex: 1111111,
      }}
    />
  );
}
 
export default Toaster;