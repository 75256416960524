import React from "react";

function AllEmployeesLogo({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11ZM12 19C15.3137 19 18 17.6569 18 16C18 14.3431 15.3137 13 12 13C8.68629 13 6 14.3431 6 16C6 17.6569 8.68629 19 12 19ZM6.38165 13.019C3.91199 13.1713 2 14.2241 2 15.5001C2 16.5521 3.29949 17.4523 5.13963 17.8213C4.72843 17.2645 4.5 16.6483 4.5 16.0001C4.5 14.8567 5.21076 13.8129 6.38165 13.019ZM19.5001 16.0001C19.5001 16.6483 19.2716 17.2645 18.8604 17.8213C20.7006 17.4523 22.0001 16.5521 22.0001 15.5001C22.0001 14.2241 20.0881 13.1713 17.6184 13.019C18.7893 13.8129 19.5001 14.8567 19.5001 16.0001ZM15.7183 10.5354C16.2115 9.8134 16.5 8.94039 16.5 8C16.5 7.68794 16.4682 7.3833 16.4077 7.08914C16.5949 7.0312 16.7938 7 17 7C18.1045 7 19 7.89543 19 9C19 10.1046 18.1045 11 17 11C16.5123 11 16.0653 10.8254 15.7183 10.5354ZM7 7C7.20619 7 7.40509 7.0312 7.59223 7.08914C7.53176 7.3833 7.5 7.68794 7.5 8C7.5 8.94039 7.78845 9.8134 8.28171 10.5354C7.93462 10.8254 7.4877 11 7 11C5.89543 11 5 10.1046 5 9C5 7.89543 5.89543 7 7 7Z"
        fill={color}
      />
    </svg>
  );
}

export default AllEmployeesLogo;
