import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import AllEmployeeAsset from "../all-employee-asset/AllEmployeeAsset";
import AllEmployeeBankInfo from "../all-employee-bankInfo/AllEmployeeBankInfo";
import "../all-employee-navbar/AllEmployeeNavbar.css";
import ProfessionalInfo from "../all-employee-professional-info/ProfessionalInfo";
import PersonalInfo from "../all-employee-profile/PersonalInfo";
import EmployeesDocuments from "../all-employees-documents/EmployeesDocuments";
import AllEmployeeOtherInfo from "../all-employee-otherInfo/AllEmployeeOtherInfo";

const AllEmployeeNavbar = () => {
  const location = useLocation();
  const Data = location.state?.viewData;
  const type = location?.state?.type;
  const [activeTab, setActiveTab] = useState("Personalinfo");
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);
  const containerRef = useRef();

  useEffect(() => {
    const navbar = document.getElementById("navbarSupportedContent");
    navbar.addEventListener("hidden.bs.collapse", () => {
      setNavbarCollapsed(true);
    });
    navbar.addEventListener("shown.bs.collapse", () => {
      setNavbarCollapsed(false);
    });
  }, []);

  const [startValues, setStartValues] = useState({
    empId: "",
    profileImage: "",
    profileImageName: "",
    employeeFirstName: "",
    employeeLastName: "",
    employeeDob: "",
    email: "",
    gender: "",
    nationality: "",
    pinCode: "",
    city: "",
    state: "",
    bloodGroup: "",
    mobileNumber: "",
    employeeDoj: "",
    designation: "",
    salary: "",
    address: "",
    aadhaarNumber: "",
    panNumber: "",
    bankName: "",
    branch: "",
    accountNumber: "",
    ifscCode: "",
    officeEmail: "",
    contactName: "",
    relationship: "",
    emergencyContactNo: "",
    employeeId: "",
    lastWorkingDate: "",
    joiningDate: "",
    active: "",
    aadharFile: null,
    aadharFileName: null,
    panFile: null,
    panFileName: null,
    bankPassbookFile: null,
    bankPassbookFileName: null,
    passportSizePhoto: null,
    passportFileName: null,
    degreeFile:null,
    degreeFileName:null,
    userName: "",
    employeeCreatedTime: "",
    supervisor: "",
    attendanceList: [],
    // assetList:[],
    assetList: [
      {
        assetId: "",
        assetName: null,
        assetType: "",
        serialNo: null,
        assignDate: null,
        surrenderDate: null,
        assetDescription: "",
        purchaseDate: "",
        warranty: "",
        warrantyExpiryDate: "",
      },
    ],
  });

  useEffect(() => {
    if (Data) {
      var dateStr = Data?.employeeDob ? Data?.employeeDob : "";
      var DobDateObj = new Date(dateStr);
      var datejoin = Data?.joiningDate ? Data?.joiningDate : "";
      var DojDateObj = new Date(datejoin);
      var dateEnd = Data?.lastWorkingDate ? Data?.lastWorkingDate : "";
      var DoeDateObj = new Date(dateEnd);

      setStartValues({
        ...Data,
        employeeDob: DobDateObj,
        joiningDate: DojDateObj,
        lastWorkingDate: DoeDateObj,
      });
    }
  }, [Data]);

  const [isPersonalInfoValid, setIsPersonalInfoValid] = useState(false);
  const [isBankInfoValid, setIsBankInfoValid] = useState(false);
  const [isProfessionalInfoValid, setIsProfessionalInfoValid] = useState(false);
  const [isAssetValid, setIsAssetValid] = useState(false);
  const [isDocumentValid, setIsDocumentValid] = useState(false);


  const handleSubmitInPersonalInfo = (values, isValid) => {
    setStartValues(values);
    setIsPersonalInfoValid(isValid);
    if (isValid) {
      setActiveTab("BankInfo");
    }
  };

  const handleSubmitInBankInfo = (values, isValid) => {
    setStartValues(values);
    setIsBankInfoValid(isValid);
    if (isValid) {
      setActiveTab("ProfessionalInfo");
    }
  };
  const handleSubmitInProfessional = (values, isValid) => {
    setStartValues(values);
    setIsProfessionalInfoValid(isValid);
    if (isValid) {
      setActiveTab("Asset");
    }
  };
  const handleSubmitInAsset = (values, isValid) => {
    setStartValues(values);
    setIsAssetValid(isValid);
    if (isValid) {
      setActiveTab("Documents");
    }
  };
  const handleSubmitInDocuments = (values, isValid) => {
    setStartValues(values);
    setIsDocumentValid(isValid);
    if (isValid) {
      setActiveTab("OtherInfo");
    }
  };
  return (
    <div className="mb-0 pb-0">
      <div
        className="employee-list-container sticky-header mb-0 "
        ref={containerRef}
      >
        <div className="employee-list-banner  p-0">
          <div
            className="employee-list-table-holder ps-3 pe-2 pt-2 mt-0"
            style={{
              borderBottom: "0",
              borderRadius: "10px 10px 0px 0px",
              backgroundColor: "white",
              padding: "0",
            }}
          >
            <div className="AllEmployee-navibar-container w-100 mt-0 p-0">
              <nav className="navbar navbar-expand-md navbar-light m-0 p-0">
                <div className="container-fluid g-0">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setNavbarCollapsed(!isNavbarCollapsed)}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className={`collapse navbar-collapse ${
                      isNavbarCollapsed ? "" : " show"
                    }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="add-employee-nav gap-md-3 border-bottom w-100 ps-0 d-md-flex flex-wrap">
                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "Personalinfo" &&
                          "add-NewEmployee-navbar-text-active"
                        }`}
                        // onClick={() => {
                        //   setActiveTab("Personalinfo");
                        // }}
                        onClick={() => {
                          if (type === "View" || type === "Edit") {
                            setActiveTab("Personalinfo");
                          }
                        }}
                      >
                        Personal Info
                      </span>
                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "BankInfo" &&
                          "add-NewEmployee-navbar-text-active"
                        } ${
                          !isPersonalInfoValid &&
                          activeTab === "BankInfo" &&
                          "disabled-tab"
                        }`}
                        // onClick={() => {
                        //   setActiveTab("BankInfo");
                        // }}
                        onClick={() => {
                          if (type === "View" || type === "Edit") {
                            setActiveTab("BankInfo");
                          }
                        }}
                      >
                        National ID & Bank Info
                      </span>
                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "ProfessionalInfo" &&
                          "add-NewEmployee-navbar-text-active"
                        } ${
                          !isBankInfoValid &&
                          activeTab === "ProfessionalInfo" &&
                          "disabled-tab"
                        }`}
                        // onClick={() => {
                        //   setActiveTab("ProfessionalInfo");
                        // }}
                        onClick={() => {
                          if (type === "View" || type === "Edit") {
                            setActiveTab("ProfessionalInfo");
                          }
                        }}
                      >
                        Professional Information
                      </span>
                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "Asset" &&
                          "add-NewEmployee-navbar-text-active"
                        } ${
                          !isProfessionalInfoValid &&
                          activeTab === "Asset" &&
                          "disabled-tab"
                        }`}
                        // onClick={() => {
                        //   setActiveTab("Asset");
                        // }}
                        onClick={() => {
                          if (type === "View" || type === "Edit") {
                            setActiveTab("Asset");
                          }
                        }}
                      >
                        Asset
                      </span>
                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "Documents" &&
                          "add-NewEmployee-navbar-text-active"
                        } ${
                          !isAssetValid &&
                          activeTab === "Documents" &&
                          "disabled-tab"
                        }`}
                        // onClick={() => {
                        //   setActiveTab("Documents");
                        // }}
                        onClick={() => {
                          if (type === "View" || type === "Edit") {
                            setActiveTab("Documents");
                          }
                        }}
                      >
                        Documents
                      </span>
                      <span
                        className={`add-NewEmployee-navbar-text ${
                          activeTab === "OtherInfo" &&
                          "add-NewEmployee-navbar-text-active"
                        } ${
                          !isAssetValid &&
                          activeTab === "OtherInfo" &&
                          "disabled-tab"
                        }`}
                        // onClick={() => {
                        //   setActiveTab("OtherInfo");
                        // }}
                        onClick={() => {
                          if (type === "View" || type === "Edit") {
                            setActiveTab("OtherInfo");
                          }
                        }}
                      >
                        Other Info
                      </span>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="faculty-all-student-navbar-inner-container">
        {activeTab === "Personalinfo" && (
          <>
            <PersonalInfo
              onSubmit={handleSubmitInPersonalInfo}
              values={startValues}
              type={type}
            />
          </>
        )}
        {activeTab === "BankInfo" && (
          <>
            <AllEmployeeBankInfo
              onSubmit={handleSubmitInBankInfo}
              values={startValues}
              type={type}
              setActiveTab={setActiveTab}
            />
          </>
        )}
        {activeTab === "ProfessionalInfo" && (
          <>
            <ProfessionalInfo
              onSubmit={handleSubmitInProfessional}
              values={startValues}
              type={type}
              setActiveTab={setActiveTab}
            />
          </>
        )}
        {activeTab === "Asset" && (
          <>
            <AllEmployeeAsset
              onSubmit={handleSubmitInAsset}
              values={startValues}
              type={type}
              setActiveTab={setActiveTab}
            />
          </>
        )}
        {activeTab === "Documents" && (
          <>
            <EmployeesDocuments
              onSubmit={handleSubmitInDocuments}
              values={startValues}
              type={type}
              setActiveTab={setActiveTab}
            />
          </>
        )}
         {activeTab === "OtherInfo" && (
          <>
            <AllEmployeeOtherInfo
              values={startValues}
              type={type}
              setActiveTab={setActiveTab}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AllEmployeeNavbar;
