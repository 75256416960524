import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Trash from "../../../../assets/images-svg/Trash";
import { axiosService } from "../../../../services/axios.service";
import Deletemodal from "../../../holidays/components/holidays-list/Deletemodal";
import "../../components/all-look-up-details/AllLookUpDetails.css";
import { LOOKUP_URL } from "../../constants/all-look-up-urls";
import "./LookUpProjectTab.css";
function LookUpAssetTab() {
  const [assetType, setAssetType] = useState([]);
  const [assetValue, setAssetValue] = useState("");
  const [assetData, setAssetData] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const openDeleteModal = (assetTypeMasterId) => {
    setSelectedAssetId(assetTypeMasterId);
    setDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setDeleteModalOpen(false);
  };
  const onSubmit = async () => {
    try {
      const requestData = {
        ...(assetValue && { assetName: assetValue }),
      };
      const url = LOOKUP_URL.ADD_ASSET;
      const response = await axiosService.post(url, requestData);
      const { data } = response?.data;
      setAssetData(data);
      setAssetValue("");
      toast.success("Asset Type successfully added");
    } catch (error) {
      if (error.response && error.response.status === 500) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage.split(":")[1]);
      } else {
        console.error("Error:", error);
        toast.error("An error occurred while processing your request.");
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await axiosService.get(LOOKUP_URL.GET_ASSET);
      const responseData = response?.data?.data;
      const assets = responseData.filter(
        (asset) => asset.assetName && asset.assetName.trim() !== ""
      );
      const assetTypes = assets.map((asset) => ({
        assetTypeMasterId: asset.assetTypeMasterId,
        assetName: asset.assetName,
      }));

      setAssetType(assetTypes.reverse());
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [assetData]);

  return (
    <div className="look-up-project-tab-holder">
      <div className="look-up-project-tab d-flex flex-wrap">
        <span className="p-float-label ">
          <InputText
            id="name"
            value={assetValue}
            onChange={(e) => setAssetValue(e.target.value)}
          />
          <label htmlFor="name">Enter Asset Type</label>
        </span>
        <div className="ms-auto" style={{ marginRight: "25px" }}>
          <button
            className="add-button"
            style={{ opacity: assetValue === "" ? "0.5" : "1" }}
            onClick={onSubmit}
            disabled={assetValue === ""}
          >
            Add
          </button>
        </div>
      </div>
      <div className="lookup-list-table-container">
        <table className="lookup-list-table">
          <thead>
            <tr>
              <th scope="col" style={{ width: "20%" }}>
                S.No
              </th>
              <th scope="col" style={{ width: "60%" }}>
                Asset Type
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {assetType.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item?.assetName}</td>
                <td>
                  <div className="action d-inline-flex gap-1">
                    <button onClick={() => openDeleteModal(item?.assetTypeMasterId)}>
                      <Trash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Deletemodal
        modalOpen={deleteModalOpen}
        closeModal={handleCloseModal}
        cancelButton={true}
        submitButton={true}
        onCloseTitle="Close"
        assetData={selectedAssetId}
        fetchData={fetchData}
        title="Are you sure"
        data="You want to delete?"
      />
    </div>
  );
}

export default LookUpAssetTab;
