

function SearchIcon(){
    return(
        <div>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 10.25C1.5 15.0825 5.41751 19 10.25 19C15.0825 19 19 15.0825 19 10.25C19 5.41751 15.0825 1.5 10.25 1.5C5.41751 1.5 1.5 5.41751 1.5 10.25ZM10.25 20.5C4.58908 20.5 0 15.9109 0 10.25C0 4.58908 4.58908 0 10.25 0C15.9109 0 20.5 4.58908 20.5 10.25C20.5 12.8105 19.5611 15.1517 18.0089 16.9482L21.2803 20.2197C21.5732 20.5126 21.5732 20.9874 21.2803 21.2803C20.9874 21.5732 20.5126 21.5732 20.2197 21.2803L16.9482 18.0089C15.1517 19.5611 12.8105 20.5 10.25 20.5Z" fill="black"/>
</svg>

        </div>
    )
}

export default SearchIcon;