import { City, Country, State } from "country-state-city";
import { Field, Form, Formik } from "formik";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import AllEmployeeBankInfo from "../all-employee-bankInfo/AllEmployeeBankInfo";
import "../all-employee-profile/PersonalInfo.css";
import "../all-employees-list/AllEmployeesList.css";
import CancelModal from "../cancel-modal/CancelModal";
import { axiosService } from "../../../../services/axios.service";
import { ALL_EMPLOYEES_URL } from "../../constants/all-employees-urls";
import { toast } from "react-toastify";

function PersonalInfo({ onSubmit, type, values }) {
  console.log(type)
  const isFilled = false;
  const containerRef = useRef();
  const [fieldsAreReadOnly, setFieldsAreReadOnly] = useState(false);
  const [isCountryFocused, setIsCountryFocused] = useState(false);
  const [isStateFocused, setIsStateFocused] = useState(false);
  const [isCityFocused, setIsCityFocused] = useState(false);
  const [selectedGender, setSelectedGender] = useState(
    values?.gender ? values?.gender : ""
  );
  const [isGenderFocused, setIsGenderFocused] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const activeTab = "Personalinfo";

  useEffect(() => {
    if (type === "View") {
      setFieldsAreReadOnly(true);
    }
  }, [values, type]);

  const handleCountryFocused = () => {
    setIsCountryFocused(true);
  };

  const handleStateFocus = () => {
    setIsStateFocused(true);
  };

  const handleStateBlur = () => {
    setIsStateFocused(false);
  };

  const handleCityFocus = () => {
    setIsCityFocused(true);
  };
  const handleGenderFocus = () => {
    setIsGenderFocused(true);
  };

  const handleCityBlur = () => {
    setIsCityFocused(false);
  };

  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "42px",
      boxShadow: state.isFocused ? "none" : "none",
      backgroundColor: state.isFocused ? "#ffff" : "rgba(0 0 0 0.05)",
      borderRadius: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: '"Lexend", sans-serif',
      fontSize: "16px",
      marginTop: "0",
      backgroundColor: state.isFocused ? "#3FC28A" : "#ffffff",
      color: "black",
      "&:hover": {
        backgroundColor: "#3FC28A",
        color: "#ffffff",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      fontFamily: '"Lexend", sans-serif',
      fontSize: "16px",
      fontWeight: 400,
    }),
    menu: (provided) => ({
      ...provided,
    }),
  };

  const handleNumericInputChange = (fieldName, e, setFieldValue, maxLength) => {
    const value = e.target.value;
    let numericValue = value.replace(/\D/g, "");
    if (maxLength) {
      numericValue = numericValue.slice(0, maxLength);
    }
    setFieldValue(fieldName, numericValue);
  };

  const [errors, setErrors] = useState({});

  const validateFields = (values) => {
    const errors = {};
    if (!values.employeeFirstName?.trim()) {
      errors.employeeFirstName = "Enter First Name";
    }
    if (!values.employeeLastName?.trim()) {
      errors.employeeLastName = "Enter Last Name";
    }
    if (!values.mobileNumber) {
      errors.mobileNumber = "Enter Mobile Number";
    } else if (
      values.mobileNumber.length > 0 &&
      values.mobileNumber.length !== 10
    ) {
      errors.mobileNumber = "Invalid Mobile Number";
    }
    if (!values.emergencyContactNo) {
      errors.emergencyContactNo = "Enter Emergency contact number";
    } else if (
      values.emergencyContactNo.length > 0 &&
      values.emergencyContactNo.length !== 10
    ) {
      errors.emergencyContactNo = "Invalid Emergency contact number";
    }
    if (!values.pinCode) {
      errors.pinCode = "Enter PIN code";
    } else if (values.pinCode.length > 0 && values.pinCode.length !== 6) {
      errors.pinCode = "Invalid PIN code";
    }
    if (!values.city) {
      errors.city = "Select City";
    }
    if (!values.state) {
      errors.state = "Select State";
    }
    if (!values.nationality) {
      errors.nationality = "Select Nationality";
    }
    if (!values.employeeDob) {
      errors.employeeDob = "Select Date of Birth";
    }
    if (!values.bloodGroup) {
      errors.bloodGroup = "Enter Blood Group";
    }
    if (!values.gender) {
      errors.gender = "Select Gender";
    }
    if (!values.address) {
      errors.address = "Enter Your address";
    }
    if (!values.relationship) {
      errors.relationship = "Enter relationship";
    }
    if (!values.contactName) {
      errors.contactName = "Enter Emergency contact name";
    }
    if (!values.email.trim()) {
      errors.email = "Enter Email";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(
        values.email.trim()
      )
    ) {
      errors.email = "Invalid Email";
    }
    console.log(errors, "errrrrr");
    return errors;
  };

  const handleSubmit = (values) => {
    const validationErrors = validateFields(values);
    if (type === "View") {
      onSubmit(values, true);
    } else if (type === "Edit") {
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        OnEditSubmit(values, true);
      }
    } else {
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        onSubmit(values, true);
      }
    }
  };
  

  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    return isoDateString;
  }
  const OnEditSubmit = async (values) => {
    let mergedValues;
    try {
      mergedValues = {
        ...values,
        supervisor: { empId: values?.supervisor?.empId },
        employeeDob: convertDateFormat(values?.employeeDob),
        joiningDate: convertDateFormat(values?.joiningDate),
        lastWorkingDate: convertDateFormat(values?.lastWorkingDate),
      };
      const response = await axiosService.put(
        `${ALL_EMPLOYEES_URL.UPDATE_EMPLOYEE_DATA}`,
        mergedValues
      );

      if (response.status === 200) {
        navigate("/all-employees/employees-list");
        toast.success("Updated successfully");
      }
    } catch (error) {
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(error?.response?.data?.message?.split(":")[1]);
      }
    }
  };
 
  const [selectedCountry, setSelectedCountry] = useState(
    values?.nationality ? values?.nationality : ""
  );
  const [selectedState, setSelectedState] = useState(
    values?.state ? values?.state : ""
  );
  const [selectedCity, setSelectedCity] = useState(
    values?.city ? values?.city : ""
  );

  const [cities, setCities] = useState([]);

  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const countryOptions = allCountries.map((country) => ({
          label: country.name,
          value: country.name,
        }));
        setCountries(countryOptions);
      } catch {}
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const allCountries = Country.getAllCountries();
        const selectedCountryInfo = allCountries.find(
          (country) => country.name === selectedCountry
        );
        if (selectedCountryInfo) {
          const allStates = State.getAllStates();
          const countryStates = allStates.filter(
            (state) => state.countryCode === selectedCountryInfo.isoCode
          );
          const StateOptions = countryStates.map((state) => ({
            label: state.name,
            value: state.name,
          }));
          setStates(StateOptions);

          if (values?.state) {
            setSelectedState(values?.state);
          }
        }
      } catch (error) {}
    };

    fetchStates();
  }, [selectedCountry, values?.state]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const allStates = State.getAllStates();
        const selectedStateInfo = allStates.find(
          (state) => state.name === selectedState
        );

        if (selectedStateInfo) {
          const allCities = City.getAllCities();
          const stateCities = allCities.filter(
            (city) => city.stateCode === selectedStateInfo.isoCode
          );

          const cityOptions = stateCities.map((city) => ({
            label: city.name,
            value: city.name,
          }));

          setCities(cityOptions);

          if (values?.city) {
            setSelectedCity(values?.city);
          }
        }
      } catch (error) {}
    };

    fetchCities();
  }, [selectedState, values?.city]);

  return (
    <div className=" employee-list-container-personal " ref={containerRef}>
      <div className="employee-list-banner mt-0">
        <div
          className="employee-list-table-holder pt-0 mt-0"
          style={{
            marginTop: "0",
            borderTop: "0",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <div className="Employee-profile-container w-100 ">
            <div className="addEmployee-details w-100">
              <Formik
                onSubmit={handleSubmit}
                initialValues={values}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className="row add-emp-row-1">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="employeeFirstName"
                            type="text"
                            
                            readOnly={fieldsAreReadOnly}
                            value={values.employeeFirstName}
                            style={{
                              backgroundColor: values.employeeFirstName
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.employeeFirstName
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            First Name
                          </label>
                          {errors.employeeFirstName &&
                            !values.employeeFirstName && (
                              <span className="error-message-text">
                                {errors.employeeFirstName}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="employeeLastName"
                            type="text"
                            
                            readOnly={fieldsAreReadOnly}
                            style={{
                              backgroundColor: values.employeeLastName
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.employeeLastName
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Last Name
                          </label>
                          {errors.employeeLastName &&
                            !values.employeeLastName && (
                              <span className="error-message-text">
                                {errors.employeeLastName}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text "
                            name="mobileNumber"
                            readOnly={fieldsAreReadOnly}
                            onChange={(e) =>
                              handleNumericInputChange(
                                "mobileNumber",
                                e,
                                setFieldValue
                              )
                            }
                            style={{
                              backgroundColor: values.mobileNumber
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.mobileNumber
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                            
                            maxLength={10}
                          />
                          <label className="add-Employee-label">
                            Mobile Number
                          </label>
                          {errors.mobileNumber &&
                            values.mobileNumber.length < 10 && (
                              <span className="error-message-text">
                                {errors.mobileNumber}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 add-box-personal">
                        <div className="floating-label-datepicker">
                          <span
                            className="p-float-label-personal"
                            style={{
                              position: "relative",
                            }}
                          >
                            <Calendar
                              inputId="employeeDob"
                              name="employeeDob"
                              value={values.employeeDob}
                              onChange={(e) =>
                                setFieldValue("employeeDob", e.value)
                              }
                              appendTo={document.body}
                              className={
                                "custom-calendar" +
                                (isFilled ? " filled" : "") +
                                (values.employeeDob ? " filled-bg" : "")
                              }
                              showIcon
                              disabled={fieldsAreReadOnly}
                              readOnlyInput
                              style={{
                                color: "#000",
                                width: "100%",
                                zIndex: "0",
                              }}
                            />

                            <label
                              className="date-picker-label"
                              htmlFor="employeeDob"
                            >
                              Date of Birth
                            </label>
                            {errors.employeeDob && !values.employeeDob && (
                              <span
                                className="error-message-text"
                                style={{ marginTop: "-15px" }}
                              >
                                {errors.employeeDob}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="bloodGroup"
                            type="text"
                            
                            readOnly={fieldsAreReadOnly}
                            style={{
                              backgroundColor: values.bloodGroup
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.bloodGroup
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Blood Group
                          </label>
                          {errors.bloodGroup && !values.bloodGroup && (
                            <span className="error-message-text">
                              {errors.bloodGroup}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-12 add-box-personal">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <div
                            className={`select-container mt-0 ${
                              isGenderFocused || selectedGender || values.gender
                                ? "active"
                                : ""
                            }`}
                            style={{
                              backgroundColor:
                                values.gender ||
                                isGenderFocused ||
                                selectedGender
                                  ? "white"
                                  : "rgba(0,0,0,0.05)",
                              border:
                                values.gender ||
                                isGenderFocused ||
                                selectedGender
                                  ? "1px solid rgba(0, 0, 0, 0.5) "
                                  : "none",
                            }}
                          >
                            <label htmlFor="gender" className="floating-label">
                              Gender
                            </label>
                            <Field name="gender">
                              {({ field }) => (
                                <Select
                                  name="gender"
                                  {...field}
                                  options={genderOptions}
                                  styles={selectStyles}
                                  classNamePrefix="atnd_gender"
                                  value={genderOptions.find(
                                    (option) => option.value === field.value
                                  )}
                                  onChange={(selectedOption) => {
                                    if (
                                      selectedOption &&
                                      selectedOption.value
                                    ) {
                                      setFieldValue(
                                        "gender",
                                        selectedOption.value
                                      );
                                      setSelectedGender(selectedOption);
                                    }
                                  }}
                                  onFocus={handleGenderFocus}
                                  isDisabled={fieldsAreReadOnly}
                                  
                                />
                              )}
                            </Field>
                          </div>
                          {errors.gender && !values.gender && (
                            <span className="error-message-text">
                              {errors.gender}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="email"
                            type="text"
                            
                            readOnly={fieldsAreReadOnly}
                            style={{
                              backgroundColor: values.email
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.email
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Personal Email Address
                          </label>
                          {errors.email &&
                            !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(
                              values.email.trim()
                            ) && (
                              <span className="error-message-text">
                                {errors.email}
                              </span>
                            )}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12 add-box-personal">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <div
                            className={`select-container mt-0 ${
                              isCountryFocused ||
                              selectedCountry ||
                              values.nationality
                                ? "active"
                                : ""
                            }`}
                            style={{
                              backgroundColor:
                                values.nationality ||
                                isCountryFocused ||
                                selectedCountry
                                  ? "white"
                                  : "rgba(0,0,0,0.05)",
                              border:
                                values.nationality ||
                                isCountryFocused ||
                                selectedCountry
                                  ? "1px solid rgba(0, 0, 0, 0.5) "
                                  : "none",
                            }}
                          >
                            <label
                              htmlFor="nationality"
                              className="floating-label"
                            >
                              Nationality
                            </label>
                            <Field name="nationality">
                              {({ field, form }) => (
                                <Select
                                  name="nationality"
                                  {...field}
                                  options={countries}
                                  styles={selectStyles}
                                  classNamePrefix="atnd_nationality"
                                  value={{
                                    label: field.value,
                                    value: field.value,
                                  }}
                                  onChange={(selectedOption) => {
                                    form.setFieldValue(
                                      field.name,
                                      selectedOption.value
                                    );
                                    form.setFieldValue("state", "");
                                    form.setFieldValue("city", "");
                                    setSelectedCountry(selectedOption.value);
                                  }}
                                  onFocus={handleCountryFocused}
                                  
                                  isDisabled={fieldsAreReadOnly}
                                />
                              )}
                            </Field>
                          </div>
                          {errors.nationality && !values.nationality && (
                            <span className="error-message-text">
                              {errors.nationality}
                            </span>
                          )}
                        </div>
                      </div>
                      <div
                        className="col-lg-8 col-md-6 col-sm-12"
                        style={{ marginTop: "-8px" }}
                      >
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100 mt-2">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="address"
                            type="text"
                            
                            readOnly={fieldsAreReadOnly}
                            style={{
                              backgroundColor: values.address
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.address
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">Address</label>
                          {errors.address && !values.address && (
                            <span className="error-message-text">
                              {errors.address}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="pinCode"
                            type="text"
                            
                            readOnly={fieldsAreReadOnly}
                            maxLength={6}
                            onChange={(e) =>
                              handleNumericInputChange(
                                "pinCode",
                                e,
                                setFieldValue
                              )
                            }
                            style={{
                              backgroundColor: values.pinCode
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.pinCode
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">ZIP Code</label>
                          {errors.pinCode && values.pinCode.length < 6 && (
                            <span className="error-message-text">
                              {errors.pinCode}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 add-box-personal">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <div
                            className={`select-container mt-0 ${
                              isStateFocused || selectedState || values.state
                                ? "active"
                                : ""
                            }`}
                            style={{
                              backgroundColor:
                                values.state || isStateFocused || selectedState
                                  ? "white"
                                  : "rgba(0,0,0,0.05)",
                              border:
                                values.state || isStateFocused || selectedState
                                  ? "1px solid rgba(0, 0, 0, 0.5) "
                                  : "none",
                            }}
                          >
                            <label htmlFor="state" className="floating-label">
                              State
                            </label>
                            <Field name="state">
                              {({ field, form }) => (
                                <Select
                                  name="state"
                                  {...field}
                                  options={states}
                                  styles={selectStyles}
                                  classNamePrefix="atnd_state"
                                  value={{
                                    label: field.value,
                                    value: field.value,
                                  }}
                                  onChange={(selectedOption) => {
                                    form.setFieldValue(
                                      field.name,
                                      selectedOption.value
                                    );
                                    form.setFieldValue("city", "");
                                    setSelectedState(selectedOption.value);
                                  }}
                                  onFocus={handleStateFocus}
                                  onBlur={handleStateBlur}
                                  
                                  isDisabled={fieldsAreReadOnly}
                                />
                              )}
                            </Field>
                          </div>
                          {errors.state && !values.state && (
                            <span className="error-message-text">
                              {errors.state}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 add-box-personal">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <div
                            className={`select-container mt-0 ${
                              isCityFocused || selectedCity || values.city
                                ? "active"
                                : ""
                            }`}
                            style={{
                              backgroundColor:
                                values.city || isCityFocused || selectedCity
                                  ? "white"
                                  : "rgba(0,0,0,0.05)",
                              border:
                                values.city || isCityFocused || selectedCity
                                  ? "1px solid rgba(0, 0, 0, 0.5) "
                                  : "none",
                            }}
                          >
                            <label htmlFor="city" className="floating-label">
                              City
                            </label>
                            <Field name="city">
                              {({ field, form }) => (
                                <Select
                                  name="city"
                                  {...field}
                                  options={cities}
                                  styles={selectStyles}
                                  classNamePrefix="atnd_city"
                                  value={{
                                    label: field.value,
                                    value: field.value,
                                  }}
                                  onChange={(selectedOption) => {
                                    form.setFieldValue(
                                      field.name,
                                      selectedOption.value
                                    );

                                    setSelectedCity(selectedOption.value);
                                  }}
                                  onFocus={handleCityFocus}
                                  onBlur={handleCityBlur}
                                  
                                  isDisabled={fieldsAreReadOnly}
                                />
                              )}
                            </Field>
                          </div>
                          {errors.city && !values.city && (
                            <span className="error-message-text">
                              {errors.city}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6  col-sm-12">
                        <div
                          className="add-Employee-form-item add-Employee-form-item--text w-100"
                          style={{ margin: "0px" }}
                        >
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text "
                            name="contactName"
                            type="text"
                            
                            readOnly={fieldsAreReadOnly}
                            style={{
                              backgroundColor: values.contactName
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.contactName
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Emergency Contact Name
                          </label>
                          {errors.contactName && !values.contactName && (
                            <span className="error-message-text">
                              {errors.contactName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6  col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="relationship"
                            type="text"
                            
                            readOnly={fieldsAreReadOnly}
                            style={{
                              backgroundColor: values.relationship
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.relationship
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="add-Employee-label">
                            Relationship
                          </label>
                          {errors.relationship && !values.relationship && (
                            <span className="error-message-text">
                              {errors.relationship}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6  col-sm-12">
                        <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                          <Field
                            id=""
                            className="add-Employee-input add-Employee-input--text"
                            name="emergencyContactNo"
                            maxLength={10}
                            onChange={(e) =>
                              handleNumericInputChange(
                                "emergencyContactNo",
                                e,
                                setFieldValue
                              )
                            }
                            style={{
                              backgroundColor: values.emergencyContactNo
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: values.emergencyContactNo
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                            
                            readOnly={fieldsAreReadOnly}
                          />
                          <label className="add-Employee-label">
                            Emergency Contact Number
                          </label>
                          {errors.emergencyContactNo &&
                            values.emergencyContactNo.length < 10 && (
                              <span className="error-message-text">
                                {errors.emergencyContactNo}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="add-Employeepage-buttons">
                      {type !== "View" && (
                        <button
                          className="Cancel-btn "
                          type="button"
                          onClick={() => setModalOpen(true)}
                        >
                          Cancel
                        </button>
                      )}
                      {type === "View" && (
                        <button
                          className="Cancel-btn"
                          type="button"
                          onClick={() =>
                            navigate("/all-employees/employees-list")
                          }
                        >
                          Cancel
                        </button>
                      )}
                      {modalOpen && (
                        <CancelModal
                          modalOpen={modalOpen}
                          closeModal={() => setModalOpen(false)}
                          onSubmit={() =>
                            navigate("/all-employees/employees-list")
                          }
                          cancelButton={true}
                          submitButton={true}
                          onCloseTitle={"Cancel"}
                        />
                      )}
                      {type === "Add" && (
                        <button className="add-button-next" type="submit">
                          Next
                        </button>
                      )}
                        {type === "Edit" && (
                        <button className="add-button-next" type="submit" >
                          Update
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div>
        {activeTab === "BankInfo" && (
          <>
           <AllEmployeeBankInfo />
           </>
           
        )}
      </div>
    </div>
  );
}
export default PersonalInfo;