import { AppConfig } from "../../../env.development";

export const LOOKUP_URL = {
  ADD_PROJECT: `${AppConfig.api_url}work-type/v1`,
  GET_PROJECT: `${AppConfig.api_url}work-type/v1`,
  DELETE_PROJECT: `${AppConfig.api_url}work-type/v1`,
  ADD_ASSET: `${AppConfig.api_url}asset-master/v1`,
  GET_ASSET: `${AppConfig.api_url}asset-master/v1`,
  DELETE_ASSET: `${AppConfig.api_url}asset-master/v1`,
  ADD_LEAVES: `${AppConfig.api_url}master-leave/v1`,
  GET_LEAVES: `${AppConfig.api_url}master-leave/v1`,
  UPDATE_LEAVES: `${AppConfig.api_url}master-leave/v1`,
  POST_PRESENCE_DETAILS: `${AppConfig.api_url}master-user/v1`,
};
