import React from "react";

function Delete() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 7.49984L15.2367 15.2555C15.1032 16.9921 13.655 18.3332 11.9132 18.3332H8.08677C6.34498 18.3332 4.89684 16.9921 4.76326 15.2555L4.16667 7.49984M17.5 5.83317C15.3351 4.77815 12.7614 4.1665 10 4.1665C7.23862 4.1665 4.66493 4.77815 2.5 5.83317M8.33333 4.1665V3.33317C8.33333 2.4127 9.07953 1.6665 10 1.6665C10.9205 1.6665 11.6667 2.4127 11.6667 3.33317V4.1665M8.33333 9.1665V14.1665M11.6667 9.1665V14.1665"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default Delete;
