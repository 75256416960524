import React from "react";

function Eye() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6086 8.21075C18.5749 9.22748 18.5749 10.7722 17.6086 11.7889C15.9788 13.5037 13.1796 15.8332 10 15.8332C6.82042 15.8332 4.02126 13.5037 2.39144 11.7889C1.4251 10.7722 1.4251 9.22748 2.39144 8.21075C4.02126 6.49595 6.82042 4.1665 10 4.1665C13.1796 4.1665 15.9788 6.49595 17.6086 8.21075Z"
        stroke="black"
        stroke-width="1.5"
      />
      <circle cx="10" cy="10" r="2.5" stroke="black" stroke-width="1.5" />
    </svg>
  );
}

export default Eye;
