import React from "react";
import Modal from "react-modal";
import { useLocation } from "react-router";
import Warning from "../../../../assets/images-png/Warning.png";
import "./CancelModal.css";
function CancelModal({
  modalOpen,
  closeModal,
  cancelButton,
  submitButton,
  onSubmit,
  onCloseTitle,
}) {

  const location = useLocation();
  const type = location?.state?.type;
  return (
    <div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        className="Cancel-modal p-2"
      >
        <div>
          <div className="exclamation-icon mt-2 mb-3">
           <img src={Warning} alt="Warning" />
          </div>
          <div className="sure-text mb-3">Are you sure ?</div>
          <div className="cancelunsaved-text mb-3">
          You want to cancel
          </div>
          {type === "Edit" && (
            <p>Any unsaved data will be lost.</p>
          )}
        </div>

        <div className=" d-flex justify-content-center mb-3">
          {cancelButton && (
            <button className="Cancel-btn  " onClick={closeModal}>
              {onCloseTitle}
            </button>
          )}
          {submitButton && (
            <button className="modal-confirm-button" onClick={onSubmit}>
              Confirm
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default CancelModal;
