import { Pagination } from "flowbite-react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useLocation } from "react-router";
import Dropdowns from "../../../../assets/images-svg/Drop_down";
import Export from "../../../../assets/images-svg/Export";
import Filter from "../../../../assets/images-svg/Filter";
import LeftArrow from "../../../../assets/images-svg/LeftArrow";
import RightArrow from "../../../../assets/images-svg/RightArrow";
import { axiosService } from "../../../../services/axios.service";
import sessionService from "../../../../services/sessionstorage.service";
import { REPORTEES_URL } from "../../../reportees/constants/reportees-urls";
import { TIMESHEET_URL } from "../../../timesheet/constants/timesheet-url";
import "./ReporteesTimeSheet.css";
const ReporteesTimeSheet = () => {
  const User = sessionService.getUserData("userData");
  const empId = User?.employee?.empId;
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const role = userData?.role;
  const [timesheetData, setTimesheetData] = useState([]);
  const [filterSelections, setFilterSelections] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [singleSelections, setSingleSelections] = useState(null);
  const [data, setData] = useState([]);
  const onPageChange = (page) => setCurrentPage(page);
  const [dates, setDates] = useState(null);

  const cardsPerPageOptions = [10, 15, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(cardsPerPageOptions[0]);
  const [totalElements, setTotalElements] = useState(0);

  const displayedCards = data;
  const handleDateChange = (e) => {
    const selectedDates = e.value;
    if (selectedDates.length === 1) {
      const endDate = new Date(selectedDates[0]);
      endDate.setDate(endDate.getDate() + 1);
      setDates([selectedDates[0], endDate]);
    } else {
      setDates(selectedDates);
    }
  };

  function formatDate(date) {
    if (date === null) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${REPORTEES_URL.GET_REPORTEES_NAMES}${empId}`;
        const response = await axiosService.get(url);
        const data = response?.data?.data;
        if (data) {
          const employee = data.map((item) => {
            return {
              name: item.employeeFirstName + " " + item.employeeLastName,
              empId: item?.employeeId,
            };
          });
          setAttendanceData(employee);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };
    if (role === "USER") {
      fetchData();
    }
  }, [empId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = REPORTEES_URL.ADMIN_REPORTEES_NAMES;
        const response = await axiosService.get(url);
        const data = response?.data?.data;

        if (data) {
          const employee = data.map((item) => {
            return {
              name: item.employeeFirstName + " " + item.employeeLastName,
              empId: item?.employeeId,
            };
          });
          setAttendanceData(employee);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "ADMIN") {
      fetchData();
    }
  }, [recordsPerPage, currentPage]);
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${REPORTEES_URL.GET_REPORTEES_TIMESHEET}${empId}`;
        const params = {
          pageNumber: currentPage,
          itemsPerPage: recordsPerPage,
          projectName: filterSelections ? filterSelections : null,
        };

        if (singleSelections) {
          params.employeeName = singleSelections;
        }

        if (dates !== null && dates.length === 2) {
          params.startDate = formatDate(dates[0]);
          params.endDate = formatDate(dates[1]);
        }
        const response = await axiosService.get(url, params);
        const data = response?.data?.data?.data;
        const totalElements = response?.data?.data?.totalElements;

        setData(data);
        setTotalElements(totalElements);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "USER") {
      fetchData();
    }
  }, [
    location.pathname,
    dates,
    filterSelections,
    empId,
    currentPage,
    recordsPerPage,
    singleSelections,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = REPORTEES_URL.ADMIN_REPORTEES_TIMESHEET;
        const params = {
          pageNumber: currentPage,
          itemsPerPage: recordsPerPage,
          projectName: filterSelections ? filterSelections : null,
        };

        if (singleSelections) {
          params.employeeName = singleSelections;
        }
        if (dates !== null && dates.length === 2) {
          params.startDate = formatDate(dates[0]);
          params.endDate = formatDate(dates[1]);
        }

        const response = await axiosService.get(url, params);
        const data = response?.data?.data?.data;
        const totalElements = response?.data?.data?.totalElements;

        setData(data);
        setTotalElements(totalElements);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "ADMIN") {
      fetchData();
    }
  }, [
    location.pathname,
    dates,
    filterSelections,
    empId,
    currentPage,
    recordsPerPage,
    singleSelections,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = TIMESHEET_URL.GET_ALL_TIMESHEET;
        const response = await axiosService.get(url);
        const data = response?.data?.data;
        setTimesheetData(data);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };
    fetchData();
  }, []);

  const handleTimeSelect = (selected) => {
    setFilterSelections(selected);
  };

  const projectOptionSet = new Set(
    timesheetData.map((project) => project.project)
  );

  const projectOption = [...projectOptionSet].map((project) => ({
    label: project,
    value: project,
  }));

  const handleEmployeeSelect = (e) => {
    setSingleSelections(e.value);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const employeeNameoptions = attendanceData.map((a) => ({
    label: a?.name,
    value: a?.empId,
  }));

  const exportData = () => {
    const urlget =
      role === "USER"
        ? `${REPORTEES_URL.GET_REPORTEES_EXPORT_TIMESHEET}${empId}`
        : `${REPORTEES_URL.GET_ADMIN_REPORTEES_EXPORT_TIMESHEET}`;
    const params = {
      projectName: filterSelections,
      employeeName: singleSelections,
    };
    if (dates !== null && dates.length === 2) {
      params.startDate = formatDate(dates[0]);
      params.endDate = formatDate(dates[1]);
    }
    axiosService
      .getFile(urlget, params)
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "exported-timesheet.xlsx";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
        }
      })

      .catch(() => {});
  };

  return (
    <>
      <div className="w-100 reportees-timesheet-list">
        <div className="reportees-timesheet-list-container">
          <div className="reportees-timesheet-list-banner">
            <div className="reportees-container-fluid">
              <div className="reportees-row">
                <div className="col w-100 reportees-timesheet-top-column">
                  <div className="reportees-attendance-list-filter-input-container">
                    <Dropdown
                      id="employee-search"
                      labelKey="employeeFirstName"
                      value={singleSelections}
                      onChange={handleEmployeeSelect}
                      options={employeeNameoptions}
                      placeholder="Employee"
                      filter={true}
                      showClear
                      className=" reportees-custom-dropdown w-full md:w-14rem"
                    />
                  </div>
                  <div className="reportees-attendance-list-filter-input-container">
                    <Dropdown
                      value={filterSelections}
                      onChange={(e) => handleTimeSelect(e.target.value)}
                      options={projectOption}
                      optionLabel="label"
                      showClear
                      placeholder="Project"
                      className="reportees-custom-dropdown w-full md:w-14rem "
                    />
                  </div>
                  <div className="reportees-timesheet-list-date-range">
                    <Calendar
                      id="calendarInput"
                      value={dates}
                      placeholder="Date Range"
                      onChange={handleDateChange}
                      selectionMode="range"
                      readOnlyInput
                      hideOnRangeSelection
                    />
                    <div className="reportees-timesheet-list-date-icon">
                      <Filter />
                    </div>
                  </div>
                  <div className="ms-auto">
                    <button
                      className="reportees-timesheet-export-button button"
                      onClick={exportData}
                    >
                      Export
                      <span className="reportees-timesheet-export-button-icon">
                        <Export />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="reportees-TimesheetScrollContainer">
                  <div className="row reportees-timesheetTableScroll">
                    <div className="reportees-timesheet-list-table-container">
                      <table className="reportees-timesheet-list-table aaaaaaaaaaaaaaa" >
                        <thead>
                          <tr>
                            <th style={{ width: "225px" }}>Employee Id</th>
                            <th style={{ width: "225px" }}>Employee Name</th>
                            <th style={{ width: "205px" }}>Project</th>
                            <th style={{ width: "205px" }}>Task Name</th>
                            <th style={{ width: "205px" }}>Date</th>
                            <th style={{ width: "205px" }}>Duration</th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayedCards.map((e, index) => (
                            <tr key={index}>
                              <td>{e?.employee?.employeeId}</td>
                              <td>
                                {" "}
                                {e?.employee?.employeeFirstName}{" "}
                                {e?.employee?.employeeLastName}
                              </td>
                              <td>{e.project}</td>
                              <td>{e.task}</td>
                              <td>{e.date}</td>
                              <td>{e.duration}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className="row mt-3"
                  style={{ boxShadow: "0px -2px  #F3EEEA" }}
                >
                  <div className="col w-50 d-flex align-items-center reportees-timesheet-record-list">
                    <span>Showing</span>
                    <div style={{ width: "fit-content", position: "relative" }}>
                      <select
                        value={recordsPerPage}
                        onChange={handleRecordsPerPageChange}
                      >
                        {cardsPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="reportees-timesheet-record-list-dropdown-icon">
                        <Dropdowns />
                      </div>
                    </div>
                  </div>
                  <div className="col w-50 reportees-timsheet-pagination-container">
                    {Math.ceil(totalElements / recordsPerPage) > 1 ? (
                      <>
                        {totalElements && (
                          <Pagination
                            className="pagination d-inline-flex"
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                              totalElements / recordsPerPage
                            )}
                            onPageChange={onPageChange}
                            nextLabel={<RightArrow />}
                            previousLabel={<LeftArrow />}
                            activePageLinkStyle={{
                              color: "#ffffff !important",
                            }}
                            icon={<span>{currentPage}</span>}
                          />
                        )}
                      </>
                    ) : (
                      <nav
                        class="pagination d-inline-flex"
                        activepagelinkstyle="[object Object]"
                        icon="[object Object]"
                      >
                        <ul class="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.4685 17.5856C14.7919 17.3269 14.8444 16.8549 14.5856 16.5315L10.9604 12L14.5856 7.46849C14.8444 7.14505 14.7919 6.67308 14.4685 6.41432C14.145 6.15556 13.673 6.208 13.4143 6.53145L9.41429 11.5315C9.19516 11.8054 9.19516 12.1946 9.41429 12.4685L13.4143 17.4685C13.673 17.7919 14.145 17.8444 14.4685 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                          <li aria-current="page">
                            <button
                              type="button"
                              class="w-12 border border-gray-300 py-2 leading-tight enabled:hover:bg-gray-100 enabled:hover:text-gray-700 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                            >
                              1
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReporteesTimeSheet;
