import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { axiosService } from "../../../../services/axios.service";
import { toast } from 'react-toastify';
import { LOOKUP_URL } from "../../constants/all-look-up-urls";
import { InputText } from "primereact/inputtext";

function LookUpLeaveTab() {
  const QuarterEnum = {
    FIRST: 'FIRST_QUARTER',
    SECOND: 'SECOND_QUARTER',
    THIRD: 'THIRD_QUARTER',
    FOURTH: 'FOURTH_QUARTER',
  };

  const [initialValues, setInitialValues] = useState({
    firstPersonalLeave: '',
    secondPersonalLeave: '',
    thirdPersonalLeave: '',
    fourthPersonalLeave: '',
    sickLeaveValue: '',
    privilegeLeaveValue: '',
    permissionLeaveValue: '',
    wfhLeaveValue: '',
    firstQuarterType: QuarterEnum.FIRST,
    secondQuarterType: QuarterEnum.SECOND,
    thirdQuarterType: QuarterEnum.THIRD,
    fourthQuarterType: QuarterEnum.FOURTH,
  });

  const [masterLeaveId, setMasterLeaveId] = useState(null);

  const validationSchema = Yup.object({
    firstPersonalLeave: Yup.number().required('Required'),
    secondPersonalLeave: Yup.number().required('Required'),
    thirdPersonalLeave: Yup.number().required('Required'),
    fourthPersonalLeave: Yup.number().required('Required'),
    sickLeaveValue: Yup.number().required('Required'),
    privilegeLeaveValue: Yup.number().required('Required'),
    permissionLeaveValue: Yup.number().required('Required'),
    wfhLeaveValue: Yup.number().required("Required"),
  });



  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setTouched({
        firstPersonalLeave: true,
        secondPersonalLeave: true,
        thirdPersonalLeave: true,
        fourthPersonalLeave: true,
        sickLeaveValue: true,
        privilegeLeaveValue: true,
        permissionLeaveValue: true,
        wfhLeaveValue: true,
      });
      await handleAddSubmit(values);

    },
  });

  const fetchData = async () => {
    try {
      const response = await axiosService.get(LOOKUP_URL.GET_LEAVES);
      const data = response?.data?.data[0];

      if (data) {
        formik.setValues({
          firstPersonalLeave: data.firstPersonalLeave || '',
          secondPersonalLeave: data.secondPersonalLeave || '',
          thirdPersonalLeave: data.thirdPersonalLeave || '',
          fourthPersonalLeave: data.fourthPersonalLeave || '',
          sickLeaveValue: data.sickLeave || '',
          privilegeLeaveValue: data.privilegeLeave || '',
          permissionLeaveValue: data.permissionLeave || '',
          wfhLeaveValue: data.wfhLeave || '',
          firstQuarterType: QuarterEnum.FIRST,
          secondQuarterType: QuarterEnum.SECOND,
          thirdQuarterType: QuarterEnum.THIRD,
          fourthQuarterType: QuarterEnum.FOURTH,
        });
        setMasterLeaveId(data.masterLeaveId);

        console.log(response, 'Fetched data successfully');
      } else {
        console.error("No data found");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handleAddSubmit = async (values) => {
    const formData = {
      firstQuarterType: values.firstQuarterType,
      secondQuarterType: values.secondQuarterType,
      thirdQuarterType: values.thirdQuarterType,
      fourthQuarterType: values.fourthQuarterType,
      firstPersonalLeave: values.firstPersonalLeave,
      secondPersonalLeave: values.secondPersonalLeave,
      thirdPersonalLeave: values.thirdPersonalLeave,
      fourthPersonalLeave: values.fourthPersonalLeave,
      sickLeave: values.sickLeaveValue,
      privilegeLeave: values.privilegeLeaveValue,
      permissionLeave: values.permissionLeaveValue,
      wfhLeave: values.wfhLeaveValue,
      masterLeaveId: masterLeaveId,
    };

    try {
      let response;
      if (masterLeaveId) {
        response = await axiosService.put(`${LOOKUP_URL.UPDATE_LEAVES}`, formData);
        toast.success("Leave Request Updated Successfully!");
      } else {
        response = await axiosService.post(LOOKUP_URL.ADD_LEAVES, formData);
        toast.success("Leave Request Submitted Successfully!");
      }

      if (response.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error?.response?.data?.message);
      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };


  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="look-up-project-leave-tab overflow-hidden">
          <div className="row">
            <div className="col w-100">
              <p>
                1. Number of <b>Personal leave</b> to be added to the new Employee If
                an employee is joining in
              </p>
            </div>
          </div>
          <div className="row d-flex leave-list-tab-quarter" style={{ gap: "15px" }}>
            <div className="col leave-list-tab-col">
              <h6>1st Quarter</h6>
              <div className="leave-list-tab-inputbox" style={{ position: "relative" }}>
                <span className="p-float-label">
                  <InputText
                    id="firstPersonalLeave"
                    value={formik.values.firstPersonalLeave}
                    name="firstPersonalLeave"
                    onChange={formik.handleChange}
                    type='number'
                  />
                </span>
                {formik.touched.firstPersonalLeave && formik.errors.firstPersonalLeave ? (
                  <div className="add-leave-error-message">{formik.errors.firstPersonalLeave}</div>
                ) : null}
              </div>
            </div>
            <div className="col leave-list-tab-col">
              <h6>2nd Quarter</h6>
              <div className="leave-list-tab-inputbox" style={{ position: "relative" }}>
                <span className="p-float-label">
                  <InputText
                    id="secondPersonalLeave"
                    value={formik.values.secondPersonalLeave}
                    name="secondPersonalLeave"
                    onChange={formik.handleChange}
                    type='number'
                  />
                </span>
                {formik.touched.secondPersonalLeave && formik.errors.secondPersonalLeave ? (
                  <div className="add-leave-error-message">{formik.errors.secondPersonalLeave}</div>
                ) : null}
              </div>
            </div>
            <div className="col leave-list-tab-col">
              <h6>3rd Quarter</h6>
              <div className="leave-list-tab-inputbox" style={{ position: "relative" }}>
                <span className="p-float-label">
                  <InputText
                    id="thirdPersonalLeave"
                    value={formik.values.thirdPersonalLeave}
                    name="thirdPersonalLeave"
                    onChange={formik.handleChange}
                    type='number'
                  />
                </span>
                {formik.touched.thirdPersonalLeave && formik.errors.thirdPersonalLeave ? (
                  <div className="add-leave-error-message">{formik.errors.thirdPersonalLeave}</div>
                ) : null}
              </div>
            </div>
            <div className="col leave-list-tab-col">
              <h6>4th Quarter</h6>
              <div className="leave-list-tab-inputbox mx-1" style={{ position: "relative" }}>
                <span className="p-float-label">
                  <InputText
                    id="fourthPersonalLeave"
                    value={formik.values.fourthPersonalLeave}
                    name="fourthPersonalLeave"
                    onChange={formik.handleChange}
                    type='number'
                  />
                </span>
                {formik.touched.fourthPersonalLeave && formik.errors.fourthPersonalLeave ? (
                  <div className="add-leave-error-message">{formik.errors.fourthPersonalLeave}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row leave-list-tab-permission-row">
            <div className="col">
              <p className="mt-2">
                Number of <b>Sick leave</b> to be added each month
              </p>
            </div>
            <div className="col leave-list-tab-permission-col">
              <div className="leave-list-tab-inputbox" style={{ position: "relative" }}>
                <span className="p-float-label">
                  <InputText
                    id="sickLeaveValue"
                    value={formik.values.sickLeaveValue}
                    name="sickLeaveValue"
                    onChange={formik.handleChange}
                    type='number'
                  />
                </span>
                {formik.touched.sickLeaveValue && formik.errors.sickLeaveValue ? (
                  <div className="add-leave-error-message">{formik.errors.sickLeaveValue}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row leave-list-tab-permission-row">
            <div className="col">
              <p className="mt-2">
                Number of <b>Privilege leave</b> to be added each month
              </p>
            </div>
            <div className="col leave-list-tab-permission-col">
              <div className="leave-list-tab-inputbox" style={{ position: "relative" }}>
                <span className="p-float-label">
                  <InputText
                    id="privilegeLeaveValue"
                    value={formik.values.privilegeLeaveValue}
                    name="privilegeLeaveValue"
                    onChange={formik.handleChange}
                    type='number'
                  />
                </span>
                {formik.touched.privilegeLeaveValue && formik.errors.privilegeLeaveValue ? (
                  <div className="add-leave-error-message">{formik.errors.privilegeLeaveValue}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row leave-list-tab-permission-row">
            <div className="col">
              <p className="mt-2">
                Number of <b>Permission</b> to be added each month*
              </p>
            </div>
            <div className="col leave-list-tab-permission-col">
              <div className="leave-list-tab-inputbox-leave" style={{ position: "relative" }}>
                <span className="p-float-label text-wrap" style={{ height: "fit-content" }}>
                  <InputText
                    id="permissionLeaveValue"
                    value={formik.values.permissionLeaveValue}
                    name="permissionLeaveValue"
                    onChange={formik.handleChange}
                    type='number'
                  />
                </span>
                {formik.touched.permissionLeaveValue && formik.errors.permissionLeaveValue ? (
                  <div className="add-leave-error-message">{formik.errors.permissionLeaveValue}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="row leave-list-tab-permission-row">
            <div className="col " style={{ textAlign: "center" }}>
              <p className="mt-2">
                Number of <b>WFH</b> to be added each month
              </p>
            </div>
            <div className="col leave-list-tab-permission-col mb-2">
              <div className="leave-list-tab-inputbox" style={{ position: "relative" }}>
                <span className="p-float-label">
                  <InputText
                    id="wfhLeaveValue"
                    value={formik.values.wfhLeaveValue}
                    name="wfhLeaveValue"
                    onChange={formik.handleChange}
                    type='number'
                  />

                </span>
                {formik.touched.wfhLeaveValue && formik.errors.wfhLeaveValue ? (
                  <div className="add-leave-error-message">{formik.errors.wfhLeaveValue}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", padding: "15px 0" }}>
            <button className="look-up-leaves-add-btn" type="submit">
              {masterLeaveId ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LookUpLeaveTab;

