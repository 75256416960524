import { Field, Form, Formik } from "formik";
import React from "react";

const MyBankInfo = ({ values }) => {

  return (
    <div className="my-profile-content">
      <Formik initialValues={values} enableReinitialize={true}>
        {({ values }) => (
          <Form>
            <div
              className="row add-bank-container "
              style={{ marginTop: "-5px" }}
            >
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    name="panNumber"
                    render={({ field}) => (
                      <input
                        {...field}
                        className="add-Employee-input add-Employee-input--text"
                        type="text"
                        placeholder=""
                        readOnly
                        style={{
                          backgroundColor: values?.panNumber
                            ? "white"
                            : "rgba(0,0,0,0.05)",
                          border: values?.panNumber
                            ? "1px solid rgba(0, 0, 0, 0.5) "
                            : "none",
                        }}
                        value={field.value}
                      />
                    )}
                  />

                  <label className="add-Employee-label">PAN</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="aadhaarNumber"
                    type="text"
                    placeholder=""
                    readOnly
                    style={{
                      backgroundColor: values?.aadhaarNumber
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values?.aadhaarNumber
                        ? "1px solid rgba(0, 0, 0, 0.5)"
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">AADHAAR</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="accountNumber"
                    type="text"
                    placeholder=""
                    readOnly
                    style={{
                      backgroundColor: values?.accountNumber
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values?.accountNumber
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Account Number</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 position-relative">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    name="ifscCode"
                    className="add-Employee-input add-Employee-input--text"
                    type="text"
                    value={values.ifscCode}
                    readOnly
                    style={{
                      backgroundColor: values?.ifscCode
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values?.ifscCode
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">IFSC Code</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="bankName"
                    placeholder=""
                    
                    readOnly
                    style={{
                      backgroundColor: values?.bankName
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values?.bankName
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Bank Name</label>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="add-Employee-form-item add-Employee-form-item--text w-100">
                  <Field
                    id=""
                    className="add-Employee-input add-Employee-input--text"
                    name="branch"
                    readOnly
                    style={{
                      backgroundColor: values?.branch
                        ? "white"
                        : "rgba(0,0,0,0.05)",
                      border: values?.branch
                        ? "1px solid rgba(0, 0, 0, 0.5) "
                        : "none",
                    }}
                  />
                  <label className="add-Employee-label">Branch Name</label>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyBankInfo;
