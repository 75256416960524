import { useEffect, useRef, useState } from "react";
import { IoClose, IoReorderThreeOutline } from "react-icons/io5";
import { RxTriangleDown } from "react-icons/rx";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AllEmployeesLogo from "../../../../assets/images-svg/AllEmployeesLogo";
import AttendanceLogo from "../../../../assets/images-svg/AttendanceLogo";
import DashboardLogo from "../../../../assets/images-svg/DashboardLogo";
import Dropdown from "../../../../assets/images-svg/Drop_down";
import HolidayLogo from "../../../../assets/images-svg/HolidayLogo";
import LeavesLogo from "../../../../assets/images-svg/LeavesLogo";
import LogOut from "../../../../assets/images-svg/LogOut";
import MainProfile from "../../../../assets/images-svg/MainProfile";
import PayrollLogo from "../../../../assets/images-svg/PayrollLogo";
import ProfileIcon from "../../../../assets/images-svg/ProfileIcon";
import SettingsLogo from "../../../../assets/images-svg/SettingsLogo";
import Breadcrumbs from "../breadcrumb/BreadCrumb";
import "./Header.css";
import { Loader } from "react-bootstrap-typeahead";

function Header() {
  const location = useLocation();
  const [path, setPath] = useState("");
  const [title, setTitle] = useState("All Employees");
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const role = userData?.role;
  const dropdownRef = useRef(null);
  let FullName =
    (userData?.employee?.employeeFirstName
      ? userData.employee.employeeFirstName + " "
      : "") +
    (userData?.employee?.employeeLastName
      ? userData.employee.employeeLastName
      : "");

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setTitle("Dashboard");
    }
    if (
      location.pathname === "/all-employees/employees-list" ||
      location.pathname === "/all-employees/add-employees"
    ) {
      setTitle("All Employees");
    } else if (location.pathname === "/attendance") {
      setTitle("Attendance");
      setPath("/attendance/attendance-list");
    } else if (location.pathname === "/asset") {
      setTitle("Asset");
      setPath("/asset/asset-list");
    } else if (location.pathname === "/profile") {
      setTitle("Profile");
      setPath("/profile/profile-list");
    } else if (location.pathname === "/time-sheet") {
      setTitle("Timesheet");
      setPath("/time-sheet/timesheet-list");
    } else if (location.pathname === "/holidays") {
      setTitle("Holidays");
      setPath("/holidays/holidays-list");
    } else if (location.pathname === "/all-employees") {
      setTitle("Add New Employee");
      setPath("/all-employees/add-employees");
    } else if (location.pathname === "/look-up") {
      setTitle("Look-Up");
      setPath("/look-Up/look-up-list");
    } else if (location.pathname === "/reportees") {
      if (role === "ADMIN") {
        setTitle("Reports");
      } else {
        setTitle("My Reportees");
      }
      setPath("/reportees");
    } else if (location.pathname === "/leaves") {
      setTitle("Leave");
      setPath("/leaves");
    }
  }, [location.pathname]);

  const breadcrumbsPaths = [{ label: title, url: location.pathname }];

  const [showSidebar, setShowSidebar] = useState(false);
  const clearLocalStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
  };
  const handleLogoutClick = () => {
    clearLocalStorage();
    navigate("/");
  };
  const handleProfileClick = () => {
    clearLocalStorage();
    navigate("/profile");
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [showFullPageDetails, setShowFullPageDetails] = useState(false);

  const handleToggleFullPageDetails = () => {
    setShowFullPageDetails(!showFullPageDetails);
  };

  const queryParams = new URLSearchParams(location.search);
  const empName = queryParams?.get("empName");

  return (
    <nav className="navbar navbar-expand-md header-container pt-1 pb-0">
      <div className="container-fluid d-flex justify-content-between ">
        <div
          className=" align-items-center employee-list-header-text mb-0 "
          style={{ marginLeft: "5px" }}
        >

          {/* {location.pathname === "/dashboard" && (
            <div className="header-page-title">Dashboard</div>
          )} */}

          {location.pathname === "/all-employees" && (
            <div className="header-page-title">All Employees</div>
          )}
          {location.pathname === "/holidays" && (
            <div className="header-page-title">Holidays </div>
          )}
          {location.pathname === "/attendance" && (
            <div className="attendance-page-title">Attendance </div>
          )}
          {location.pathname === "/asset" && (
            <div className="asset-page-title">Asset </div>
          )}
          {location.pathname === "/time-sheet" && (
            <div className="time-sheet-page-title">Timesheet</div>
          )}
          <span
            className="d-none d-md-block header-title-text-wrap"
            onClick={() => {
              if (title === "All Employees") {
                navigate("/all-employees/employees-list");
              } else if (title === "Holidays") {
                navigate("/holidays/holidays-list");
              } else if (title === "Attendance") {
                navigate("/attendance/attendance-list");
              } else if (title === "time-sheet") {
                navigate("/time-sheet/timesheet-list");
              }
            }}
            style={{
              cursor: "pointer",
              paddingTop: "3px",
            }}
          >
            <Breadcrumbs paths={breadcrumbsPaths} className="breadcrumb mt-0" />
          </span>

          <p
            onClick={() => {
              if (title === "All Employees") {
                navigate("/all-employees/employees-list");
              }
            }}
            style={{ cursor: "pointer", paddingTop: "3px" }}
          >
            {title === "Dashboard"
              ? "All Dashboard List"
              : title === "All Employees" &&
                location.pathname === "/all-employees/add-employees"
              ? "All Employee > Add New Employee"
              : title === "All Employees" &&
                location.pathname === "/all-employees/view-employees"
              ? "All Employee > " + empName
              : title === "All Employees" &&
                location.pathname === "/all-employees/edit-employees"
              ? "All Employee > " + empName
              : title === "All Employees"
              ? "All Employee Information"
              : title === "Holidays"
              ? "All Holiday Lists"
              : title === "Timesheet"
              ? "All Timesheet Lists"
              : title === "Profile"
              ? "My profile"
              : title === "Leave"
              ? "All Leave lists"
              : title === "Asset"
              ? "All Asset List"
              : title === "Attendance"
              ? "Employees Attendance"
              : title === "Look-Up"
              ? "All Look Up details"
              : title === "Reports"
              ? "All Reports details"
              : title === "My Reportees"
              ? "All Reportees details"
              : title === "My Reportees"}
          </p>
        </div>
        <div
          className="d-md-none ps-2 d-flex col-7 header-title-text-con "
          style={{ height: "100%" }}
        >
          <div className="d-flex align-items-center ">
            <div
              className="d-flex align-items-center gap-1"
              onClick={() => {
                setShowSidebar(true);
              }}
            >
              <span
                className={`${
                  title.split(" ").length > 1
                    ? "header-title-text-wrap-mob"
                    : "header-title-text"
                }`}
              >
                {title}
              </span>
              <RxTriangleDown className="header-dropdown-icon" />
            </div>
          </div>
        </div>

        <hr className="d-none d-md-block hr-line"></hr>
        <button
          className="navbar-toggler mx-auto "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={showFullPageDetails}
          aria-label="Toggle navigation"
          onClick={handleToggleFullPageDetails}
        >
          {showFullPageDetails ? (
            <IoClose className="toggle-icon" />
          ) : (
            <IoReorderThreeOutline className="toggle-icon" />
          )}
        </button>
        {showFullPageDetails && (
          <div className="full-page-details">
            <div className="navbar-nav mb-2 mb-lg-0 gap-md-5">
              <NavLink
                to={path}
                className={
                  location.pathname === path
                    ? "nav-link ms-2 ms-md-0 active"
                    : "nav-link ms-2 ms-md-0"
                }
                onClick={() => {
                  setShowFullPageDetails(false);
                }}
              >
                {title}
              </NavLink>
            </div>
            <button
              className="cancel-button"
              onClick={handleToggleFullPageDetails}
            >
              <IoClose className="toggle-icon" />
            </button>
          </div>
        )}
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {path === "all-employees" && (
            <ul className="navbar-nav mb-2 mb-lg-0 gap-md-5">
              <NavLink
                to={"/all-employees/employees-list"}
                className="nav-link ms-2 ms-md-0 active"
              >
                All Employees
              </NavLink>
            </ul>
          )}
        </div>

        <div className="employee-list-select-holder mt-2 ms-auto position-relative">
          <div
            ref={dropdownRef}
            className="employee-list-profile-holder"
            onClick={handleDropdownToggle}
          >
            <div className="d-flex align-items-center justify-content-between gap-4">
              <div className="d-flex gap-2">
                <a href="#">
                  <MainProfile className="employee-list-profile-header" />
                </a>
                <p className="mt-2">{FullName}</p>
              </div>
              <span className="employee-list-header-dropdown-icon">
                <Dropdown />
              </span>
            </div>
            {dropdownOpen && (
              <div className="employee-list-dropdown-content position-absolute end-0 top-100">
                {role === "USER" && (
                  <div className="mb-1">
                    <a
                      href="#Profile"
                      className="employee-list-profile1"
                      onClick={handleProfileClick}
                    >
                      <span style={{ marginRight: "10px" }}>
                        <ProfileIcon />
                      </span>
                      Profile
                    </a>
                  </div>
                )}
                <div>
                  <a
                    href="/"
                    className="employee-list-logout"
                    onClick={handleLogoutClick}
                  >
                    <span style={{ marginRight: "10px", marginTop: "30px" }}>
                      <LogOut />
                    </span>
                    Logout
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showSidebar && (
        <div className="header-sidebar-main">
          <div className="d-flex justify-content-between">
            <span className="header-title-text">Dashboard</span>

            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
              type="button"
            >
              {!showSidebar && (
                <span>
                  <IoReorderThreeOutline className="toggle-icon" />
                </span>
              )}
              {showSidebar && (
                <span
                  onClick={() => {
                    setShowSidebar(false);
                  }}
                >
                  <IoClose className="toggle-icon" />
                </span>
              )}
            </button>
          </div>

          <div className="sidebar-list-container">
            <NavLink
              className="sidebar-list-inner-container"
              to={"dashboard"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? { backgroundColor: "#3fc28a", color: "#3fc28a" }
                  : { color: "#ffffff" }
              }
            >
              <div className="logo-hide-head">
                <DashboardLogo color={"#FFFFFF"} />
              </div>
              <div className="logo-show-head">
                <DashboardLogo color={"#283557"} />
              </div>
              <span className="sidebar-list-text">Dashboard</span>
            </NavLink>

            <NavLink
              className="sidebar-list-inner-container"
              to={"all-employees"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="logo-hide-head">
                <AllEmployeesLogo color={"#FFFFFF"} />
              </div>
              <div className="logo-show-head">
                <AllEmployeesLogo color={"#283557"} />
              </div>

              <span className="sidebar-list-text">All Employees</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"attendance"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <AttendanceLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <AttendanceLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Attendance</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"payroll"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <PayrollLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <PayrollLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Payroll</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"leaves"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <LeavesLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <LeavesLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Leaves</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"holidays"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <HolidayLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <HolidayLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Holidays</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"settings"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <SettingsLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <SettingsLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Settings</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"attendance"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <AttendanceLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <AttendanceLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Attendance</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"payroll"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <PayrollLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <PayrollLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Payroll</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"leaves"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <LeavesLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <LeavesLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Leaves</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"holidays"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <HolidayLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <HolidayLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Holidays</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"look-up"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <span className="sidebar-list-text">Look-Up</span>
            </NavLink>
            <NavLink
              className="sidebar-list-inner-container"
              to={"reportees"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <span className="sidebar-list-text">My Reportees</span>
            </NavLink>

            <NavLink
              className="sidebar-list-inner-container"
              to={"settings"}
              onClick={() => setShowSidebar(false)}
              style={({ isActive }) =>
                isActive
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "#3fc28a",
                    }
                  : { color: "#ffffff" }
              }
            >
              <div className="hover-hide-logo">
                <SettingsLogo color={"#FFFFFF"} />
              </div>
              <div className="hover-show-logo">
                <SettingsLogo color={"#3FC28A"} />
              </div>

              <span className="sidebar-list-text">Settings</span>
            </NavLink>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Header;
