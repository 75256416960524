import { Calendar } from "primereact/calendar";
import React, { useEffect, useRef, useState } from "react";
import Edit from "../../../../assets/images-svg/Edit";
import Filter from "../../../../assets/images-svg/Filter";
import Plus from "../../../../assets/images-svg/Plus";
import Trash from "../../../../assets/images-svg/Trash";
import { axiosService } from "../../../../services/axios.service";
import Deletemodal from "../../../holidays/components/holidays-list/Deletemodal.jsx";
import Holidaymodal from "../../../holidays/components/holidays-list/Holidaymodal.jsx";
import "../../../holidays/components/holidays-list/HolidaysList.css";
import { HOLIDAYS_URL } from "../../../holidays/constants/holidays-urls.js";

function LookUpHolidayTab() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [holidayData, setHolidayData] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({});
  const [mode, setMode] = useState("");
  const calendarRef = useRef(null);
  const handleCloseModal = () => {
    setDeleteModalOpen(false);
    fetchData();
  };

  const fetchData = async () => {
    try {
      const response = await axiosService.get(
        `${
          HOLIDAYS_URL.GET_HOLIDAYS_BY_YEAR
        }?year=${selectedYear.getFullYear()}`
      );

      const data = response?.data;
      if (data) {
        const sortedData = data.sort(
          (a, b) => new Date(a.holidayDate) - new Date(b.holidayDate)
        );
        setHolidayData(sortedData);
      }
    } catch (error) {
      console.error("Error fetching holiday data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedYear]);

  const isPastDate = (dateString) => {
    const holidayDate = new Date(dateString);
    const currentDate = new Date();
    return holidayDate < currentDate;
  };

  const handleEditClick = (holiday) => {
    setSelectedHoliday(holiday);
    setModalOpen(true);
    setMode("edit");
    fetchData()
  };

  const handleDeleteClick = (holiday) => {
    setDeleteModalData({
      title: "Are you sure ?",
      data: "Are you sure you want to delete?",
    });
    setSelectedHoliday(holiday);
    setDeleteModalOpen(true);
  };

  return (
    <div
      className="holiday-list-container">
      <div className="container-fluid">
        <div className="row mt-2 holiday-list-row look-up-holiday-tab">
          <div className="col-lg-8 col-md-6">
            <div className="holiday-list-text-holder d-flex flex-wrap align-items-center">
              <h1>Holiday Schedule for Year</h1>
              <div className="holiday-list-filters-input-container md:mx-auto">
                <div className="looup-holiday-year-filter">
                  <Calendar
                    dateFormat="yy"
                    view="year"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.value)}
                    className="calendar-input"
                    ref={calendarRef}
                  />
                  <div className="lookup-holiday-filter-icon">
                    <Filter />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex justify-content-end">
            <div
              className="employee-list-add-employee-button-holiday"
              style={{ marginTop: "-8px" }}
            >
              <button
                className="employee-add-new mx-3"
                onClick={() => {
                  setModalOpen(true);
                  setMode("add");
                }}
              >
                <span style={{ marginRight: "10px" }}>
                  <Plus />
                </span>
                Add New Holiday
              </button>
              {modalOpen && (
                <Holidaymodal
                  modalOpen={modalOpen}
                  closeModal={() => {
                    setModalOpen(false);
                    setSelectedHoliday(null);
                    fetchData()
                  }}
                  cancelButton={true}
                  submitButton={true}
                  onCloseTitle={"Cancel"}
                  mode={mode}
                  holidayData={selectedHoliday}
                  modalTitle={
                    mode === "edit"
                      ? "Edit Holiday"
                     : "Add New Holiday"
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="lookup-list-holiday-table-container">
          <table className="lookup-list-holiday-table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Day</th>
                <th scope="col">Holiday Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {holidayData.map((holiday, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <span>
                        <hr
                          className={`holidays-hr-line ${
                            isPastDate(holiday.holidayDate)
                              ? "past-holiday"
                              : "upcoming-holiday"
                          }`}
                        />
                      </span>
                      {holiday.holidayDate}
                    </div>
                  </td>
                  <td>{holiday.day}</td>
                  <td>{holiday.holidayName}</td>
                  <td>
                    <div className="action d-inline-flex gap-3">
                      <button onClick={() => handleEditClick(holiday)}>
                        <Edit />
                      </button>
                      <button onClick={() => handleDeleteClick(holiday)}>
                        <Trash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex gap-4 mt-3" style={{boxShadow:"0px -2px  #F3EEEA"}}>
          <div className="d-flex gap-2 holidays-indicator">
            <div className="circle-green mt-2"></div>
            <p>Upcoming</p>
          </div>
          <div className="d-flex gap-2 holidays-indicator">
            <div className="circle-black mt-2"></div>
            <p>Past Holidays</p>
          </div>
        </div>
      </div>
      <Deletemodal
        modalOpen={deleteModalOpen}
        closeModal={handleCloseModal}
        cancelButton={true}
        submitButton={true}
        onCloseTitle="Close"
        holidayData={selectedHoliday}
        {...deleteModalData}
      />
    </div>
  );
}

export default LookUpHolidayTab;
