import { AppConfig } from "../../../env.development";

export const REPORTEES_URL = {
  GET_ALL_REPORTEES: `${AppConfig.api_url}attendance/filter/hierarchy/v1/`,
  GET_REPORTEES_NAMES: `${AppConfig.api_url}employee/v1/hierarchy/`,
  GET_REPORTEES_LEAVES: `${AppConfig.api_url}leave/v1/leave/hierarchy/`,
  GET_REPORTEES_LEAVE_BALANCE: `${AppConfig.api_url}leave-master/v1/hierarchy/`,
  GET_REPORTEES_TIMESHEET: `${AppConfig.api_url}time-sheet/v1/hierarchy/`,
  REPORTEES_LEAVE_APPROVE: `${AppConfig.api_url}leave/v3/`,
  REPORTEES_LEAVE_REJECT: `${AppConfig.api_url}leave/v4/`,
  REPORTEES_APPLIED_LEAVE: `${AppConfig.api_url}leave/v1/`,

  GET_REPORTEES_EXPORT_TIMESHEET: `${AppConfig.api_url}time-sheet/v2/export/`,

  GET_ADMIN_REPORTEES_EXPORT_TIMESHEET: `${AppConfig.api_url}time-sheet/v3/export`,

  GET_ALL_REPORTEES_ADMIN: `${AppConfig.api_url}attendance/filter/admin/v1`,
  ADMIN_REPORTEES_LEAVES: `${AppConfig.api_url}leave/v1/leave/admin`,
  ADMIN_REPORTEES_LEAVE_BALANCE: `${AppConfig.api_url}leave-master/v1/admin`,
  ADMIN_REPORTEES_TIMESHEET: `${AppConfig.api_url}time-sheet/v1/admin`,
  ADMIN_REPORTEES_NAMES: `${AppConfig.api_url}employee/v1`,
  ADMIN_EDIT_LEAVE_MASTER: `${AppConfig.api_url}leave-master/v1`,
  GET_MONTHLY_LEAVE: `${AppConfig.api_url}leave-master/v1/update-leave`,
};
