import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import HolidaysList from "../holidays-list/HolidaysList";

function HolidaysRoutes() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/holidays") {
      navigate("/holidays/holidays-list");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="holidays-list" element={<HolidaysList />} />
      
    </Routes>
  );
}

export default HolidaysRoutes;
