import { ErrorMessage, Form, Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { axiosService } from "../../../../services/axios.service";
import { HOLIDAYS_URL } from "../../constants/holidays-urls";

function Holidaymodal({
  modalOpen,
  closeModal,
  cancelButton,
  submitButton,
  onCloseTitle,
  mode,
  holidayData,
  modalTitle,
}) {
  const [date, setDate] = useState(null);
  const [isFilled, setIsFilled] = useState(false);

  const year = date ? date.getFullYear() : "";
  const month = date ? String(date.getMonth() + 1).padStart(2, "0") : "";
  const day = date ? String(date.getDate()).padStart(2, "0") : "";

  const formattedDate = `${year}-${month}-${day}`;
  const options = { weekday: "long" };
  const formattedDay = date ? date.toLocaleString("en-US", options) : "";

  const [initialValues, setInitialValues] = useState({
    holidayName: "",
    holidayDate: "",
  });

  useEffect(() => {
    if ((mode === "edit" || mode === "view") && holidayData) {
      setInitialValues({
        holidayName: holidayData.holidayName,
        holidayDate: new Date(holidayData.holidayDate),
      });
      if (mode === "edit" && !date) {
        setDate(new Date(holidayData.holidayDate));
      }
    }
  }, [mode, holidayData, date]);

  const validationSchema = Yup.object().shape({
    holidayName: Yup.string().required("Holiday Name is required"),
    holidayDate: Yup.date().nullable().required(" Holiday Date is required"),
  });

  const onSubmit = async (values) => {
    const responseData = {
      holidayName: values.holidayName,
      holidayDate: formattedDate,
      day: formattedDay,
    };

    try {
      let response;
      if (mode === "add") {
        response = await axiosService.post(
          `${HOLIDAYS_URL.ADD_HOLIDAYS}`,
          responseData
        );
      } else if (mode === "edit" && holidayData) {
        const updatedData = {
          ...responseData,
          holidayId: holidayData?.holidayId,
        };
        response = await axiosService.put(
          `${HOLIDAYS_URL.UPDATE_HOLIDAYS}`,
          updatedData
        );
      }

      if (response && response.status === 200) {
        toast.success(
          mode === "add"
            ? "Holiday Added Successfully!"
            : "Holiday Updated Successfully!"
        );
        closeModal();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage.split(":")[1]);
      } else {
        toast.error("An error occurred while processing your request.");
      }
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      className="holiday-confirm-modal"
      overlayClassName="holiday-confirm-modal-overlay"
      bodyOpenClassName="overflow-hidden"
    >
      <div className="holiday-confirm-modal-container">
        <h1>{modalTitle}</h1>
        <hr
          style={{ border: "1px solid rgba(0, 0, 0, 0.2)", marginTop: "-2px" }}
        />
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form>
              <div className="floating-label-datepicker-lookup mt-3 ">
                <span className="p-float-label">
                  <InputText
                    id="inputtext"
                    value={values.holidayName}
                    placeholder=""
                    name="holidayName"
                    readOnly={mode === "view"}
                    onChange={(e) => {
                      const capitalizedValue = e.target.value.replace(
                        /\b\w/g,
                        (char) => char.toUpperCase()
                      );
                      handleChange({
                        target: {
                          name: "holidayName",
                          value: capitalizedValue,
                        },
                      });
                    }}
                    className={
                      " d-flex" +
                      (errors.holidayName && touched.holidayName
                        ? " p-invalid"
                        : "")
                    }
                  />
                  <label
                    htmlFor="inputtext"
                    className="holiday-date-picker-label"
                    style={{ display: "block" }}
                  >
                    Holiday Name
                  </label>
                  {!values.holidayName &&
                    errors.holidayName &&
                    touched.holidayName && (
                      <ErrorMessage
                        name="holidayName"
                        component="div"
                        className="p-error"
                      />
                    )}
                </span>
              </div>

              <div className="floating-label-datepicker-lookup">
                <span className="p-float-label" style={{ marginTop: "40px" }}>
                  <Calendar
                    inputId="Select Date"
                    name="holidayDate"
                    value={mode === "view" ? initialValues.holidayDate : date}
                    onChange={(e) => {
                      setDate(e.value);
                      handleChange(e);
                      setIsFilled(!!e.value);
                    }}
                    appendTo={document.body}
                    className={
                      "custom-calendar" +
                      (errors.holidayDate && touched.holidayDate
                        ? " p-invalid"
                        : "") +
                      (isFilled ? " filled" : "") +
                      (values.holidayDate ? " filled-bg" : "")
                    }
                    style={{ width: "100%", color: "#000" }}
                    showIcon
                    disabled={mode === "view"}
                    readOnlyInput
                  />
                  <label
                    htmlFor="inputtext"
                    className="holiday-date-picker-label"
                  >
                    Select Date
                  </label>
                </span>
                {!values.holidayDate &&
                  errors.holidayDate &&
                  touched.holidayDate && (
                    <ErrorMessage
                      name="holidayDate"
                      component="div"
                      className="p-error1"
                    />
                  )}
              </div>
              <div
                className="gap-3 d-flex justify-content-around mb-2"
                style={{ marginTop: "35px", marginLeft: "15px" }}
              >
                {cancelButton && (
                  <button
                    className="modal-cancel-button"
                    style={{ margin: mode === "view" ? "0 auto" : "" }}
                    onClick={closeModal}
                  >
                    {onCloseTitle}
                  </button>
                )}
                {submitButton && mode !== "view" && (
                  <button className="modal-add-button add-button" type="submit">
                    {mode === "add" ? "Add" : "Update"}
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default Holidaymodal;
