import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AssetList from "../asset-list/AssetList";

const AssetRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/asset") {
      navigate("/asset/asset-list");
    }
  }, [location.pathname, navigate]);

  return (
    <Routes>
      <Route path="asset-list" element={<AssetList/>} />
    </Routes>
  );
};

export default AssetRoutes;
