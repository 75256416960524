import React from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../../../../assets/images-png/Alert.png";
import { axiosService } from "../../../../services/axios.service";

import { ASSET_URL } from "../../../asset/constants/asset-urls";

import { LOOKUP_URL } from "../../../look-up/constants/all-look-up-urls";
import { HOLIDAYS_URL } from "../../constants/holidays-urls";
import "./HolidaysList.css";
function Deletemodal({
  modalOpen,
  closeModal,
  title,
  data,
  cancelButton,
  submitButton,
  onCloseTitle,
  holidayData,
  taskData,
  assetData,
  assetDatas,
  fetchData,
}) {
  const handleConfirmDelete = async () => {
    try {
      if (holidayData) {
        const response = await axiosService.delete(
          `${HOLIDAYS_URL.DELETE_HOLIDAYS}/${holidayData?.holidayId}`
        );
        if (response.status === 200) {
          toast.success("Holiday deleted Successfully");
          closeModal();
          fetchData();
        }
      }

      if (taskData) {
        const response = await axiosService.delete(
          `${LOOKUP_URL.DELETE_PROJECT}/${taskData}`
        );

        if (response.status === 200) {
          toast.success("Data successfully deleted");
          closeModal();
          fetchData();
        }
      }
      if (assetData) {
        const response = await axiosService.delete(
          `${LOOKUP_URL.DELETE_ASSET}/${assetData}`
        );

        if (response.status === 200) {
          toast.success("Asset Type successfully deleted");
          closeModal();
          fetchData();
        }
      }
      if (assetDatas) {
        const response = await axiosService.delete(
          `${ASSET_URL.DELETE_ASSET_DATA}/${assetDatas}`
        );

        if (response.status === 200) {
          toast.success("Asset Data successfully deleted");
          closeModal();
          fetchData();
        }
      }
      
    } catch (error) {
      console.error("Error deleting holiday:", error);
    }
  };
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      className="holiday-confirm-modal"
      overlayClassName="holiday-confirm-modal-overlay"
      bodyOpenClassName="overflow-hidden"
    >
      <div className="holiday-confirm-modal-container  delete-confirm-modal-container">
        <div>
          <img src={Alert} alt="Alert" />
        </div>
        <h1 className="delete-confirm-modal-title ">{title}</h1>
        <p className="delete-confirm-modal-data ">{data}</p>
        <div className="mt-3">
          {cancelButton && (
            <button className="modal-cancel-button " onClick={closeModal}>
              {onCloseTitle}
            </button>
          )}
          {submitButton && (
            <button
              className="modal-confirm-button-delete"
              onClick={handleConfirmDelete}
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default Deletemodal;
