import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import "./App.css";
import Header from "./core/auth/components/header/Header";
import AuthRoutes from "./core/auth/components/routes/AuthRoutes";
import SideBar from "./core/auth/components/sidebar/SideBar";
import AllEmployeesRoutes from "./modules/all-employees/components/routes/AllEmployeesRoutes";
import HolidaysRoutes from "./modules/holidays/components/routes/HolidaysRoutes";
import sessionService from "./services/sessionstorage.service";

import AssetRoutes from "./modules/asset/components/routes/AssetRoutes";

import AttendanceRoutes from "./modules/attendance/components/routes/AttendanceRoutes";
import DashboardNavBar from "./modules/dashboard/components/DashboardNavBar/DashboardNavBar";
import LeaveLists from "./modules/leaves/components/LeaveLists";
import AllLookUpDetails from "./modules/look-up/components/all-look-up-details/AllLookUpDetails";
import ProfileRoutes from "./modules/profile/components/routes/ProfileRoutes";
import AllReporteesDetails from "./modules/reportees/components/all-reportees-details/AllReporteesDetails";
import TimesheetRoutes from "./modules/timesheet/components/routes/TimesheetRoutes";

function App() {
  const [hideNavbar, setHideNavbar] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === "/") {
      setHideNavbar(true);
    } else {
      setHideNavbar(false);
    }
  }, [location]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", height: "100vh" }}>
        {sessionService.isUserLoggedIn() && !hideNavbar && <SideBar />}
        <div
          className="app-right-side-content"
          style={{ flex: 1}}
        >
          {sessionService.isUserLoggedIn() && !hideNavbar && <Header />}
          <Routes>
            <Route path="*" element={<AuthRoutes />} />
            {sessionService.isUserLoggedIn() && (
              <>
                <Route path="/dashboard/*" element={<DashboardNavBar />} />
                <Route
                  path="/all-employees/*"
                  element={<AllEmployeesRoutes />}
                />
                <Route path="/attendance/*" element={<AttendanceRoutes />} />
                <Route path="/asset/*" element={<AssetRoutes/>} />
                <Route path="/time-sheet/*" element={<TimesheetRoutes />} />
                <Route path="/holidays/*" element={<HolidaysRoutes />} />
           

                <Route path="/look-up/*" element={<AllLookUpDetails />} />

                <Route path="/reportees/*" element={<AllReporteesDetails />} />

                <Route path="/leaves/*" element={<LeaveLists />} />

                <Route path="/profile/*" element={<ProfileRoutes />} />
              </>
            )}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default function Root() {
  return <App />;
}
