import { Field, FieldArray, Form, Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Plus from "../../../../assets/images-svg/Plus";
import Trash from "../../../../assets/images-svg/Trash";
import { axiosService } from "../../../../services/axios.service";
import { ALL_EMPLOYEES_URL } from "../../constants/all-employees-urls";
import "../all-employee-asset/AllEmployeeAsset.css";
import CancelModal from "../cancel-modal/CancelModal";

const AllEmployeeAsset = ({ onSubmit, type, values, setActiveTab }) => {
  const containerRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [setAssetData] = useState([]);
  const [fieldsAreReadOnly, setFieldsAreReadOnly] = useState(false);
  const [setAssetMap] = useState({});
  const [setAssetMaps] = useState({});
  const [error, setError] = useState(false);
  const [assetFilter, setAssetFilter] = useState([]);

  function formatDateForsubmit(date) {
    const year = date ? date.getFullYear() : "";
    const month = date ? String(date.getMonth() + 1).padStart(2, "0") : "";
    const day = date ? String(date.getDate()).padStart(2, "0") : "";
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  function parseDate(dateString) {
    if (!dateString) return null;
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }
  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    return isoDateString;
  }
  const OnEditSubmit = async (values) => {
    let mergedValues;
    try {
      mergedValues = {
        ...values,
        supervisor: { empId: values?.supervisor?.empId },
        employeeDob: convertDateFormat(values?.employeeDob),
        joiningDate: convertDateFormat(values?.joiningDate),
        lastWorkingDate: convertDateFormat(values?.lastWorkingDate),
        assetList: values?.assetList?.every(asset =>
          Object.values(asset).every(value => value === "" || value === null)
        )
          ? [] 
          : values?.assetList, 
      
      };
      const response = await axiosService.put(
        `${ALL_EMPLOYEES_URL.UPDATE_EMPLOYEE_DATA}`,
        mergedValues
      );

      if (response.status === 200) {
        navigate("/all-employees/employees-list");
        toast.success("Updated successfully");
      }
    } catch (error) {
      if (error?.response?.data?.message !== "Bad credentials") {
        toast.error(error?.response?.data?.message?.split(":")[1]);
      }
    }
  };

  const handleSubmit = (values) => {
    const saveValues = {
      ...values,
    };
    if (type === "View") {
      onSubmit(saveValues, true);
    } else if (type === "Edit") {
      OnEditSubmit(saveValues, true);
    } else {
      onSubmit(saveValues, true);
    }
  };

  // ----API ----
  const getFilter = async () => {
    try {
      const response = await axiosService.get(
        ALL_EMPLOYEES_URL.GET_EMPLOYEES_ASSET_DROP
      );
      const responseget = response?.data?.data;
      setAssetFilter(responseget);
    } catch (error) {
      console.error("Error fetching filter data:", error);
    }
  };

  useEffect(() => {
    getFilter();
  }, []);

  function dataAsset(value, type) {
    if (type === "assetName") {
      let a = assetFilter.filter((b) => value === b?.assetName);
      let c = {
        assetName: a[0]?.assetName,
        serialNo: a[0]?.serialNo,
      };
      return c;
    }
    if (type === "serialNo") {
      let a = assetFilter.filter((b) => value === b?.serialNo);
      let c = {
        assetName: a[0]?.assetName,
        serialNo: a[0]?.serialNo,
      };
      return c;
    }
  }

  const fetchData = async (value, form, index, type) => {
    let para = dataAsset(value, type);
    setError(false);

    try {
      const url = ALL_EMPLOYEES_URL.GET_EMPLOYEES_ASSET;
      const response = await axiosService.get(url, para);
      if (response.status === 200) {
        const data = response?.data?.data;

        const assetMap = data.reduce((acc, item) => {
          acc[item.assetName] = item;
          return acc;
        }, {});
        const assetMaps = data.reduce((acc, item) => {
          acc[item.serialNo] = item;
          return acc;
        }, {});
        if (type === "assetName") {
          const selectedAsset = assetMap[value];

          form.setFieldValue(
            `assetList[${index}].assetType`,
            selectedAsset?.assetType || ""
          );
          form.setFieldValue(
            `assetList[${index}].serialNo`,
            selectedAsset?.serialNo || ""
          );
          form.setFieldValue(
            `assetList[${index}].assetId`,
            selectedAsset?.assetId || ""
          );
          form.setFieldValue(
            `assetList[${index}].assetDescription`,
            selectedAsset?.assetDescription || ""
          );
          form.setFieldValue(
            `assetList[${index}].purchaseDate`,
            selectedAsset?.purchaseDate || ""
          );
          form.setFieldValue(
            `assetList[${index}].warranty`,
            selectedAsset?.warranty || ""
          );
          form.setFieldValue(
            `assetList[${index}].warrantyExpiryDate`,
            selectedAsset?.warrantyExpiryDate || ""
          );
        }
        if (type === "serialNo") {
          const selectedAssetSerial = assetMaps[value];

          form.setFieldValue(
            `assetList[${index}].assetType`,
            selectedAssetSerial?.assetType || ""
          );
          form.setFieldValue(
            `assetList[${index}].assetName`,
            selectedAssetSerial?.assetName || ""
          );
          form.setFieldValue(
            `assetList[${index}].assetId`,
            selectedAssetSerial?.assetId || ""
          );
          form.setFieldValue(
            `assetList[${index}].assetDescription`,
            selectedAssetSerial?.assetDescription || ""
          );
          form.setFieldValue(
            `assetList[${index}].purchaseDate`,
            selectedAssetSerial?.purchaseDate || ""
          );
          form.setFieldValue(
            `assetList[${index}].warranty`,
            selectedAssetSerial?.warranty || ""
          );
          form.setFieldValue(
            `assetList[${index}].warrantyExpiryDate`,
            selectedAssetSerial?.warrantyExpiryDate || ""
          );
        }
        form.setFieldValue("assetName", data[0]?.assetName);
        form.setFieldValue("serialNo", data[0]?.serialNo);
        form.setFieldValue("assetId", data[0]?.assetId);
        setAssetData(data);
        setAssetMap(assetMap);
        setAssetMaps(assetMaps);
      } else {
        toast.error("The asset data is already added");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Asset already taken");
        setError(true);
      }
      console.error("Error fetching project data:", error);
    }
  };

  const assetOptions = assetFilter?.map((e) => ({
    name: e.assetName,
    value: e.assetName,
  }));
  const serialOptions = assetFilter?.map((e) => ({
    name: e.serialNo,
    value: e.serialNo,
  }));

  useEffect(() => {
    if (type === "View") {
      setFieldsAreReadOnly(true);
    }
  }, [type]);
  return (
    <div className="employee-list-container-personal" ref={containerRef}>
      <div className="employee-list-banner mt-0">
        <div
          className="employee-list-table-holder pt-0 mt-0"
          style={{
            marginTop: "0",
            borderTop: "0",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <div className="Employee-profile-container w-100">
            <div className="addEmployee-details w-100">
              <Formik
                initialValues={{
                  ...values,
                  assetList: (values.assetList.length > 0 &&
                    values.assetList) || [
                      {
                        assetId: "",
                        assetName: null,
                        assetType: "",
                        serialNo: null,
                        assignDate: null,
                        surrenderDate: null,
                        assetDescription: "",
                        purchaseDate: "",
                        warranty: "",
                        warrantyExpiryDate: "",
                      },
                    ],
                }}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ values }) => (
                  <Form>
                    <FieldArray name="assetList">
                      {({ push, remove }) => (
                        <>
                          <div className="row employee-asset-container">
                            <div className="col-lg-3 col-md-3 ">
                              <label style={{ marginLeft: "2px" }}>
                                Asset Name
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <label style={{ marginLeft: "-1px" }}>
                                Asset Type
                              </label>
                            </div>
                            <div className="col-lg-3 col-md-3">
                              <label style={{ marginLeft: "-7px" }}>
                                Serial Number
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <label style={{ marginLeft: "-12px" }}>
                                Assign Date
                              </label>
                            </div>
                            <div className="col-lg-2 col-md-2">
                              <label style={{ marginLeft: "-19px" }}>
                                Surrender Date
                              </label>
                            </div>
                            {values.assetList &&
                              values.assetList.map((assetList, index) => (
                                <div
                                  className="row employee-asset-main row-gap-3"
                                  style={{ marginTop: "10px" }}
                                  key={index}
                                >
                                  <div className="col-lg-3 col-md-3">
                                    <div className="employee-asset-drop">
                                      <Field
                                        name={`assetList[${index}].assetName`}
                                      >
                                        {({ field, form }) =>
                                          fieldsAreReadOnly ? (
                                            <div
                                              className="w-full md:w-14rem asset-name-drop"
                                              style={{
                                                backgroundColor: field.value
                                                  ? "white"
                                                  : "rgba(0,0,0,0.05)",
                                                color: field.value
                                                  ? "black"
                                                  : "none",
                                                padding: "2px 10px",
                                              }}
                                            >
                                              {field.value}
                                            </div>
                                          ) : (
                                            <Dropdown
                                              value={field.value}
                                              onChange={(e) => {
                                                fetchData(
                                                  e.value,
                                                  form,
                                                  index,
                                                  "assetName"
                                                );
                                                form.setFieldValue(
                                                  field.name,
                                                  e.value
                                                );
                                              }}
                                              options={assetOptions}
                                              optionLabel="name"
                                              // placeholder="Select an Asset Name"
                                              filter
                                              showClear
                                              className="w-full md:w-14rem asset-name-drop"
                                              style={{
                                                zIndex: "0",
                                                backgroundColor: field.value
                                                  ? "white"
                                                  : "rgba(0,0,0,0.05)",
                                                color: field.value
                                                  ? "black"
                                                  : "none",
                                              }}
                                            />
                                          )
                                        }
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <Field
                                      name={`assetList[${index}].assetType.assetName`}
                                      className="add-employee-asset-input"
                                      type="text"
                                      readOnly
                                      style={{
                                        backgroundColor: values.assetList[index]
                                          .assetType
                                          ? "white"
                                          : "rgba(0,0,0,0.05)",
                                        color: values.assetList[index].assetType
                                          ? "black"
                                          : "none",
                                        border: values.assetList[index]
                                          .assetType
                                          ? " 1px solid lightgray"
                                          : "rgba(0,0,0,0.05)",
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-3 col-md-3">
                                    <div className="employee-asset-drop">
                                      <Field
                                        name={`assetList[${index}].serialNo`}
                                      >
                                        {({ field, form }) =>
                                          fieldsAreReadOnly ? (
                                            <div
                                              className="w-full md:w-14rem asset-name-drop"
                                              style={{
                                                backgroundColor: field.value
                                                  ? "white"
                                                  : "rgba(0,0,0,0.05)",
                                                color: field.value
                                                  ? "black"
                                                  : "none",
                                                padding: "2px 10px",
                                              }}
                                            >
                                              {field.value}
                                            </div>
                                          ) : (
                                            <Dropdown
                                              value={field.value}
                                              onChange={(e) => {
                                                fetchData(
                                                  e.value,
                                                  form,
                                                  index,
                                                  "serialNo"
                                                );

                                                form.setFieldValue(
                                                  field.name,
                                                  e.value
                                                );
                                              }}
                                              options={serialOptions}
                                              optionLabel="name"
                                              // placeholder="Select a Serial Number"
                                              filter
                                              showClear
                                              disabled={fieldsAreReadOnly}
                                              className="w-full md:w-14rem asset-name-drop"
                                              style={{
                                                zIndex: "0",
                                                backgroundColor: field.value
                                                  ? "white"
                                                  : "rgba(0,0,0,0.05)",
                                                color: field.value
                                                  ? "black"
                                                  : "none",
                                              }}
                                            />
                                          )
                                        }
                                      </Field>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <span className="p-float-employee-asset">
                                      <Field
                                        name={`assetList[${index}].assignDate`}
                                      >
                                        {({ field, form }) => (
                                          <Calendar
                                            inputId={`assignDate-${index}`}
                                            value={parseDate(field.value)}
                                            onChange={(e) => {
                                              const formattedDate =
                                                formatDateForsubmit(e.value);
                                              form.setFieldValue(
                                                field.name,
                                                formattedDate
                                              );
                                            }}
                                            className={
                                              "employee-asset-calendar" +
                                              (field.value ? " filled-bg" : "")
                                            }
                                            style={{
                                              width: "100%",
                                              color: "#000",
                                              zIndex: "0",
                                            }}
                                            showIcon
                                            readOnlyInput
                                            disabled={fieldsAreReadOnly}
                                          />
                                        )}
                                      </Field>
                                    </span>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <span className="p-float-employee-asset">
                                      <Field
                                        name={`assetList[${index}].surrenderDate`}
                                      >
                                        {({ field, form }) => (
                                          <Calendar
                                            inputId={`surrenderDate-${index}`}
                                            value={parseDate(field.value)}
                                            onChange={(e) => {
                                              const formattedDate =
                                                formatDateForsubmit(e.value);
                                              form.setFieldValue(
                                                field.name,
                                                formattedDate
                                              );
                                            }}
                                            className={
                                              "employee-asset-calendar" +
                                              (field.value ? " filled-bg" : "")
                                            }
                                            style={{
                                              width: "100%",
                                              color: "#000",
                                              zIndex: "0",
                                            }}
                                            showIcon
                                            readOnlyInput
                                            disabled={fieldsAreReadOnly}
                                          />
                                        )}
                                      </Field>
                                    </span>

                                    {values.assetList.length > 1 &&
                                      type !== "View" && (
                                        <span
                                          className="employee-remove-asset"
                                          style={{
                                            position: "absolute",
                                            right: "30px",
                                          }}
                                          onClick={() => remove(index)}
                                        >
                                          <Trash />
                                        </span>
                                      )}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {type !== "View" && (
                            <div style={{ marginTop: "10px" }}>
                              <button
                                type="button"
                                className="employee-add-asset"
                                onClick={() =>
                                  push({
                                    assetName: "",
                                    assetType: "",
                                    serialNo: "",
                                    assignDate: null,
                                    surrenderDate: null,
                                  })
                                }
                              >
                                <span className="employee-asset-add-icon">
                                  <Plus />
                                </span>
                                Add Asset
                              </button>
                            </div>
                          )}
                          <div
                            className="add-Employeepage-buttons mt-3 ms-auto"
                            style={{ textAlign: "right" }}
                          >
                            {type !== "View" && (
                              <button
                                className="Cancel-btn"
                                type="button"
                                onClick={() => setModalOpen(true)}
                              >
                                Cancel
                              </button>
                            )}
                            {type === "View" && (
                              <button
                                className="Cancel-btn"
                                type="button"
                                onClick={() =>
                                  navigate("/all-employees/employees-list")
                                }
                              >
                                Cancel
                              </button>
                            )}
                            {modalOpen && (
                              <CancelModal
                                modalOpen={modalOpen}
                                closeModal={() => setModalOpen(false)}
                                onSubmit={() =>
                                  navigate("/all-employees/employees-list")
                                }
                                cancelButton={true}
                                submitButton={true}
                                onCloseTitle={"Cancel"}
                              />
                            )}
                            {type === "Add" || (type !== "View" && type !== "Edit") ? (
                              <button
                                className="add-button-prev"
                                type="button"
                                onClick={() => setActiveTab("ProfessionalInfo")}
                              >
                                Previous
                              </button>
                            ) : null}
                            {type === "Add" && (
                              <button className="add-button-next" type="submit" disabled={error}>
                                Next
                              </button>
                            )}
                            {type === "Edit" && (
                              <button className="add-button-next" type="submit" disabled={error}>
                                Update
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllEmployeeAsset;
