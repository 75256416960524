import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Pagination } from "flowbite-react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdowns from "../../../../assets/images-svg/Drop_down";
import Edit from "../../../../assets/images-svg/Edit";
import Export from "../../../../assets/images-svg/Export";
import Filter from "../../../../assets/images-svg/Filter";
import LeftArrow from "../../../../assets/images-svg/LeftArrow";
import Plus from "../../../../assets/images-svg/Plus";
import RightArrow from "../../../../assets/images-svg/RightArrow";
import { axiosService } from "../../../../services/axios.service";
import sessionService from "../../../../services/sessionstorage.service";
import { LOOKUP_URL } from "../../../look-up/constants/all-look-up-urls";
import { TIMESHEET_URL } from "../../../timesheet/constants/timesheet-url";
import "./TimesheetList.css";
import Select from "react-select";

// const customStyles = {
//   overlay: {
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//   },
//   content: {
//     top: "50%",
//     left: "50%",
//     width: "50%",
//     height: "78%",
//     transform: "translate(-50%, -50%)",
//     borderRadius: "20px",
//     padding: "0",
//   },
// };

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    width: "650px",
    height: "max-content",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    margin: "10px",
  },
  // padding:"20px",
};

const TimesheetList = () => {
  const User = sessionService.getUserData("userData");
  const empId = User?.employee?.empId;
  const [timesheetData, setTimesheetData] = useState([]);
  const [filterSelections, setFilterSelections] = useState(null);
  const [projectSelections, setProjectSelections] = useState(null);
  const [taskSelections, setTaskSelections] = useState("");
  const [duration, setDuration] = useState(null);
  const [startTime, setStartTime] = useState(dayjs());
  const [endTime, setEndTime] = useState(dayjs());
  const [projectOptions, setProjectOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [date, setDate] = useState(dayjs());

  const [taskInputValue, setTaskInputValue] = useState("");
  const typeaheadRefProject = useRef(null);
  const commentsref = useRef(null);
  const typeaheadRefTask = useRef(null);
  const [totalElements, setTotalElements] = useState(null);
  const cardsPerPageOptions = [10, 20, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(cardsPerPageOptions[0]);
  const displayedCards = timesheetData;
  const [comments, setComments] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: "42px",
      boxShadow: state.isFocused ? "none" : "none",
      backgroundColor: state.isFocused ? "#ffff" : "rgba(0 0 0 0.05)",
      borderRadius: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: '"Lexend", sans-serif',
      fontSize: "16px",
      marginTop: "0",
      backgroundColor: state.isFocused ? "#3FC28A" : "#ffffff",
      color: "black",
      "&:hover": {
        backgroundColor: "#3FC28A",
        color: "#ffffff",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000 !important",
      fontFamily: '"Lexend", sans-serif',
      fontSize: "16px",
      fontWeight: 400,
    }),
    menu: (provided) => ({
      ...provided,
    }),
  };

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const exportData = () => {
    const urlget = `${TIMESHEET_URL.GET_TIMESHEET_EXPORT}${empId}`;
    const params = {
      projectName: filterSelections,
    };
    if (dates !== null && dates.length === 2) {
      params.startDate = formatDate(dates[0]);
      params.endDate = formatDate(dates[1]);
    }
    axiosService
      .getFile(urlget, params)
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "exported-timesheet.xlsx";

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
        }
      })

      .catch(() => {});
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosService.get(LOOKUP_URL.GET_PROJECT);
        const responseData = response?.data?.data;
        const projects = responseData.filter(
          (item) => item.project && item.project.trim() !== ""
        );
        const projectOptions = projects.map((project) => ({
          value: project.project,
          label: project.project,
        }));

        setProjectOptions(projectOptions);
        const tasks = responseData.filter(
          (item) => item.task && item.task.trim() !== ""
        );
        const taskNames = tasks.map((task) => task.task);

        setTaskOptions(taskNames);
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchData();
  }, []);

  const calculateDuration = () => {
    const durationInHours = endTime.diff(startTime, "hour");
    const durationInMinutes = endTime.diff(startTime, "minute") % 60;
    const hoursText = durationInHours === 1 ? "hr" : "hrs";
    return `${durationInHours}.${durationInMinutes
      .toString()
      .padStart(2, "0")} ${hoursText}`;
  };
  const [taskData, setTaskData] = useState("");
  useEffect(() => {
    setDuration(calculateDuration());
  }, [startTime, endTime]);
  console.log(projectSelections, taskData, "PROJECT");
  const onsubmit = async () => {
    try {
      const requestData = {
        project: projectSelections.value,
        task: taskData,
        date: date.format("YYYY-MM-DD"),
        fromTime: startTime.format("HH:mm"),
        toTime: endTime.format("HH:mm"),
        duration: duration,
        comments: comments,
        employee: {
          empId: empId,
        },
      };
      const response = await axiosService.post(
        TIMESHEET_URL.ADD_TIMESHEET,
        requestData
      );
      if (response?.status === 200) {
        setProjectSelections("");
        setTaskSelections("");
        setTaskInputValue("");
        setDate(dayjs());
        setStartTime(dayjs());
        setEndTime(dayjs());
        toast.success("Your data is added successfully");
        closeModal();
        fetchData();
        // typeaheadRefProject.current.clear();
        // typeaheadRefTask.current.clear();
        if (typeaheadRefProject.current) {
          typeaheadRefProject.current.clear();
        }

        if (typeaheadRefTask.current) {
          typeaheadRefTask.current.clear();
        }
        console.log(requestData, "yyyyyyyyyyyyyyyyyyyyyyyy");
      } else {
        toast.success("Fail to add the data");
      }
    } catch (error) {
      console.error("Error: ", error);
      toast.error("An error occurred while processing your request.");
    }
  };

  const [dates, setDates] = useState(null);
  const handleDateChange = (e) => {
    const selectedDates = e.value;
    if (selectedDates.length === 1) {
      const endDate = new Date(selectedDates[0]);
      endDate.setDate(endDate.getDate() + 1);
      setDates([selectedDates[0], endDate]);
    } else {
      setDates(selectedDates);
    }
  };

  const [timeSheetId, setTimeSheetId] = useState("");
  const handleEditClick = (timesheetItem) => {
    setProjectSelections(timesheetItem?.project);
    setTaskSelections(timesheetItem?.task ? [timesheetItem?.task] : "");
    setDate(dayjs(timesheetItem?.date));

    setStartTime(dayjs(timesheetItem?.fromTime, "hh:mm A"));
    setEndTime(dayjs(timesheetItem?.toTime, "hh:mm A"));
    setTimeSheetId(timesheetItem?.timesheetId);
  };

  const editSubmit = async () => {
    try {
      const requestData = {
        project: projectSelections.value,
        task: taskData,
        date: date.format("YYYY-MM-DD"),
        fromTime: startTime.format("HH:mm"),
        toTime: endTime.format("HH:mm"),
        duration: duration,
        comments: comments,
        timesheetId: timeSheetId,
        employee: {
          empId: empId,
        },
      };
      const response = await axiosService.put(
        TIMESHEET_URL.UPDATE_TIMESHEET,
        requestData
      );
      if (response?.status === 200) {
        setProjectSelections("");
        setTaskSelections("");
        setTaskInputValue("");
        setDate(dayjs());

        setStartTime(dayjs());
        setEndTime(dayjs());
        toast.success("Your data is updated successfully");
        closeModal();
        fetchData();
        // typeaheadRefProject.current.clear();
        // typeaheadRefTask.current.clear();
        if (typeaheadRefProject.current) {
          typeaheadRefProject.current.clear();
        }

        if (typeaheadRefTask.current) {
          typeaheadRefTask.current.clear();
        }
        setTimeSheetId(null);
      } else {
        toast.success("Fail to add the data");
      }
    } catch (error) {
      console.error("Error: ", error);
      toast.error("Duration can't include negative values");
    }
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const fetchData = async () => {
    try {
      const url = `${TIMESHEET_URL.GET_TIMESHEET_BY_EMPID} ${empId}`;
      const params = {
        pageNumber: currentPage,
        itemsPerPage: recordsPerPage,
        projectName: filterSelections,
      };
      if (dates !== null && dates.length === 2) {
        params.startDate = formatDate(dates[0]);
        params.endDate = formatDate(dates[1]);
      }
      const response = await axiosService.get(url, params);
      const data = response?.data?.data?.data;
      setTotalElements(parseInt(response.data.data.totalElements));
      if (data) {
        setTimesheetData(data);
      }
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dates, filterSelections, currentPage, recordsPerPage]);

  const handleProjectSelect = (selectedOption) => {
    setProjectSelections(selectedOption);
  };

  const handleTimeSelect = (selected) => {
    setFilterSelections(selected);
    setCurrentPage(1);
  };

  const projectOptionSet = new Set(
    timesheetData.map((project) => project.project)
  );

  const projectOption = [...projectOptionSet].map((project) => ({
    label: project,
    value: project,
  }));

  const [projectFocussed, setProjectFocussed] = useState(false);
  const handleProjectFocus = () => {
    setProjectFocussed(true);
  };
  const handleCancel = () => {
    closeModal();
    setProjectSelections("");
    setTaskData("");
    setComments("");
    setDate(dayjs());
    setStartTime(dayjs());
    setEndTime(dayjs());
    // typeaheadRefProject.current.clear();
    if (typeaheadRefProject.current) {
      typeaheadRefProject.current.clear();
    }
  };

  return (
    <>
      <div className="w-100 timesheet-list">
        <div className="timesheet-list-container">
          <div className="timesheet-list-banner">
            <div className="container-fluid">
              <div className="row">
                <div className="col w-100 timesheet-top-column">
                  <div className="timesheet-list-filter">
                    <Dropdown
                      value={filterSelections}
                      onChange={(e) => handleTimeSelect(e.target.value)}
                      options={projectOption}
                      optionLabel="label"
                      filter={true}
                      showClear
                      placeholder="Project"
                      className="w-full md:w-14rem timesheet-status "
                      style={{ zIndex: "0" }}
                    />
                  </div>
                  <div className="timesheet-list-date-range">
                    <Calendar
                      id="calendarInput"
                      value={dates}
                      placeholder="Date Range"
                      onChange={handleDateChange}
                      selectionMode="range"
                      readOnlyInput
                      hideOnRangeSelection
                    />
                    <div className="timesheet-list-date-icon">
                      <Filter />
                    </div>
                  </div>
                  <div className="ms-auto">
                    <button
                      className="timesheet-export-button"
                      onClick={exportData}
                    >
                      Export
                      <span className="timesheet-export-button-icon">
                        <Export />
                      </span>
                    </button>
                  </div>
                  <div>
                    <button
                      className="timesheet-modal-add"
                      onClick={() => openModal()}
                    >
                      <span className="timesheet-modal-add-iocn">
                        <Plus />
                      </span>
                      Add Timesheet
                    </button>
                  </div>
                </div>
                <Modal
                  isOpen={modalIsOpen}
                  style={customStyles}
                  contentLabel="TimeSheet Modal"
                >
                  <div
                    className="timesheet-container"
                    style={{
                      width: "100%",
                      height: "100%",
                      // overflow: "hidden",
                      padding: "10px",
                    }}
                  >
                    <div className="timesheet-modal-title">Time Sheet</div>
                    <hr />
                    <div>
                      <div
                        className="timesheet-list-project-add col-lg-12 col-md-12"
                        style={{ marginTop: "20px" }}
                      >
                        <div
                          className={`select-container-asset mt-0 ${
                            projectFocussed || projectSelections ? "active" : ""
                          }`}
                          style={{
                            backgroundColor:
                              projectSelections || projectFocussed
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                            border:
                              projectSelections || projectFocussed
                                ? "1px solid rgba(0, 0, 0, 0.5)"
                                : "none",
                          }}
                        >
                          <label
                            htmlFor="project"
                            className="floating-label-asset"
                          >
                            Project
                          </label>

                          <Select
                            value={projectSelections}
                            onChange={handleProjectSelect}
                            options={projectOptions}
                            styles={selectStyles}
                            onFocus={handleProjectFocus}
                          />
                        </div>
                      </div>
                      <div className="timesheet-list-task-add col-lg-12 col-md-12">
                        <div
                          className="col-lg-12 col-md-12 asset-form-item"
                          style={{ marginTop: "25px" }}
                        >
                          <input
                            className="asset-modal-input"
                            type="text"
                            placeholder=""
                            value={taskData}
                            onChange={(e) => setTaskData(e.target.value)}
                            style={{
                              backgroundColor: taskData
                                ? "white"
                                : "rgba(0,0,0,0.05)",
                              border: taskData
                                ? "1px solid rgba(0, 0, 0, 0.5) "
                                : "none",
                            }}
                          />
                          <label className="asset-modal-label">Task</label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-3 timesheet-add-modal"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="timesheet-date col-lg-3 col-md-6 col-sm-12">
                        <lable className=" timeseet-modal-lable">
                          Start Date
                        </lable>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          style={{ width: "100%" }}
                        >
                          <DemoContainer components={["MobileDatePicker"]}>
                            <MobileDatePicker
                              className="time-note timesheet-date-picker-two"
                              value={date}
                              onChange={(newDate) => setDate(newDate)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>

                      <div className="mobile-time col-lg-3 col-md-6 col-sm-12">
                        <lable className=" timeseet-modal-lable">
                          Start Time
                        </lable>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["MobileTimePicker"]}>
                            <MobileTimePicker
                              className="time-note timesheet-date-picker-three"
                              value={startTime}
                              onChange={(newStartTime) =>
                                setStartTime(newStartTime)
                              }
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>

                      <div className="mobile-time col-lg-3 col-md-6 col-sm-12">
                        <lable className=" timeseet-modal-lable">
                          End Time
                        </lable>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["MobileTimePicker"]}>
                            <MobileTimePicker
                              value={endTime}
                              onChange={(newEndTime) => setEndTime(newEndTime)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>

                      <div className="timesheet-list-duration col-lg-3 col-md-6 col-sm-12">
                        <lable className=" timeseet-modal-lable">
                          Duration
                        </lable>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={duration || ""}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="timesheet-list-task-add col-lg-12 col-md-12">
                      <div
                        className="col-lg-12 col-md-12 asset-form-item"
                        style={{ marginTop: "25px" }}
                      >
                        <input
                          className="asset-modal-input"
                          type="text"
                          placeholder=""
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                          style={{
                            backgroundColor: comments
                              ? "white"
                              : "rgba(0,0,0,0.05)",
                            border: comments
                              ? "1px solid rgba(0, 0, 0, 0.5) "
                              : "none",
                            height: "80px",
                          }}
                        />
                        <label className="asset-modal-label">Comments</label>
                      </div>
                    </div>

                    <div className="timesheet-modal-button">
                      <span>
                        <button
                          className="Asset-cancle-button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </button>
                      </span>
                      <span>
                        {timeSheetId ? (
                          <button
                            className="timesheet-list-add-button"
                            onClick={editSubmit}
                            disabled={
                              !projectSelections ||
                              taskData === "" ||
                              projectSelections?.length === 0
                            }
                            style={{
                              opacity:
                                !projectSelections ||
                                taskData === "" ||
                                projectSelections?.length === 0
                                  ? 0.5
                                  : 1,
                            }}
                          >
                            Update
                          </button>
                        ) : (
                          <button
                            className="timesheet-list-add-button"
                            onClick={onsubmit}
                            disabled={
                              !projectSelections ||
                              taskData === "" ||
                              projectSelections?.length === 0
                            }
                            style={{
                              opacity:
                                !projectSelections ||
                                taskData === "" ||
                                projectSelections?.length === 0
                                  ? 0.5
                                  : 1,
                            }}
                          >
                            Add
                          </button>
                        )}
                      </span>
                    </div>
                  </div>
                </Modal>
                <div className="row timesheetTableScroll">
                  <div className="timesheet-list-table-container">
                    <table className="timesheet-list-table">
                      <thead>
                        <tr>
                          <th style={{ width: "225px" }}>Project</th>
                          <th style={{ width: "225px" }}>Task Name</th>
                          <th style={{ width: "205px" }}>Date</th>
                          <th style={{ width: "205px" }}>Start Time</th>
                          <th style={{ width: "205px" }}>End Time</th>
                          <th style={{ width: "205px" }}>Duration</th>
                          <th style={{ width: "90px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayedCards.map((timesheetItem, index) => (
                          <tr key={index}>
                            <td>{timesheetItem.project}</td>
                            <td>{timesheetItem.task}</td>
                            <td>{timesheetItem.date}</td>
                            <td>{timesheetItem.fromTime}</td>
                            <td>{timesheetItem.toTime}</td>
                            <td>{`${timesheetItem.duration.split(":")[0]}.${
                              timesheetItem.duration.split(":")[1]
                            } hrs`}</td>

                            <td>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleEditClick(timesheetItem);
                                  openModal();
                                }}
                              >
                                <Edit />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col w-50 d-flex align-items-center timesheet-record-list">
                    <span>Showing</span>
                    <div style={{ width: "fit-content", position: "relative" }}>
                      <select
                        value={recordsPerPage}
                        onChange={handleRecordsPerPageChange}
                      >
                        {cardsPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="timesheet-record-list-dropdown-icon">
                        <Dropdowns />
                      </div>
                    </div>
                  </div>
                  <div className="col w-50 timsheet-pagination-container">
                    {Math.ceil(totalElements / recordsPerPage) > 1 ? (
                      <>
                        {totalElements && (
                          <Pagination
                            className="timesheet-pagination d-inline-flex"
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                              totalElements / recordsPerPage
                            )}
                            onPageChange={onPageChange}
                            previousLabel={<LeftArrow />}
                            nextLabel={<RightArrow />}
                            activePageLinkStyle={{
                              color: "#ffffff !important",
                            }}
                            icon={<span>{currentPage}</span>}
                          />
                        )}
                      </>
                    ) : (
                      <nav
                        class="pagination d-inline-flex"
                        activepagelinkstyle="[object Object]"
                        icon="[object Object]"
                      >
                        <ul class="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.4685 17.5856C14.7919 17.3269 14.8444 16.8549 14.5856 16.5315L10.9604 12L14.5856 7.46849C14.8444 7.14505 14.7919 6.67308 14.4685 6.41432C14.145 6.15556 13.673 6.208 13.4143 6.53145L9.41429 11.5315C9.19516 11.8054 9.19516 12.1946 9.41429 12.4685L13.4143 17.4685C13.673 17.7919 14.145 17.8444 14.4685 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                          <li aria-current="page">
                            <button
                              type="button"
                              class="w-12 border border-gray-300 py-2 leading-tight enabled:hover:bg-gray-100 enabled:hover:text-gray-700 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                            >
                              1
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimesheetList;
