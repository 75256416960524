import { AppConfig } from "../../../env.development";

export const LEAVE_URL = {
  ADD_LEAVES: `${AppConfig.api_url}leave/v1`,
  EDIT_LEAVES: `${AppConfig.api_url}leave/v1`,
  GET_LEAVES: `${AppConfig.api_url}leave/v2/filter-leave/`,
  GET_EMPLOYEE_LEAVES: `${AppConfig.api_url}leave/v1/employee/`,
  GET_EMP_LEAVES: `${AppConfig.api_url}leave/v2/`,
  GET_ALL_HOLIDAYS: `${AppConfig.api_url}holiday/v1`,
};
