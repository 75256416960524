import React from 'react'

const ClockOut = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.8001 5.39453L6.5001 8.03125M6.5001 8.03125L9.8001 10.668M6.5001 8.03125H18.6001M13.1001 12.2073V12.2498C13.1001 13.2343 13.1001 13.7269 12.8603 14.1029C12.6494 14.4337 12.3131 14.7024 11.8991 14.8709C11.4285 15.0625 10.812 15.0625 9.57988 15.0625H4.51988C3.28777 15.0625 2.67257 15.0625 2.20196 14.8709C1.78801 14.7024 1.45057 14.4337 1.23965 14.1029C1.0001 13.7273 1.0001 13.2353 1.0001 12.2527V3.80935C1.0001 2.82681 1.0001 2.33552 1.23965 1.95987C1.45057 1.62912 1.78801 1.35993 2.20196 1.1914C2.67211 1 3.28699 1 4.5167 1H9.5835C10.8132 1 11.429 1 11.8991 1.1914C12.3131 1.35993 12.6494 1.62937 12.8603 1.96013C13.1001 2.33614 13.1001 2.82803 13.1001 3.8125V3.85645" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  )
}

export default ClockOut