import React from "react";

function Approved() {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 31 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 11.9614L10.8777 19.8403L27.76 2.95703"
        stroke="#43852C"
        stroke-opacity="0.7"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Approved;
